/* eslint-disable react/no-multi-comp */
/* eslint max-classes-per-file: ["error", 13] */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';

// Class for Refresh Icon
export class RefreshIcon extends Component {
  static propTypes = {
    className: PropTypes.string
  };

  render() {
    const { className } = this.props;
    return <Icon className={`icon-base icon-warning ${className}`}>sync</Icon>;
  }
}

// Class for Loading Icon
export class LoadingIcon extends Component {
  static propTypes = {
    className: PropTypes.string
  };

  render() {
    const { className } = this.props;
    return (
      <Icon className={`icon-base icon-info icon-spin ${className}`}>sync</Icon>
    );
  }
}

// Class for Success Icon
export class SuccessIcon extends Component {
  static propTypes = {
    className: PropTypes.string
  };

  render() {
    const { className } = this.props;
    return (
      <Icon className={`icon-base icon-ok ${className}`}>check_circle</Icon>
    );
  }
}

// Class for Error Icon
export class ErrorIcon extends Component {
  static propTypes = {
    className: PropTypes.string
  };

  render() {
    const { className } = this.props;
    return <Icon className={`icon-base icon-error ${className}`}>error</Icon>;
  }
}

// Class for Warning Icon
export class WarningIcon extends Component {
  static propTypes = {
    className: PropTypes.string
  };

  render() {
    const { className } = this.props;
    return <Icon className={`icon-base ${className}`}>warning</Icon>;
  }
}

// Class for Warning Icon
export class PreviousIcon extends Component {
  static propTypes = {
    className: PropTypes.string
  };

  render() {
    const { className } = this.props;
    return <Icon className={`icon-base ${className}`}>skip_previous</Icon>;
  }
}

// Class for Warning Icon
export class NextIcon extends Component {
  static propTypes = {
    className: PropTypes.string
  };

  render() {
    const { className } = this.props;
    return <Icon className={`icon-base ${className}`}>skip_next</Icon>;
  }
}

// Class for Warning Icon
export class ArrowRightIcon extends Component {
  static propTypes = {
    className: PropTypes.string
  };

  render() {
    const { className } = this.props;
    return <Icon className={`icon-base ${className}`}>arrow_forward</Icon>;
  }
}

// Class for Warning Icon
export class ArrowLeftIcon extends Component {
  static propTypes = {
    className: PropTypes.string
  };

  render() {
    const { className } = this.props;
    return <Icon className={`icon-base ${className}`}>arrow_back</Icon>;
  }
}

// Class for Warning Icon
export class PlayIcon extends Component {
  static propTypes = {
    className: PropTypes.string
  };

  render() {
    const { className } = this.props;
    return <Icon className={`icon-base ${className}`}>play_arrow</Icon>;
  }
}

// Class for Warning Icon
export class PauseIcon extends Component {
  static propTypes = {
    className: PropTypes.string
  };

  render() {
    const { className } = this.props;
    return <Icon className={`icon-base ${className}`}>pause</Icon>;
  }
}

// Class for Add Icon
export class AddIcon extends Component {
  static propTypes = {
    className: PropTypes.string
  };

  render() {
    const { className } = this.props;
    return <Icon className={`icon-base ${className}`}>add</Icon>;
  }
}

// Class for Delete Icon
export class DeleteIcon extends Component {
  static propTypes = {
    className: PropTypes.string
  };

  render() {
    const { className } = this.props;
    return <Icon className={`icon-base ${className}`}>delete</Icon>;
  }
}
