import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types'; // eslint-disable-line

import i18n from '../../i18n';

import '../../styles/patient-list.css';

export default class PatientsListView extends Component {
  static propTypes = {
    patients: PropTypes.shape({
      isFetching: PropTypes.bool.isRequired,
      patients: PropTypes.arrayOf(PropTypes.any).isRequired
    }),
    shouldSort: PropTypes.bool
  };

  renderPatients() {
    const { patients, shouldSort } = this.props;

    let sortedPatients = patients && patients.patients;

    if (shouldSort) {
      sortedPatients =
        (patients &&
          patients.patients &&
          patients.patients.sort((a, b) => {
            if (
              a.lastName
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '') <
              b.lastName
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
            )
              return -1;
            if (
              a.lastName
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '') >
              b.lastName
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
            )
              return 1;
            return 0;
          })) ||
        [];
    } else {
      sortedPatients = patients && patients.patients.slice(0, 5);
    }

    return sortedPatients.map(patient => (
      <Link
        key={patient.id}
        to={`patients/${patient.id}`}
        style={{ textDecoration: 'none' }}
      >
        <div className="list-item box">
          <div className="column-name">
            {`${patient.lastName} ${patient.firstName}`}
          </div>
          <div className="column-date-of-birth">
            {patient.dateOfBirth &&
              patient.dateOfBirth &&
              new Date(patient.dateOfBirth).toLocaleDateString('hu-HU')}
          </div>
          <div className="column-last-exam-date">
            {patient.lastExamination &&
              patient.lastExamination.date &&
              new Date(patient.lastExamination.date).toLocaleString('hu-HU')}
          </div>
          <div className="column-last-exam-comment">
            {patient.lastExamination && patient.lastExamination.comment}
          </div>
        </div>
      </Link>
    ));
  }

  render() {
    return (
      <div className="patient-list patients-grid">
        <div className="list-item list-header">
          <div className="column-name">{i18n.t('patientsList.name')}</div>
          <div className="column-date-of-birth">
            {i18n.t('patientsList.dateOfBirth')}
          </div>
          <div className="column-last-exam-date">
            {i18n.t('patientsList.dateOfLastExam')}
          </div>
          <div className="column-last-exam-comment">
            {i18n.t('patientsList.commentOfLastExam')}
          </div>
        </div>
        {this.renderPatients()}
      </div>
    );
  }
}
