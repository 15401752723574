import React, { Component } from 'react';
import './styles/app.css';
import './styles/_main.scss';
import './styles/_examination.scss';
import './styles/_patient.scss';
import './styles/_settings.scss';
import './styles/_recent.scss';
import './styles/_search.scss';
import './styles/_progress.scss';
import './styles/_evaluation.scss';
import { Provider } from 'react-redux';
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import createBrowserHistory from 'history/createBrowserHistory'; // eslint-disable-line import/no-extraneous-dependencies
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import configureStore from './store';
import Main from './views/main';
// import Report from './views/report';
// import Utils from './services/utils';

import './styles/statement.css';

const { store, persistor } = configureStore();
const history = createBrowserHistory();

// Render view based on login status
class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Main />
          <ToastContainer />
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
export { history };
