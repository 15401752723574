import React, { Component } from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line
import moment from 'moment';
import PatientView from './patient-view';
import Loader from '../../components/loader';
import NotFound from '../../components/not-found/not-found';

import i18n from '../../i18n';

import patientModel from '../../models';

class Patient extends Component {
  static propTypes = {
    history: PropTypes.shape(),
    deletePatient: PropTypes.func,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string
      })
    }).isRequired,
    patients: PropTypes.shape({
      isFetching: PropTypes.bool.isRequired,
      patients: PropTypes.arrayOf(patientModel.patient).isRequired
    }),
    getPatient: PropTypes.func.isRequired,
    patientId: PropTypes.string,
    isPatientFetching: PropTypes.bool,
    patient: patientModel.patient,
    errors: PropTypes.arrayOf(PropTypes.any)
  };

  constructor(props) {
    super(props);
    this.state = {
      isPatientModalOpen: false,
      isExamModalOpen: false,
      isConfirmModalOpen: false
    };

    this.openPatientModal = this.openPatientModal.bind(this);
    this.openExamModal = this.openExamModal.bind(this);
    this.openConfirmModal = this.openConfirmModal.bind(this);
    this.closePatientModal = this.closePatientModal.bind(this);
    this.closeExamModal = this.closeExamModal.bind(this);
    this.closeConfirmModal = this.closeConfirmModal.bind(this);
  }

  async componentDidMount() {
    const { getPatient, match, patients } = this.props;
    const patientId = match && match.params && match.params.id;

    // if the patient is not yet in the patients state (i.e. on page reload)
    // => load that single patient
    if (
      !patients ||
      !patients.patients ||
      !patients.patients.find(p => p.id === patientId)
    ) {
      getPatient(match && match.params && match.params.id);
    }
  }

  getSelectedPatient() {
    const { match, patients } = this.props;
    const patientId = match && match.params && match.params.id;
    return (
      patients &&
      patients.patients &&
      patients.patients.find(p => p.id.toString() === patientId)
    );
  }

  openPatientModal() {
    this.setState({
      isPatientModalOpen: true
    });
  }

  closePatientModal() {
    const { getPatient, patientId } = this.props;
    this.setState({
      isPatientModalOpen: false
    });
    getPatient(patientId);
  }

  openExamModal() {
    this.setState({
      isExamModalOpen: true
    });
  }

  closeExamModal() {
    this.setState({
      isExamModalOpen: false
    });
  }

  openConfirmModal() {
    this.setState({
      isConfirmModalOpen: true
    });
  }

  closeConfirmModal() {
    this.setState({
      isConfirmModalOpen: false
    });
  }

  render() {
    const {
      isPatientModalOpen,
      isExamModalOpen,
      isConfirmModalOpen
    } = this.state;
    const {
      patient,
      isPatientFetching,
      getPatient,
      patientId,
      errors,
      history,
      deletePatient
    } = this.props;

    if (errors) {
      const errorMessage = errors.message;
      return <NotFound message={i18n.t(`backendResponse.${errorMessage}`)} />;
    }

    if (!patient) {
      if (!isPatientFetching) {
        getPatient(patientId);
      }
      return <Loader message={i18n.t('loadingInfo.loadingPatient')} />;
    }

    const lastExamination =
      patient &&
      patient.examinations &&
      patient.examinations.length > 0 &&
      patient.examinations[0];
    const defaultModalValuesExam = {
      weight: lastExamination && lastExamination.weight,
      height: lastExamination && lastExamination.height,
      date: moment().format('YYYY-MM-DDTHH:mm') || '',
      dominantSide: lastExamination && lastExamination.dominantSide,
      examType: '',
      comment: ''
    };
    const defaultModalValuesPatient = {
      firstName: patient && patient.firstName,
      lastName: patient && patient.lastName,
      dateOfBirth: patient && moment(patient.dateOfBirth).format('YYYY-MM-DD'),
      placeOfBirth: patient && patient.placeOfBirth,
      mothersName: patient && patient.mothersName,
      address: (patient && patient.address) || '',
      email: (patient && patient.email) || '',
      phone: (patient && patient.phone) || '',
      gender: patient && patient.gender,
      dominantSide: patient && patient.dominantSide
    };

    return (
      <PatientView
        patient={patient}
        isPatientModalOpen={isPatientModalOpen}
        isExamModalOpen={isExamModalOpen}
        defaultModalValuesExam={defaultModalValuesExam}
        defaultModalValuesPatient={defaultModalValuesPatient}
        openPatientModal={this.openPatientModal}
        closePatientModal={this.closePatientModal}
        openExamModal={this.openExamModal}
        closeExamModal={this.closeExamModal}
        isFetching={isPatientFetching}
        isConfirmModalOpen={isConfirmModalOpen}
        openConfirmModal={this.openConfirmModal}
        closeConfirmModal={this.closeConfirmModal}
        history={history}
        deletePatient={deletePatient}
      />
    );
  }
}

export default Patient;
