import React, { Component } from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line
import ExaminationsView from './examinations-view';

class Examinations extends Component {
  static propTypes = {
    examinations: PropTypes.shape({
      isFetching: PropTypes.bool.isRequired,
      examinations: PropTypes.arrayOf(PropTypes.any).isRequired
    }),
    getAllExaminations: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      searchedExaminations: [],
      search: {
        show: 'search-results-closed',
        name: '',
        comment: '',
        date: ''
      }
    };

    this.searchChanged = this.searchChanged.bind(this);
    this.closeSearch = this.closeSearch.bind(this);
  }

  async componentDidMount() {
    const { getAllExaminations } = this.props;
    getAllExaminations('*', 500);
  }

  searchChanged(e) {
    const { state } = this;
    const { examinations } = this.props;

    if (!examinations || !examinations.examinations) {
      return;
    }

    switch (e.target.name) {
      case 'name':
        state.search.name = e.target.value;
        break;
      case 'comment':
        state.search.comment = e.target.value;
        break;
      case 'date':
        state.search.date = e.target.value;
        break;
      default:
        break;
    }

    const { name, date, comment } = state.search;

    if (name || comment || date) {
      // Search if one of the field is not empty

      let searchedExaminations = examinations.examinations;

      // filter by name first
      if (name) {
        const re = new RegExp(
          name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
          'i'
        );
        searchedExaminations = searchedExaminations.filter(
          p =>
            re.test(
              `${p.firstName
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')} ${p.lastName
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')}`
            ) && p
        );
        // searchedExaminations = [...new Set([...searchedExaminations, ...filtered])];
      }

      // further filter by comment
      if (comment) {
        const re = new RegExp(
          comment.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
          'i'
        );
        searchedExaminations = searchedExaminations.filter(
          p =>
            re.test(
              p.comment &&
                p.comment.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
            ) && p
        );
      }

      // further filter by date
      if (date) {
        const re = new RegExp(date, 'i');
        searchedExaminations = searchedExaminations.filter(
          p => re.test(p.date) && p
        );
      }

      // @TODO: better search (from db)
      this.setState(prevState => ({
        ...prevState,
        search: {
          ...prevState.search,
          show: 'search-results-open'
        },
        searchedExaminations
      }));
    } else {
      // Close search if everything is deleted from search fields

      this.setState(prevState => ({
        ...prevState,
        search: {
          ...prevState.search,
          show: 'search-results-closed'
        }
      }));
    }
  }

  closeSearch() {
    this.setState(prevState => ({
      search: {
        ...prevState.search,
        show: 'search-results-closed',
        name: '',
        comment: '',
        date: ''
      }
    }));
  }

  render() {
    const { props } = this;
    const { search, searchedExaminations } = this.state;
    return (
      <ExaminationsView
        examinations={props.examinations}
        searchChanged={this.searchChanged}
        search={search}
        searchedExaminations={searchedExaminations}
        closeSearch={this.closeSearch}
      />
    );
  }
}

export default Examinations;
