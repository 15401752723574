import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PatientsList from '../../patient-list';
import i18n from '../../../i18n';

export default class SearchPatientsView extends Component {
  static propTypes = {
    searchChanged: PropTypes.func,
    isOpen: PropTypes.bool,
    patients: PropTypes.arrayOf(PropTypes.shape()),
    closeSearch: PropTypes.func,
    searchFields: PropTypes.arrayOf(PropTypes.shape())
  };

  render() {
    const {
      searchChanged,
      isOpen,
      patients,
      closeSearch,
      searchFields
    } = this.props;

    /**
     * Rendering order:
     * 1. render title
     * 2. render searchFields (map)
     * 3. render searchResults (check isOpen)
     */
    return (
      <div className="search">
        <h1>{i18n.t('searchInfo.searchForPatient')}</h1>
        <div className="search-fields">
          {searchFields.map(field => (
            <div key={field.name} className="search-field">
              <h1>{field.title}</h1>
              <div className="search-field-input box">
                <input
                  name={field.name}
                  type="text"
                  placeholder={field.placeholder}
                  onChange={e => {
                    searchChanged({
                      name: e.target.name,
                      value: e.target.value
                    });
                  }}
                />
              </div>
            </div>
          ))}
        </div>
        {isOpen ? (
          <div className="search-results">
            <h1>{i18n.t('searchInfo.results')}</h1>
            <PatientsList
              shouldSort
              patients={{ patients, isFetching: false }}
            />
            <button
              type="button"
              className="btn-red close-search"
              onClick={() => closeSearch()}
            >
              {i18n.t('searchActions.close')}
            </button>
          </div>
        ) : null}
      </div>
    );
  }
}
