import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import i18n from '../../../i18n';
import Confirm from '../../modals/confirm';
import Timer from './timer';
import { LoadingIcon } from '../../icons';

export default class ProgressView extends Component {
  static propTypes = {
    runningId: PropTypes.number,
    examination: PropTypes.shape(),
    stopExamination: PropTypes.func,
    startExamination: PropTypes.func,
    localExamination: PropTypes.shape(),
    isConfirmOpen: PropTypes.bool,
    closeConfirm: PropTypes.func,
    openConfirm: PropTypes.func,
    confirmExamination: PropTypes.func,
    cleanupExamination: PropTypes.func,
    getExamination: PropTypes.func,
    isStopping: PropTypes.bool,
    resetStopping: PropTypes.func
  };

  static renderPending(status) {
    return (
      <div className="progress box">
        <div className="flex-container">
          <p>
            <LoadingIcon />
            {`Status: ${i18n.t(`examStatusInfo.${status}`)}`}
          </p>
        </div>
      </div>
    );
  }

  /**
   * Merges the status from the local and cloud stored examination.
   *
   * @returns The status of the examination.
   * @memberof ProgressView
   */
  getStatus() {
    const { examination, localExamination, runningId } = this.props;

    if (runningId) {
      return 'blocked';
    }

    if (
      examination &&
      (examination.status === 'confirmed' ||
        examination.status === 'completed') &&
      ((localExamination && localExamination.status !== 'pending') ||
        !localExamination)
    ) {
      return examination.status;
    }

    return localExamination && localExamination.status;
  }

  // This will be called if the exam is running
  renderRunning(status) {
    const {
      stopExamination,
      examination,
      isStopping,
      localExamination
    } = this.props;

    if (isStopping) {
      return (
        <div className="progress box">
          <div className="flex-container">
            <p>
              <LoadingIcon />
              Status: Examination is stopping...
            </p>
          </div>
        </div>
      );
    }

    return (
      <div className="progress box">
        <div className="flex-container">
          <button type="button" onClick={() => stopExamination(examination.id)}>
            {i18n.t('examStatusActions.stop')}
          </button>

          <Timer
            time={
              localExamination.createdAt &&
              localExamination.createdAt.toString()
            }
          />
          <p>
            {`Frames: ${
              localExamination.details
                ? i18n.t(`examStatusInfo.${localExamination.details}`)
                : 0
            }`}
          </p>
          <p>{`Status: ${i18n.t(`examStatusInfo.${status}`)}`}</p>
        </div>
      </div>
    );
  }

  renderCompleted() {
    const {
      examination,
      isStopping,
      resetStopping,
      confirmExamination
    } = this.props;

    if (isStopping) {
      resetStopping();
    }

    return (
      <div className="progress box">
        <button
          type="button"
          onClick={() => confirmExamination(examination.id)}
        >
          {i18n.t('examStatusActions.confirm')}
        </button>
      </div>
    );
  }

  renderOtherRunning() {
    const { runningId, getExamination } = this.props;

    return (
      <div className="progress box">
        <p>{i18n.t('examStatusInfo.otherExamInProgress')}</p>
        <p>
          <Link
            className="text-link"
            onClick={() => getExamination(runningId)}
            to={`/examinations/${runningId}`}
          >
            {i18n.t('examStatusActions.goToExam')}
          </Link>
        </p>
      </div>
    );
  }

  renderStartable(id, status) {
    const {
      isStopping,
      resetStopping,
      isConfirmOpen,
      closeConfirm,
      cleanupExamination,
      getExamination,
      startExamination,
      openConfirm
    } = this.props;

    if (isStopping) {
      resetStopping();
    }

    // Check examination status
    if (status === 'confirmed' || status === 'failed') {
      // If confirmed or failed
      // -> return Recapture Data button
      return (
        <div className="progress box">
          {isConfirmOpen ? (
            <Confirm
              isConfirmOpen={isConfirmOpen}
              closeConfirm={closeConfirm}
              confirmFunction={() => {
                // updateExamination(examination.id, { status: 'recapturing' });
                cleanupExamination(id);
                getExamination(id);
                startExamination(id);
              }}
              confirmMessage={i18n.t('examStatusInfo.confirmRecapture')}
            />
          ) : null}
          <button type="button" onClick={() => openConfirm()}>
            {i18n.t('examStatusActions.recapture')}
          </button>
          {status === 'failed' ? <span>Examination failed</span> : null}
        </div>
      );
    }

    // If not
    // -> return Start Examination button
    return (
      <div className="progress box">
        <button type="button" onClick={() => startExamination(id)}>
          {i18n.t('examStatusActions.start')}
        </button>
      </div>
    );
  }

  /**
   * Rendering order: ...
   */
  render() {
    const { examination } = this.props;
    const status = this.getStatus();
    switch (status) {
      case 'running':
        return this.renderRunning(status);
      case 'pending':
        return ProgressView.renderPending(status);
      case 'completed':
        return this.renderCompleted(status);
      case 'blocked':
        return this.renderOtherRunning(status);
      case 'failed':
      default:
        return this.renderStartable(examination && examination.id, status);
    }
  }
}
