import { REQUEST } from '../request';
// To retreive files from local backend
const GET_FILES_LOCAL_SUCCESS = 'GET_FILES_LOCAL_SUCCESS';
const GET_FILES_LOCAL_FAILED = 'GET_FILES_LOCAL_FAILED';
const CLEAN_LOCAL_FILES = 'CLEAN_LOCAL_FILES';
const REFRESH_THERMAL_SUCCESS = 'REFRESH_THERMAL_SUCCESS';
const REFRESH_THERMAL_FAILED = 'REFRESH_THERMAL_FAILED';

const REFRESH_CARV_FAILED = 'REFRESH_CARV_FAILED';
const REFRESH_CARV_SUCCESS = 'REFRESH_CARV_SUCCESS';

export const Types = {
  GET_FILES_LOCAL_SUCCESS,
  GET_FILES_LOCAL_FAILED,
  CLEAN_LOCAL_FILES,
  REFRESH_THERMAL_SUCCESS,
  REFRESH_THERMAL_FAILED,
  REFRESH_CARV_FAILED,
  REFRESH_CARV_SUCCESS,
  REQUEST
};

export const initialState = {
  files: {
    thermalImages: [],
    kinectVideo: [],
    carvData: {}
  },
  errors: null
};

const files = (state = initialState, action) => {
  if (!action) return state;

  switch (action.type) {
    case REQUEST:
      return {
        ...state,
        errors: null
      };

    case GET_FILES_LOCAL_SUCCESS:
      return {
        ...state,
        files: (action.payload && action.payload.files) || { ...state.files },
        errors: null
      };

    case GET_FILES_LOCAL_FAILED:
      return {
        ...state,
        errors: action.payload
      };

    case CLEAN_LOCAL_FILES:
    case 'FLUSH':
      return initialState;
    default:
      return state;
  }
};

export const flushLocalFiles = () => ({
  type: CLEAN_LOCAL_FILES
});

export const Actions = {
  flushLocalFiles
};

export default files;
