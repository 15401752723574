import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  joints,
  jointTypes,
  jointTypesAzure,
  jointsAzure
} from '../../../../../services/joints';

let VERSION = null;
let JOINTS = joints;
let JOINT_TYPES = jointTypes;
let KINECT_STANDARD_W = 620;
const VIDEO_HQ_W = 960;
let KINECT_STANDARD_H = 350;
const VIDEO_HQ_H = 540;
let STROKE = 2;
let POINT = 5;

export default class Skeleton extends Component {
  static propTypes = {
    examinationId: PropTypes.number,
    examination: PropTypes.shape(),
    skeleton: PropTypes.arrayOf(PropTypes.any),
    videoTime: PropTypes.number,
    isPrint: PropTypes.bool
  };

  static drawJoints(body) {
    return Object.keys(JOINT_TYPES).map(name => {
      const point = body[JOINT_TYPES[name]];
      return (
        <circle
          key={name}
          r={POINT}
          cx={(point.x * VIDEO_HQ_W) / KINECT_STANDARD_W}
          cy={(point.y * VIDEO_HQ_H) / KINECT_STANDARD_H}
        />
      );
    });
  }

  static drawBones(body) {
    const validJoints = JOINTS.filter(
      j =>
        typeof body[j.left] !== 'undefined' &&
        typeof body[j.right] !== 'undefined'
    );

    let i = 0;

    return validJoints.map(j => {
      i += 1;
      return (
        <line
          key={`line-${i}`}
          strokeWidth={STROKE}
          x1={(body[j.left].x * VIDEO_HQ_W) / KINECT_STANDARD_W}
          y1={(body[j.left].y * VIDEO_HQ_H) / KINECT_STANDARD_H}
          x2={(body[j.right].x * VIDEO_HQ_W) / KINECT_STANDARD_W}
          y2={(body[j.right].y * VIDEO_HQ_H) / KINECT_STANDARD_H}
        />
      );
    });
  }

  static drawBody(data) {
    const { body } = data;

    return (
      <>
        {Skeleton.drawJoints(body)}
        {Skeleton.drawBones(body)}
      </>
    );
  }

  componentDidMount() {
    const { examinationId, examination, isPrint } = this.props;
    const { version } = examination;
    VERSION = version;

    if (examinationId && examinationId > 55) {
      KINECT_STANDARD_W = 638;
      KINECT_STANDARD_H = 358;
    }
    if (
      (examinationId && [22, 64, 67, 68, 73].includes(examinationId)) ||
      VERSION === 'azure'
    ) {
      KINECT_STANDARD_W = 640;
      KINECT_STANDARD_H = 584;
      JOINT_TYPES = jointTypesAzure;
      JOINTS = jointsAzure;
    }

    if (isPrint) {
      STROKE = 4;
      POINT = 4;
    }
  }

  findNearestTimeStamp() {
    const { skeleton, videoTime } = this.props;

    if (!skeleton) {
      return null;
    }

    const time = parseFloat(videoTime);

    if (time < skeleton[0].time || time > skeleton[skeleton.length - 1].time) {
      return null;
    }

    let nearest = null;
    let lastIndex = null;

    skeleton.forEach((d, index) => {
      const stamp = Number(skeleton[index].time);
      if (
        nearest === null ||
        Math.abs(stamp - time) < Math.abs(nearest - time)
      ) {
        nearest = stamp;
        lastIndex = index;
      }
    });
    return nearest === null ? null : skeleton[lastIndex];
  }

  render() {
    const data = this.findNearestTimeStamp();

    return (
      <div className="skeleton">
        <svg id="skeleton-svg" viewBox="0 0 960 540">
          {data ? Skeleton.drawBody(data) : null}
        </svg>
      </div>
    );
  }
}
