import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SettingsView from './settings-view';
import NotFound from '../../components/not-found';

class Settings extends Component {
  static propTypes = {
    getSettings: PropTypes.func,
    settings: PropTypes.shape(),
    updateSettings: PropTypes.func,
    killPendingProgresses: PropTypes.func,
    isFetching: PropTypes.bool,
    isLocalBackendAvailable: PropTypes.bool,
    carvSessions: PropTypes.arrayOf(PropTypes.shape())
  };

  /**
   * If local backend is available:
   * -> get settings from local backend
   *
   * If not:
   * -> do nothing
   */
  componentDidMount() {
    const { getSettings, isLocalBackendAvailable } = this.props;
    if (isLocalBackendAvailable) {
      getSettings();
    }
  }

  /**
   * If local backend is available:
   * -> Render settings view
   *
   * If not:
   * -> Render error message
   */
  render() {
    const {
      settings,
      updateSettings,
      killPendingProgresses,
      isFetching,
      isLocalBackendAvailable,
      carvSessions
    } = this.props;

    return isLocalBackendAvailable ? (
      <SettingsView
        settings={settings}
        updateSettings={updateSettings}
        killPendingProgresses={killPendingProgresses}
        isFetching={isFetching}
        carvSessions={carvSessions}
      />
    ) : (
      <NotFound
        icon
        message="Local backend not available! Start the Dynaknee icon from the desktop if connecting from a Dynaknee laptop!"
      />
    );
  }
}

export default Settings;
