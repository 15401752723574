import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class TimerView extends Component {
  static propTypes = {
    time: PropTypes.string
  };

  render() {
    const { time } = this.props;
    return <p>{`Time: ${time}`}</p>;
  }
}
