import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  LoadingIcon,
  WarningIcon,
  SuccessIcon,
  ErrorIcon,
  RefreshIcon
} from '../../../../../icons';

class CarvData extends Component {
  static propTypes = {
    carvData: PropTypes.arrayOf(PropTypes.shape()),
    examinationId: PropTypes.string,
    fromServer: PropTypes.bool,
    status: PropTypes.string,
    download: PropTypes.func,
    examinationActionDisable: PropTypes.func,
    refreshCarv: PropTypes.func,
    enableResendData: PropTypes.bool
  };

  render() {
    const {
      carvData,
      download,
      examinationId,
      fromServer,
      status,
      enableResendData,
      examinationActionDisable,
      refreshCarv
    } = this.props;

    const isLoading = carvData.some(
      file =>
        file.status && (file.status === 'pending' || file.status === 'started')
    );

    const isError =
      carvData.some(file => file.status && file.status === 'failed') ||
      status === 'failed';
    const isFiles = carvData.length > 0;

    let mainIcon = <WarningIcon />;

    // Define the main icon
    if (isFiles) {
      if (isError) {
        mainIcon = <ErrorIcon />;
      } else if (isLoading) {
        mainIcon = <LoadingIcon />;
      } else {
        mainIcon = <SuccessIcon />;
      }
    }

    return (
      <div>
        <h1 style={{ marginBottom: 10 }}>
          {mainIcon}
          Carv foot pressure data
        </h1>
        <div className="files-box box">
          {carvData && carvData.length > 0 ? (
            carvData.map(file => {
              const globalStatus = status === 'failed' ? status : file.status;

              return (
                <div key={file.file} className="file">
                  <p
                    role="presentation"
                    onClick={() => {
                      download(examinationId, file.type, file.file, fromServer);
                    }}
                  >
                    {(() => {
                      switch (globalStatus) {
                        case 'started':
                        case 'pending':
                          return <LoadingIcon />;
                        case 'failed':
                          return <ErrorIcon />;
                        case 'completed':
                        case 'success':
                          return <SuccessIcon />;
                        default:
                          return null;
                      }
                    })()}
                    {file.file}
                  </p>
                </div>
              );
            })
          ) : (
            <p className="no-files">No files found!</p>
          )}
        </div>
        {enableResendData && carvData.length === 0 ? (
          <button
            type="button"
            onClick={() => {
              examinationActionDisable();
              refreshCarv(examinationId);
            }}
          >
            <RefreshIcon className="icon-white" />
            Refresh
          </button>
        ) : null}
      </div>
    );
  }
}
export default CarvData;
