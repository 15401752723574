import { REQUEST } from '../request';

const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';
const UPDATE_SETTINGS_SUCCESS = 'UPDATE_SETTINGS_SUCCESS';
const GET_SETTINGS_FAILED = 'GET_SETTINGS_FAILED';
const UPDATE_SETTINGS_FAILED = 'UPDATE_SETTINGS_FAILED';

const CHECK_LOCAL_BACKEND_SUCCESS = 'CHECK_LOCAL_BACKEND_SUCCESS';
const CHECK_LOCAL_BACKEND_FAILED = 'CHECK_LOCAL_BACKEND_FAILED';

export const Types = {
  GET_SETTINGS_SUCCESS,
  UPDATE_SETTINGS_SUCCESS,
  GET_SETTINGS_FAILED,
  UPDATE_SETTINGS_FAILED,
  CHECK_LOCAL_BACKEND_SUCCESS,
  CHECK_LOCAL_BACKEND_FAILED
};

export const initialState = {
  isFetching: false,
  settings: {
    thermalPath: '',
    thermalLimit: '',
    carvCredentials: {}
  },
  carvSessions: []
};

const settings = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST: {
      return {
        ...state,
        isFetching: true
      };
    }
    case GET_SETTINGS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        settings:
          action.payload && action.payload.data && action.payload.data.settings,
        carvSessions:
          (action.payload &&
            action.payload.data &&
            action.payload.data.carvSessions) ||
          []
      };
    }

    case UPDATE_SETTINGS_SUCCESS: {
      return {
        ...state,
        isFetching: false
      };
    }

    case GET_SETTINGS_FAILED:
    case UPDATE_SETTINGS_FAILED: {
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    }

    default:
      return state;
  }
};

export default settings;
