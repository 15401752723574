import { REQUEST } from '../request';

// To retreive files from local backend
const START_EXAMINATION_SUCCESS = 'START_EXAMINATION_SUCCESS';
const START_EXAMINATION_FAILED = 'START_EXAMINATION_FAILED';

const STOP_EXAMINATION_SUCCESS = 'STOP_EXAMINATION_SUCCESS';
const STOP_EXAMINATION_FAILED = 'STOP_EXAMINATION_FAILED';

const GET_LOCAL_EXAMINATION_SUCCESS = 'GET_LOCAL_EXAMINATION_SUCCESS';
const GET_LOCAL_EXAMINATION_FAILED = 'GET_LOCAL_EXAMINATION_FAILED';

const CLEAN_LOCAL_EXAMINATION = 'CLEAN_LOCAL_EXAMINATION';

const REFRESH_THERMAL_SUCCESS = 'REFRESH_THERMAL_SUCCESS';
const REFRESH_THERMAL_FAILED = 'REFRESH_THERMAL_FAILED';

export const Types = {
  START_EXAMINATION_SUCCESS,
  START_EXAMINATION_FAILED,
  STOP_EXAMINATION_SUCCESS,
  STOP_EXAMINATION_FAILED,
  CLEAN_LOCAL_EXAMINATION,
  GET_LOCAL_EXAMINATION_SUCCESS,
  GET_LOCAL_EXAMINATION_FAILED,
  REFRESH_THERMAL_SUCCESS,
  REFRESH_THERMAL_FAILED,
  REQUEST
};

export const initialState = {
  id: null,
  status: null,
  details: null, // frames
  createdAt: null, // start of the exam
  progresses: [],
  errors: null
};

const localExamination = (state = initialState, action) => {
  if (!action) return state;

  switch (action.type) {
    case REQUEST:
      if (
        action.payload &&
        (action.payload.action === 'REFRESH_THERMAL' ||
          action.payload.action === 'REFRESH_CARV')
      ) {
        return {
          ...state,
          status: 'pending',
          errors: null
        };
      }

      return {
        ...state,
        errors: null
      };

    case GET_LOCAL_EXAMINATION_SUCCESS:
    case START_EXAMINATION_SUCCESS:
      return {
        ...(action.payload &&
          action.payload.data &&
          action.payload.data.examination),
        runningId: action.payload.data.runningId,
        errors: null
      };

    case GET_LOCAL_EXAMINATION_FAILED:
      return {
        ...state,
        errors: action.payload
      };

    case CLEAN_LOCAL_EXAMINATION:
    case 'FLUSH':
      return initialState;
    default:
      return state;
  }
};

export const flushLocalExamination = () => ({
  type: CLEAN_LOCAL_EXAMINATION
});

export const Actions = {
  flushLocalFiles: flushLocalExamination
};

export default localExamination;
