import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ViewTitle extends Component {
  static propTypes = {
    title: PropTypes.string
  };

  render() {
    const { title } = this.props;
    return <h1 className="route-title">{title}</h1>;
  }
}

export default ViewTitle;
