import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SearchPatientsView from './search-patients-view';
import i18n from '../../../i18n';

export default class SearchPatients extends Component {
  static propTypes = {
    patients: PropTypes.arrayOf(PropTypes.shape())
  };

  // Pre-defined searchfields for searching patients
  static SearchFields = [
    {
      title: i18n.t('searchInfo.byName'),
      name: 'name',
      placeholder: i18n.t('searchPlaceHolder.name')
    },
    {
      title: i18n.t('searchInfo.byExamComment'),
      name: 'comment',
      placeholder: i18n.t('searchPlaceHolder.comment')
    },
    {
      title: i18n.t('searchInfo.byExamDate'),
      name: 'date',
      placeholder: '2018-08-11'
    }
  ];

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      search: {}
    };
    this.searchChanged = this.searchChanged.bind(this);
    this.closeSearch = this.closeSearch.bind(this);
  }

  async searchChanged(obj) {
    // Set state's search date
    // await it!
    await this.setState(prevState => ({
      ...prevState,
      search: {
        ...prevState.search,
        [obj.name]: obj.value
      }
    }));

    // Get search from state
    const { search } = this.state;
    let isBlank = true;

    // Iterate through all keys, check wheter they are empty or not
    Object.keys(search).forEach(key => {
      // If not empty, set isBlank false
      if (search[key] && search[key] !== '') {
        isBlank = false;
      }
    });

    // Open search results
    this.setState({
      isOpen: !isBlank
    });
  }

  filterPatients(patients) {
    const { search } = this.state;

    let searchedPatients = patients;

    // filter by name first
    if (search.name && search.name !== '') {
      const re = new RegExp(
        search.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
        'i'
      );
      searchedPatients = searchedPatients.filter(
        p =>
          re.test(
            `${p.firstName
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')} ${p.lastName
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')}`
          ) && p
      );
    }

    // further filter by comment
    if (search.comment && search.comment !== '') {
      const re = new RegExp(
        search.comment.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
        'i'
      );
      searchedPatients = searchedPatients.filter(
        p =>
          re.test(
            p.lastExamination &&
              p.lastExamination.comment &&
              p.lastExamination.comment
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
          ) && p
      );
    }

    // further filter by date
    if (search.date && search.date !== '') {
      const re = new RegExp(search.date, 'i');
      searchedPatients = searchedPatients.filter(
        p => re.test(p.lastExamination && p.lastExamination.date) && p
      );
    }

    return searchedPatients;
  }

  closeSearch() {
    this.setState({
      isOpen: false
    });
  }

  render() {
    const { isOpen } = this.state;
    const { patients } = this.props;

    const filteredPatients = this.filterPatients(patients);

    return (
      <SearchPatientsView
        searchChanged={this.searchChanged}
        patients={filteredPatients}
        isOpen={isOpen}
        closeSearch={this.closeSearch}
        searchFields={SearchPatients.SearchFields}
      />
    );
  }
}
