/* eslint react/jsx-wrap-multilines: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line

import i18n from '../../../i18n';

import { getModalFields, ModalTypes } from '../modal-fields';
import NewExaminationView from './new-examination-view';

class NewExamination extends Component {
  static propTypes = {
    patientId: PropTypes.number.isRequired,
    isOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    addExamination: PropTypes.func.isRequired,
    defaults: PropTypes.shape({})
  };

  constructor(props) {
    super(props);
    this.fields = getModalFields(ModalTypes.Examination, props.defaults);
    this.buttons = this.setupButtons();
  }

  setupButtons() {
    return [
      {
        name: 'addExamination',
        type: 'submit',
        text: i18n.t('modalActions.addExamination'),
        doValidate: true, // optional (true by default)
        submit: that => {
          this.addExamination(that);
        }
      }
    ];
  }

  addExamination(that) {
    const { addExamination, patientId } = this.props;
    const { state } = that;
    const fields = { ...state.fields };
    const examination = {
      weight: parseFloat(fields.weight.value) || -1,
      height: parseFloat(fields.height.value) || -1,
      date: new Date(fields.date.value).toISOString(),
      // examType: fields.examType.value.toLowerCase(),
      // Bulk examType:
      // @TODO: Remove if necessary
      clInjury: fields.clInjury.value,
      clSurgery: fields.clSurgery.value,
      clReplacement: fields.clReplacement.value,
      examType: 'treadmill',
      comment: fields.comment.value,
      patientId
    };

    addExamination(examination);
  }

  render() {
    const { isOpen, closeModal } = this.props;
    return (
      <NewExaminationView
        modalType="examination"
        fields={this.fields}
        buttons={this.buttons}
        isOpen={isOpen}
        closeModal={closeModal}
      />
    );
  }
}

export default NewExamination;
