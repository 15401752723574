import React, { Component } from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line
import { NavLink, Link } from 'react-router-dom';
import '../../../styles/sidebar.css';

import i18n from '../../../i18n';

class SidebarView extends Component {
  static propTypes = {
    user: PropTypes.shape({
      name: PropTypes.string,
      username: PropTypes.string,
      token: PropTypes.string
    }),
    navigationRoutes: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
    signOutHandler: PropTypes.func.isRequired
  };

  static renderNavigation(routes) {
    return routes.map(route => (
      <NavLink key={route.link} to={route.link} className="menu-item">
        <div className="menu-item-div">
          <h2>{route.label}</h2>
        </div>
      </NavLink>
    ));
  }

  render() {
    const { user } = this.props;
    const { navigationRoutes } = this.props;
    const { signOutHandler } = this.props;
    return (
      <div className="side-bar">
        <div className="app-name">
          <Link to="/recent">
            <img src="/assets/images/logo_white.png" alt="Orthosera" />
          </Link>
        </div>
        <div className="user">
          <h1>{user && (user.name || user.username)}</h1>
          <div className="actions">
            {/* <p className="edit" type="button" role="presentation">
              {i18n.t(`profileActions.editProfile`)}
            </p> */}
            <p
              className="logout"
              type="button"
              role="presentation"
              onClick={signOutHandler}
            >
              {i18n.t(`profileActions.logout`)}
            </p>
          </div>
        </div>
        <div className="menu">
          {SidebarView.renderNavigation(navigationRoutes)}
        </div>
      </div>
    );
  }
}

export default SidebarView;
