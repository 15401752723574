import { connect } from 'react-redux';
import EditPatient from './edit-patient';
import { updatePatient, print } from '../../../reducers/request';

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
  print,
  updatePatient
})(EditPatient);
