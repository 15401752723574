import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import TimerView from './timer-view';

export default class Timer extends Component {
  static propTypes = {
    time: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      actualTime: moment().valueOf()
    };
  }

  componentDidMount() {
    this.timer = setInterval(() => {
      this.setState({ actualTime: moment().valueOf() });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    const { time } = this.props;
    const { actualTime } = this.state;

    const elapsedTime = moment(moment(actualTime) - moment(time)).format(
      'm:ss'
    );

    return <TimerView time={elapsedTime} />;
  }
}
