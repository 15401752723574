/* globals window document Blob */
import env from '../../config';
import ApiHandlerService, { ResponseTypes } from '../../services/api-handler';

export default class FilesApi {
  static async download(variables, token) {
    const id = variables && variables.id;
    const type = variables && variables.type;
    const file = variables && variables.file;
    const fromServer = variables && variables.fromServer;

    // @NOTE:
    // file names are not conventional yet, needs formatting
    let fileName = '';
    if (file) {
      const splitted = file.split('/');
      fileName = splitted[splitted.length - 1];
    }

    if (fromServer) {
      // Download data from server
      let typeSrv = fileName.split('.');
      typeSrv = typeSrv[typeSrv.length - 1];
      // Get data from server
      let urlType = '';
      if (typeSrv === 'jpg') {
        urlType = 'image';
      } else if (typeSrv === 'mp4') {
        urlType = 'video';
      } else {
        urlType = 'json';
      }

      const url = `${env.API_BASE_URL}/download/${urlType}/${id}/${fileName}`;
      const config = {
        headers: {
          authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Cache-Control': 'no-store'
        },
        method: 'GET'
      };

      const resp = await ApiHandlerService.fetch(
        url,
        config,
        urlType === 'json' ? ResponseTypes.json : ResponseTypes.blob
      );

      if (resp.ok === false) {
        return resp;
      }

      if (urlType === 'json') {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        const blob = new Blob([resp.data.data.data], {
          type: 'application/json'
        });
        a.href = window.URL.createObjectURL(blob);
        a.download = resp.data.data.fileName;
        a.click();
      } else {
        const fileObjectURL = URL.createObjectURL(resp.data);
        window.open(fileObjectURL, '_blank');
      }
    } else {
      // Download data from local
      // (open API call in new window -> show response image)
      const rnd = Math.floor(Math.random() * 99);
      // add a random number as a qs param to avoid loading from cache
      const url = `${env.LOCAL_API_BASE_URL}/download/${id}/${type}/${fileName}?x=${rnd}`;
      window.open(url, 'blank');
    }

    return null;
  }
}
