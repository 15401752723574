import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ThermalLimitView from './thermal-limit-view';

export default class ThermalLimit extends Component {
  static propTypes = {
    thermalLimit: PropTypes.string,
    updateSettings: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      isChanging: false,
      changedData: {
        thermalLimit: ''
      }
    };
    this.save = this.save.bind(this);
    this.cancelChange = this.cancelChange.bind(this);
    this.startChange = this.startChange.bind(this);
    this.setData = this.setData.bind(this);
  }

  setData(obj) {
    this.setState(prevState => ({
      ...prevState,
      changedData: { ...prevState.changedData, [obj.name]: obj.value }
    }));
  }

  startChange() {
    const { thermalLimit } = this.props;
    this.setState({
      isChanging: true,
      changedData: {
        thermalLimit
      }
    });
  }

  cancelChange() {
    this.setState({
      isChanging: false,
      changedData: {
        thermalLimit: ''
      }
    });
  }

  save() {
    const { updateSettings } = this.props;
    const { changedData } = this.state;
    updateSettings({ ...changedData });
    this.cancelChange();
  }

  render() {
    const { isChanging, changedData } = this.state;
    const { thermalLimit } = this.props;

    return (
      <ThermalLimitView
        changedData={changedData}
        isChanging={isChanging}
        thermalLimit={thermalLimit}
        startChange={this.startChange}
        cancelChange={this.cancelChange}
        setData={this.setData}
        save={this.save}
      />
    );
  }
}
