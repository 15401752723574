import { Types as ExaminationTypes } from '../examinations';
import { REQUEST, CHECK_LOCAL_BACKEND } from '../request';

const START_API_FETCHING = 'START_API_FETCHING';
const STOP_API_FETCHING = 'STOP_API_FETCHING';
const APP_SIGN_IN_ERROR = 'APP_SIGN_IN_ERROR';
const APP_SIGN_UP_ERROR = 'APP_SIGN_UP_ERROR';
const APP_SIGN_UP_SUCCESS = 'APP_SIGN_UP_SUCCESS';
const MENU_TOOGLE = 'MENU_TOOGLE';
const EXAMINATION_ACTION_ENABLED = 'EXAMINATION_ACTION_ENABLED';
const EXAMINATION_ACTION_DISABLED = 'EXAMINATION_ACTION_DISABLED';
const LOCAL_BACKEND_AVAILABLE = 'LOCAL_BACKEND_AVAILABLE';
const LOCAL_BACKEND_NOT_AVAILABLE = 'LOCAL_BACKEND_NOT_AVAILABLE';

export const Types = {
  START_API_FETCHING,
  STOP_API_FETCHING,
  APP_SIGN_IN_ERROR,
  APP_SIGN_UP_ERROR,
  APP_SIGN_UP_SUCCESS,
  MENU_TOOGLE,
  EXAMINATION_ACTION_ENABLED,
  EXAMINATION_ACTION_DISABLED
};

export const initialState = {
  isMenuOpen: true,
  isFetching: false,
  signInErrors: [],
  signUpErrors: {},
  signedUp: false,
  isExaminationStartable: true,
  isLocalBackendAvailable: true
};

const app = (state = initialState, action) => {
  if (!action) return state;
  const { type, payload } = action;

  switch (type) {
    case REQUEST:
      return {
        ...state,
        isFetching:
          action.payload && action.payload.action === CHECK_LOCAL_BACKEND
      };
    case START_API_FETCHING:
      return {
        ...state,
        isFetching: true,
        signInErrors: [],
        signUpErrors: {}
      };
    case STOP_API_FETCHING:
      return {
        ...state,
        isFetching: false,
        signedUp: false
      };
    case APP_SIGN_IN_ERROR:
      return {
        ...state,
        signInErrors: [...payload]
      };
    case APP_SIGN_UP_ERROR:
      return {
        ...state,
        signUpErrors: payload
      };
    case APP_SIGN_UP_SUCCESS:
      return {
        ...state,
        isFetching: false,
        signedUp: true,
        signInErrors: [],
        signUpErrors: {}
      };
    case MENU_TOOGLE:
      return {
        ...state,
        isMenuOpen: !state.isMenuOpen
      };
    case EXAMINATION_ACTION_ENABLED:
      return {
        ...state,
        isExaminationStartable: true
      };
    case EXAMINATION_ACTION_DISABLED:
      return {
        ...state,
        isExaminationStartable: false
      };
    case LOCAL_BACKEND_AVAILABLE:
      return {
        ...state,
        isFetching: false,
        isLocalBackendAvailable: true
      };
    case LOCAL_BACKEND_NOT_AVAILABLE:
      return {
        ...state,
        isFetching: false,
        isLocalBackendAvailable: false
      };
    case ExaminationTypes.PAIR_DEVICE_SUCCESS:
      return {
        ...state,
        pairedId:
          action.payload &&
          action.payload.data &&
          action.payload.data.examinationId
      };
    default:
      return state;
  }
};

export const startApiFetching = () => ({ type: START_API_FETCHING });

export const stopApiFetching = () => ({ type: STOP_API_FETCHING });

export const signInError = (...messages) => ({
  type: APP_SIGN_IN_ERROR,
  payload: messages
});

export const signUpError = messages => ({
  type: APP_SIGN_UP_ERROR,
  payload: messages
});

export const signUpSuccess = () => ({ type: APP_SIGN_UP_SUCCESS });

export const menuToogle = () => ({
  type: MENU_TOOGLE
});

export const examinationActionEnable = () => ({
  type: EXAMINATION_ACTION_ENABLED
});

export const examinationActionDisable = () => ({
  type: EXAMINATION_ACTION_DISABLED
});

export const localBackendAvailable = () => ({
  type: LOCAL_BACKEND_AVAILABLE
});

export const localBackendNotAvailable = () => ({
  type: LOCAL_BACKEND_NOT_AVAILABLE
});

export const Actions = {
  startApiFetching,
  stopApiFetching,
  signInError,
  signUpError,
  signUpSuccess,
  menuToogle,
  examinationActionEnable,
  examinationActionDisable,
  localBackendAvailable,
  localBackendNotAvailable
};

export default app;
