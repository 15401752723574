import { connect } from 'react-redux';
import NewPatient from './new-patient';
import { addPatient, print } from '../../../reducers/request';

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
  print,
  addPatient
})(NewPatient);
