import { takeLatest, fork, put } from 'redux-saga/effects';
import { Types as ProgressTypes } from '../../reducers/progress';
import { Types as FileTypes } from '../../reducers/files';
import { examinationActionEnable } from '../../reducers/app';
import {
  Actions as NotificationsActions,
  MessageTypes
} from '../../reducers/notifications';

export function* progressKilled() {
  yield put(
    NotificationsActions.showNotification({
      message: 'progressKilled',
      type: MessageTypes.info,
      flash: true
    })
  );
  yield put(examinationActionEnable());
}

export function* carvMessage(action) {
  yield put(
    NotificationsActions.showNotification({
      message: action.payload.message,
      type: MessageTypes.info,
      flash: true
    })
  );
  yield put(examinationActionEnable());
}

export function* thermalError() {
  yield put(examinationActionEnable());
}

function* progressSaga() {
  yield fork(takeLatest, ProgressTypes.KILL_PROGRESSES_SUCCESS, progressKilled);
  yield fork(takeLatest, FileTypes.REFRESH_CARV_SUCCESS, carvMessage);
  yield fork(
    takeLatest,
    [FileTypes.REFRESH_THERMAL_FAILED, FileTypes.REFRESH_CARV_FAILED],
    thermalError
  );
}

export default progressSaga;
