import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { WarningIcon } from '../../../../icons';

export default class AngleTableView extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape()),
    specialData: PropTypes.shape(),
    isError: PropTypes.bool,
    selectedPoint: PropTypes.string,
    handleInputChange: PropTypes.func
  };

  renderRows() {
    const { data } = this.props;

    if (!data) {
      return null;
    }

    return data.map(row => (
      <React.Fragment key={row.key}>
        <div style={row.margin ? { marginBottom: '12px' } : {}}>
          <p>{row.key}</p>
        </div>
        <div style={row.margin ? { marginBottom: '12px' } : {}}>
          <p>
            {row.right && !Number.isNaN(row.right) ? (
              row.right.toFixed(2)
            ) : (
              <WarningIcon />
            )}
          </p>
        </div>
        <div style={row.margin ? { marginBottom: '12px' } : {}}>
          <p>
            {row.left && !Number.isNaN(row.left) ? (
              row.left.toFixed(2)
            ) : (
              <WarningIcon />
            )}
          </p>
        </div>
      </React.Fragment>
    ));
  }

  renderSpecialRows() {
    const { selectedPoint, handleInputChange, specialData } = this.props;

    const values = specialData;

    return (
      <>
        <div>
          Average valgus knee diff at
          <input
            type="text"
            value={selectedPoint}
            onChange={handleInputChange}
          />
          % squat depth
        </div>
        <div>
          <p>
            {values &&
            values.avgValgusRight &&
            values.avgValgusRight.toFixed(2) !== '0.00' &&
            !Number.isNaN(values.avgValgusRight)
              ? values.avgValgusRight.toFixed(2)
              : ''}
          </p>
        </div>
        <div>
          <p>
            {values &&
            values.avgValgusLeft &&
            values.avgValgusLeft.toFixed(2) !== '0.00' &&
            !Number.isNaN(values.avgValgusLeft)
              ? values.avgValgusLeft.toFixed(2)
              : ''}
          </p>
        </div>

        <div>
          Average varus knee diff at
          <input
            type="text"
            value={selectedPoint}
            onChange={handleInputChange}
          />
          % squat depth
        </div>
        <div>
          <p>
            {values &&
            values.avgVarusRight &&
            values.avgVarusRight.toFixed(2) !== '0.00' &&
            !Number.isNaN(values.avgVarusRight)
              ? values.avgVarusRight.toFixed(2)
              : ''}
          </p>
        </div>
        <div>
          <p>
            {values &&
            values.avgVarusLeft &&
            values.avgVarusLeft.toFixed(2) !== '0.00' &&
            !Number.isNaN(values.avgVarusLeft)
              ? values.avgVarusLeft.toFixed(2)
              : ''}
          </p>
        </div>
      </>
    );
  }

  render() {
    const { isError } = this.props;
    return (
      <>
        <h2 className="angles-header">
          Angles
          {isError ? (
            <span className="error">
              <WarningIcon />
              Check if all action points are in place and height of patient is
              set
            </span>
          ) : null}
        </h2>
        <div className="angle-grid">
          <div>
            <p />
          </div>
          <div>
            <p>Right</p>
          </div>
          <div>
            <p>Left</p>
          </div>
          {this.renderRows()}
          {this.renderSpecialRows()}
        </div>
      </>
    );
  }
}
