import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class CarvCredentialsView extends Component {
  static propTypes = {
    carvCredentials: PropTypes.shape(),
    isChanging: PropTypes.bool,
    cancelChange: PropTypes.func,
    changedData: PropTypes.shape(),
    startChange: PropTypes.func,
    setData: PropTypes.func,
    save: PropTypes.func
  };

  render() {
    const {
      carvCredentials,
      isChanging,
      cancelChange,
      changedData,
      startChange,
      setData,
      save
    } = this.props;

    return isChanging ? (
      <div className="setting">
        <h2>Credentials (username / password)</h2>

        <input
          type="text"
          placeholder="Username"
          value={changedData.username}
          onChange={e => setData({ name: 'username', value: e.target.value })}
        />
        <input
          type="password"
          placeholder="Password"
          value={changedData.password}
          onChange={e => setData({ name: 'password', value: e.target.value })}
        />

        <button type="button" onClick={() => cancelChange()}>
          Cancel
        </button>
        <button type="button" onClick={() => save()}>
          Save
        </button>
      </div>
    ) : (
      <div className="setting">
        <h2>Credentials (username / password)</h2>

        <input
          disabled
          type="text"
          placeholder="No username set yet"
          value={carvCredentials && carvCredentials.username}
        />

        <input
          disabled
          type="password"
          placeholder="No password set yet"
          value={carvCredentials && carvCredentials.password}
        />

        <button type="button" onClick={() => startChange()}>
          Change
        </button>
      </div>
    );
  }
}
