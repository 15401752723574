import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Skeleton from './skeleton';
import Player from './player';
import Controls from './controls';
import KeyPoints from './key-points';
import AngleTable from './angle-table';
// import ManualQAngle from './manual-q-angle';
// import Report from './report';
import { AddIcon } from '../../../icons';
import Calculations from '../../../../services/calculations';
// import CarvSync from './carv-sync';
// import { jointTypes } from '../../../../services/joints';

export default class EvaluationView extends Component {
  static propTypes = {
    videoSource: PropTypes.string,
    examinationId: PropTypes.number,
    examination: PropTypes.shape(),
    // comments: PropTypes.string,
    skeleton: PropTypes.arrayOf(PropTypes.any),
    skeletonForCalculation: PropTypes.arrayOf(PropTypes.any),
    carv: PropTypes.shape(),
    keyPoints: PropTypes.arrayOf(PropTypes.shape()),
    unformattedKeyPoints: PropTypes.shape(),
    chartData: PropTypes.shape(),
    // patient: PropTypes.shape(),
    updateEvaluation: PropTypes.func,
    selectNearestData: PropTypes.func,
    // selectNearestCarv: PropTypes.func,
    calculateReportData: PropTypes.func,
    calculateSpecialReportData: PropTypes.func,
    // updateRemoteExamination: PropTypes.func,
    exportTimeSeries: PropTypes.func,
    exportRawDataset: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
    this.state = {
      time: 0,
      isPlaying: false,
      duration: null,
      keyPoints: [],
      selectedKeyPoint: null,
      calculatedFrameData: null,
      isDragging: false,
      isKeyPointsChanged: false,
      isReportOpen: false,
      // isSyncOpen: false,
      bubbleStyle: {}
    };
    this.setElapsedTime = this.setElapsedTime.bind(this);
    this.setPlaying = this.setPlaying.bind(this);
    this.setPaused = this.setPaused.bind(this);
    this.setDuration = this.setDuration.bind(this);
    this.setDraggedTime = this.setDraggedTime.bind(this);
    this.setDraggedKeyPoint = this.setDraggedKeyPoint.bind(this);
    this.setDragging = this.setDragging.bind(this);
    this.setToCurrentTime = this.setToCurrentTime.bind(this);
    this.setSelectedKeyPoint = this.setSelectedKeyPoint.bind(this);
    this.jumpTo = this.jumpTo.bind(this);
    this.jumpToFrame = this.jumpToFrame.bind(this);
    // this.closeSync = this.closeSync.bind(this);
  }

  componentDidMount() {
    const { keyPoints } = this.props;
    this.setState({ keyPoints });
  }

  /**
   * @NOTE
   * Called when:
   * 1. Video is playing
   * 2. User steps frames
   *
   * Set state time from video's actual time
   * Calculates frame data if necessarry
   */
  setElapsedTime(shouldCalculate = false) {
    const video = this.videoRef && this.videoRef.current;
    if (video) {
      const time = parseFloat(video.currentTime);
      this.setTime(time, shouldCalculate);
    }
  }

  /**
   * @NOTE
   * Called when:
   * 1. User drags video seeker
   *
   * Set state time from seeker
   * Change video's actual time
   */
  setDraggedTime(event, shouldCalculate = false) {
    event.preventDefault();

    const { selectNearestData, skeletonForCalculation } = this.props;

    const time = parseFloat(event.target.value);

    const nearestTime = selectNearestData(time, skeletonForCalculation, 'time');

    this.setTime(nearestTime, shouldCalculate);

    // Set video's actual time
    const video = this.videoRef && this.videoRef.current;
    if (video) {
      video.currentTime = nearestTime;
    }
    event.target.blur();
  }

  /**
   * Sets state `time` from parameter
   * Calculates data for actual frame if necessarry
   */
  setTime(time, shouldCalculate) {
    if (shouldCalculate) {
      // Calculate frame data
      this.calculateFrameData(time);
    }
    this.setState({ time });
  }

  /**
   * Set state `duration` from video's duration
   */
  setDuration() {
    const video = this.videoRef && this.videoRef.current;
    if (video) {
      this.setState({ duration: video.duration });
    }
  }

  /**
   * @NOTE
   * Called when:
   * 1. Video starts playing
   *
   * Sets state `isPlaying` to true
   */
  setPlaying() {
    this.setState({ isPlaying: true });
  }

  /**
   * @NOTE
   * Called when:
   * 1. Video stops playing
   *
   * Sets state `isPlaying` to false
   * Sets state `calculatedFrameData` properly
   */
  setPaused() {
    const { selectNearestData, skeletonForCalculation } = this.props;
    const video = this.videoRef && this.videoRef.current;
    if (video) {
      const videoTime = video.currentTime;
      const nearestTime = selectNearestData(
        videoTime,
        skeletonForCalculation,
        'time'
      );
      this.setTime(nearestTime, true);

      // Set video's actual time
      video.currentTime = nearestTime;
      this.setState({ isPlaying: false });
    }
  }

  setKeypointManualQAngle(keypointId, manualQPoints) {
    const { keyPoints } = this.state;
    const { time } = this.state;
    const newKeyPoints = keyPoints.map(kp => {
      if (kp.id === keypointId) {
        const newKeypoint = kp;
        if (manualQPoints.length > 0) {
          newKeypoint.manualQAnglePoints = manualQPoints;
        } else if (newKeypoint.manualQAnglePoints) {
          delete newKeypoint.manualQAnglePoints;
        }
        return newKeypoint;
      }
      return kp;
    });
    this.setState({ keyPoints: newKeyPoints });
    this.calculateFrameData(time);
    this.formatAndUpdate();
  }

  setDragging(isDragging, isKeypoint = false, keypointObject = null) {
    this.setState({ isDragging });
    if (isKeypoint && !isDragging && keypointObject !== null) {
      const { isKeyPointsChanged } = this.state;
      if (isKeyPointsChanged) {
        this.formatAndUpdate();
      }
    }
  }

  /**
   * @NOTE
   * Called when:
   * 1. User drags a keypoint to a new position
   *
   * Sets dragged keypoint's new value
   */
  setDraggedKeyPoint(event, id) {
    const { value } = event.target;
    const { keyPoints, duration } = this.state;

    const draggedKeyPoint = keyPoints.find(point => point.id === id);
    if (draggedKeyPoint.value !== parseFloat(value)) {
      this.setNewKeyPoints(parseFloat(value), id);
    }
    event.target.blur();

    this.setState({
      bubbleStyle: {
        id,
        style: { left: `${(value * 100) / duration}%` }
      }
    });
  }

  /**
   * @NOTE
   * Called when:
   * 1. User double-clicks a keypoint
   *
   * Sets double-clicked keypoint's value to actual time
   */
  setToCurrentTime(event, id) {
    const { time } = this.state;
    this.setNewKeyPoints(time, id);
    event.target.blur();
  }

  /**
   * Sets state `keyPoints` changing:
   * The point's (which has the id of `id`) value to `time`
   */
  setNewKeyPoints(time, id) {
    const { keyPoints } = this.state;
    const { selectNearestData, skeletonForCalculation } = this.props;
    const newKeyPoints = keyPoints.map(point => {
      if (point.id === id) {
        const newPoint = point;
        const nearestTime = selectNearestData(
          time,
          skeletonForCalculation,
          'time'
        );
        newPoint.value = nearestTime;
        return newPoint;
      }
      return point;
    });
    this.setState({ keyPoints: newKeyPoints, isKeyPointsChanged: true });
  }

  /**
   * @NOTE
   * Called when:
   * 1. User clicks on key point
   *
   * Sets state `selectedKeyPoint`
   * It stores the `id` of the clicked keyPoint
   */
  setSelectedKeyPoint(id) {
    this.setState({ selectedKeyPoint: id });
  }

  /**
   * Calculates data for the nearest frame to `videoTime` and sets it to state
   */
  calculateFrameData(videoTime) {
    // const { keyPoints } = this.state;
    const { selectNearestData, skeletonForCalculation, keyPoints } = this.props;

    const actualBody = selectNearestData(videoTime, skeletonForCalculation);

    const calculatedFrameData = [];

    if (!actualBody) {
      this.setState({ calculatedFrameData });
      return;
    }

    // Knee-Hip calculations
    const kneeHipLeftX = Calculations.kneeHipLeftX(
      actualBody,
      skeletonForCalculation,
      keyPoints
    );
    const kneeHipRightX = Calculations.kneeHipRightX(
      actualBody,
      skeletonForCalculation,
      keyPoints
    );

    // WristLeft - SpineBase vertical diff (cm)
    const wristSpineBaseLeft = Calculations.wristSpineBaseLeft(actualBody);
    const wristSpineBaseRight = Calculations.wristSpineBaseRight(actualBody);

    // SpineBase & SpineMid height
    const spineBaseHeight = Calculations.spineBaseHeight(actualBody);
    const spineMidX = Calculations.spineMidX(
      actualBody,
      skeletonForCalculation
    );

    // Add calculated pieces of data to list
    calculatedFrameData.push(
      {
        type: 'kinect',
        name: 'Left knee shift: + medial / - lateral',
        value: kneeHipLeftX,
        unit: 'cm'
      },
      {
        type: 'kinect',
        name: 'Right knee shift: + medial / - lateral',
        value: kneeHipRightX,
        unit: 'cm'
      },
      {
        type: 'kinect',
        name: 'WristLeft - SpineBase vertical diff',
        value: wristSpineBaseLeft,
        unit: 'cm'
      },
      {
        type: 'kinect',
        name: 'WristRight - SpineBase vertical diff',
        value: wristSpineBaseRight,
        unit: 'cm'
      },
      {
        type: 'kinect',
        name: 'SpineBase height',
        value: spineBaseHeight,
        unit: 'cm'
      },
      {
        type: 'kinect',
        name: 'Chest',
        value: spineMidX,
        unit: 'cm'
      }
    );

    this.setState({ calculatedFrameData });
  }

  /**
   * Adds a new keypoint with `type`
   */
  addKeyPoint(type) {
    const { selectNearestData, skeletonForCalculation } = this.props;
    const { keyPoints, time } = this.state;
    const newKeyPoints = keyPoints;
    let id = 1;
    // Define new id
    newKeyPoints.forEach(point => {
      if (point.id >= id) {
        id = point.id + 1;
      }
    });
    const nearestTime = selectNearestData(time, skeletonForCalculation, 'time');
    newKeyPoints.push({ id, type, value: nearestTime });
    this.formatAndUpdate(newKeyPoints);
    this.setState({ keyPoints: newKeyPoints });
  }

  /**
   * Removes keypoint with `id`
   */
  removeKeyPoint(id) {
    const { keyPoints } = this.state;
    const newKeyPoints = keyPoints.filter(point => point.id !== id);
    this.formatAndUpdate(newKeyPoints);
    this.setState({ keyPoints: newKeyPoints, selectedKeyPoint: null });
  }

  jumpToFrame(type) {
    const { selectNearestData, skeletonForCalculation } = this.props;
    const video = this.videoRef && this.videoRef.current;
    if (video) {
      const time = parseFloat(video.currentTime);
      const nearestTime = selectNearestData(time, skeletonForCalculation, type);

      this.setTime(nearestTime, true);
      video.currentTime = nearestTime;
    }
  }

  jumpTo(event, type) {
    event.preventDefault();

    const { time, keyPoints } = this.state;
    const possibleKeyPoints = keyPoints.filter(keyPoint => {
      if (type === 'next') {
        if (keyPoint.value > time) return true;
        return false;
      }
      if (type === 'previous') {
        if (keyPoint.value < time) return true;
        return false;
      }
      return false;
    });

    if (possibleKeyPoints.length === 0) {
      return;
    }

    let closest = null;
    possibleKeyPoints.forEach(keyPoint => {
      if (!closest) {
        closest = keyPoint.value;
      }
      if (type === 'next') {
        if (keyPoint.value - time < closest - time) {
          closest = keyPoint.value;
        }
      }
      if (type === 'previous') {
        if (time - keyPoint.value < time - closest) {
          closest = keyPoint.value;
        }
      }
    });

    this.setTime(closest, true);

    // Set video's actual time
    const video = this.videoRef && this.videoRef.current;
    if (video) {
      video.currentTime = closest;
    }
    event.target.blur();
  }

  /**
   * Formats keypoints to match backend needs
   * and sends update request
   */
  formatAndUpdate(keyPointsAsParams = null) {
    const {
      examinationId,
      updateEvaluation,
      calculateReportData,
      skeletonForCalculation,
      examination
    } = this.props;

    let { keyPoints } = this.state;
    if (keyPointsAsParams) {
      keyPoints = keyPointsAsParams;
    }

    const reportData = calculateReportData(
      keyPoints,
      skeletonForCalculation,
      examination
    );

    const formattedKeyPoints = {};
    const keys = [];

    // define keys
    keyPoints.forEach(point => {
      if (!keys.includes(point.type)) {
        keys.push(point.type);
      }
    });

    // format key points
    keys.forEach(key => {
      // formattedkey example:
      // left-leg-points -> leftLegPoints
      let formattedKey = key
        .split('-')
        .map(element => element.charAt(0).toUpperCase() + element.slice(1))
        .join('');
      formattedKey =
        formattedKey.charAt(0).toLowerCase() + formattedKey.slice(1);

      // define object property
      formattedKeyPoints[formattedKey] = [];

      // add every element to property object with the exact type
      keyPoints.forEach(point => {
        if (point.type === key) {
          const obj = { value: point.value };
          if (point.manualQAnglePoints) {
            obj.points = point.manualQAnglePoints;
          }
          formattedKeyPoints[formattedKey].push(obj);
        }
      });
    });

    // call API update
    updateEvaluation(examinationId, {
      keyPoints: formattedKeyPoints,
      calculations: reportData
    });

    this.setState({ isKeyPointsChanged: false });
  }

  /**
   * Syncing Carv delta
   */
  // openSync() {
  //   // To be implemented
  //   this.setState({ isSyncOpen: true });
  // }

  // closeSync() {
  //   // To be implemented
  //   this.setState({ isSyncOpen: false });
  // }

  /**
   * Renders key point buttons
   */
  renderButtons() {
    // const { carv } = this.props;
    const { selectedKeyPoint /* isSyncOpen */ } = this.state;

    return (
      <div className="key-point-buttons">
        <div>
          <button
            type="button"
            className="add-key-point add-key-point-start"
            onClick={() => this.addKeyPoint('start-points')}
          >
            <AddIcon className="icon-white" />
            Start
          </button>
          <button
            type="button"
            className="add-key-point add-key-point-left-squat"
            onClick={() => this.addKeyPoint('left-leg-points')}
          >
            <AddIcon className="icon-white" />
            Left Squat
          </button>
          <button
            type="button"
            className="add-key-point add-key-point-right-squat"
            onClick={() => this.addKeyPoint('right-leg-points')}
          >
            <AddIcon className="icon-white" />
            Right Squat
          </button>
          <button
            type="button"
            className="add-key-point add-key-point-left-stand"
            onClick={() => this.addKeyPoint('left-leg-stand-points')}
          >
            <AddIcon className="icon-white" />
            Left Stance
          </button>
          <button
            type="button"
            className="add-key-point add-key-point-right-stand"
            onClick={() => this.addKeyPoint('right-leg-stand-points')}
          >
            <AddIcon className="icon-white" />
            Right Stance
          </button>
          {/* {!isSyncOpen && carv && carv.left_carv && carv.right_carv ? (
            <button
              type="button"
              className="carv-sync-button"
              onClick={() => this.openSync()}
            >
              Sync carv
            </button>
          ) : null} */}
        </div>
        {selectedKeyPoint ? (
          <div>
            <button
              className="remove-key-point"
              type="button"
              onClick={() => this.removeKeyPoint(selectedKeyPoint)}
            >
              remove selected
            </button>
          </div>
        ) : null}
      </div>
    );
  }

  renderCharts() {
    const { chartData } = this.props;
    const {
      time,
      duration,
      keyPoints,
      selectedKeyPoint,
      bubbleStyle
    } = this.state;

    // Create components
    const chartComponents = Object.keys(chartData).map(key => {
      const chart = chartData[key];
      return (
        <KeyPoints
          key={key}
          id={chart.id}
          title={chart.title}
          data={chart.data}
          secondLineData={chart.secondLineData}
          duration={duration}
          time={time}
          chartOptions={chart.options}
          keyPoints={keyPoints}
          setDraggedKeyPoint={this.setDraggedKeyPoint}
          setToCurrentTime={this.setToCurrentTime}
          setSelected={this.setSelectedKeyPoint}
          selected={selectedKeyPoint}
          chartDomain={chart.domain}
          setDragging={this.setDragging}
          bubbleStyle={bubbleStyle}
        />
      );
    });

    return chartComponents;
  }

  render() {
    const {
      videoSource,
      skeleton,
      skeletonForCalculation,
      chartData,
      calculateReportData,
      calculateSpecialReportData,
      examinationId,
      examination,
      carv,
      // patient,
      // selectNearestCarv,
      // selectNearestData,
      // updateEvaluation,
      // comments,
      // updateRemoteExamination,
      exportTimeSeries,
      exportRawDataset,
      unformattedKeyPoints
    } = this.props;
    const {
      time,
      isPlaying,
      duration,
      calculatedFrameData,
      keyPoints,
      isDragging,
      isReportOpen
      // isSyncOpen
    } = this.state;

    // let actualKeyPoint = null;
    // if (!isPlaying) {
    //   actualKeyPoint = keyPoints.find(
    //     k =>
    //       k.value === time &&
    //       (k.type.includes('left') || k.type.includes('right'))
    //   );
    // }

    return (
      <div className="evaluation">
        <div className="video">
          <Controls
            isReportOpen={isReportOpen}
            duration={duration}
            time={time}
            skeleton={skeleton}
            skeletonForCalculation={skeletonForCalculation}
            videoRef={this.videoRef}
            isPlaying={isPlaying}
            setElapsedTime={this.setElapsedTime}
            setDraggedTime={this.setDraggedTime}
            calculatedFrameData={calculatedFrameData}
            setDragging={this.setDragging}
            jumpTo={this.jumpTo}
            jumpToFrame={this.jumpToFrame}
          />
          {/* {!isDragging && !isPlaying && actualKeyPoint ? (
            <ManualQAngle
              examinationVersion={examination.version}
              key={actualKeyPoint.id}
              isDragging={isDragging}
              keypoint={actualKeyPoint}
              editKeyPoint={qAnglePoints => {
                this.setKeypointManualQAngle(actualKeyPoint.id, qAnglePoints);
              }}
            />
          ) : null} */}
          <Skeleton
            skeleton={skeleton}
            videoTime={time}
            examinationId={examinationId}
            examination={examination}
          />
          <Player
            setPlaying={this.setPlaying}
            setPaused={this.setPaused}
            videoSource={videoSource}
            videoRef={this.videoRef}
            setElapsedTime={this.setElapsedTime}
            setDuration={this.setDuration}
          />
        </div>
        <div className="charts">
          {chartData && duration ? (
            <>
              {/* {isSyncOpen ? (
                <CarvSync
                  carv={carv}
                  close={this.closeSync}
                  videoTime={time}
                  examStartTime={examination.examStartTime}
                  carvDelta={examination.carvDelta}
                  updateCarvDelta={delta => {
                    updateRemoteExamination(examinationId, {
                      carvDelta: delta
                    });
                  }}
                />
              ) : null} */}
              {this.renderButtons()}
              {this.renderCharts()}
            </>
          ) : (
            <p>Loading chart data...</p>
          )}
        </div>
        <AngleTable
          isDragging={isDragging}
          keyPoints={keyPoints}
          unformattedKeyPoints={unformattedKeyPoints}
          skeleton={skeletonForCalculation}
          calculateReportData={calculateReportData}
          calculateSpecialReportData={calculateSpecialReportData}
          examination={examination}
        />
        <button
          type="button"
          onClick={() => {
            exportRawDataset(
              skeletonForCalculation,
              examination,
              carv,
              keyPoints
            );
          }}
          style={{ marginTop: 20, marginRight: 20 }}
        >
          Export RAW dataset
        </button>
        <button
          type="button"
          onClick={() => {
            exportTimeSeries(
              skeletonForCalculation,
              examination,
              carv,
              keyPoints
            );
          }}
          style={{ marginTop: 20, marginRight: 20 }}
        >
          Export time series
        </button>
        {/* {isReportOpen ? (
          <>
            <button
              style={{ marginTop: 20 }}
              type="button"
              onClick={() => this.setState({ isReportOpen: false })}
            >
              Hide report
            </button>
            <Report
              comments={comments}
              keyPoints={keyPoints}
              examination={examination}
              carv={carv}
              skeleton={skeletonForCalculation}
              skeletonForDisplay={skeleton}
              patient={patient}
              selectNearestCarv={selectNearestCarv}
              selectNearestData={selectNearestData}
              videoSource={videoSource}
              updateEvaluation={updateEvaluation}
            />
          </>
        ) : (
          <button
            type="button"
            onClick={() => this.setState({ isReportOpen: true })}
            style={{ marginTop: 20 }}
          >
            Show report
          </button>
        )} */}
      </div>
    );
  }
}
