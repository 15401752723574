import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import i18n from '../../i18n';
import Loader from '../../components/loader';
import ViewTitle from '../../components/view-title';
import ThermalPath from './setting-types/thermal-path';
import ThermalLimit from './setting-types/thermal-limit';
import CarvCredentials from './setting-types/carv-credentials';

class SettingsView extends Component {
  static propTypes = {
    settings: PropTypes.shape(),
    updateSettings: PropTypes.func,
    isFetching: PropTypes.bool,
    killPendingProgresses: PropTypes.func,
    carvSessions: PropTypes.arrayOf(PropTypes.shape())
  };

  render() {
    const {
      settings,
      updateSettings,
      isFetching,
      killPendingProgresses,
      carvSessions
    } = this.props;

    if (isFetching) {
      return <Loader message="Loading up settings..." />;
    }

    return (
      <div className="content-wrapper settings">
        <ViewTitle
          title={i18n.t('navigation.settings')}
          isFetching={isFetching}
        />

        <div className="setting-group">
          <h1>Thermal camera options</h1>
          <ThermalPath
            thermalPath={settings && settings.thermalPath}
            updateSettings={updateSettings}
          />
          <ThermalLimit
            thermalLimit={
              settings &&
              settings.thermalLimit &&
              settings.thermalLimit.toString()
            }
            updateSettings={updateSettings}
          />
        </div>

        <div className="setting-group">
          <h1>Carv Options</h1>
          <CarvCredentials
            carvCredentials={settings && settings.carvCredentials}
            updateSettings={updateSettings}
          />
        </div>

        <div className="setting-group">
          <h1>General settings &amp; actions</h1>
          <div className="setting">
            <button type="button" onClick={() => killPendingProgresses()}>
              Kill pending/running progresses
            </button>
          </div>
        </div>

        <div className="setting-group">
          <h1>Latest Carv sessions</h1>
          <div className="setting">
            <div className="carv-sessions">
              <p>Date</p>
              <p>Session ID</p>
              {carvSessions.map(session => (
                <React.Fragment key={session.start}>
                  <p className="carv-session">
                    {moment
                      .utc(session.start)
                      .local()
                      .format('YYYY MMM. D. HH:mm')}
                  </p>
                  <p className="carv-session-id">{session.id}</p>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SettingsView;
