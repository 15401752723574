/* globals window */

import { takeLatest, fork, put, call } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { Types as FileTypes } from '../../reducers/files';
import { examinationActionEnable } from '../../reducers/app';
import { getExamination } from '../../reducers/request';

// Saga that handles local backend call
export function* requestLoop(response) {
  // Check wheter upload is in progress
  let isUploadInProgress = false;

  if (response && response.payload && response.payload.files) {
    isUploadInProgress = checkIsUploadInProgress(response.payload.files);
  }

  // Delay next action
  yield call(delay, 3000);

  // Check if we still on a view which requires data
  // @TODO: If more views will use data, should implement with other approach
  const isStillOverview = window.location.pathname.includes('overview');

  // Check wheter re-downloading data from local-backend is necessarry
  if (isStillOverview) {
    // If no examinationId is received from previous call
    // -> stop request
    if (!response || !response.payload || !response.payload.examinationId) {
      return;
    }

    // Define examinationId from response
    const { examinationId } = response.payload;

    if (isUploadInProgress) {
      // If upload is still in progress
      // Fetch files again from local
      // yield put(getFilesLocal(examinationId));
    } else {
      yield put(examinationActionEnable());
      yield put(getExamination(examinationId));
    }
  }
}

function checkIsUploadInProgress(files) {
  const { thermalImages, kinectVideo } = files;

  let isUploadInProgress = false;

  if (thermalImages) {
    thermalImages.forEach(image => {
      if (
        image.status &&
        image.status !== 'completed' &&
        image.status !== 'failed'
      ) {
        isUploadInProgress = true;
      }
    });
  }

  if (!isUploadInProgress && kinectVideo) {
    kinectVideo.forEach(file => {
      if (
        file.status &&
        file.status !== 'completed' &&
        file.status !== 'failed'
      ) {
        isUploadInProgress = true;
      }
    });
  }

  return isUploadInProgress;
}

function* filesSaga() {
  yield fork(takeLatest, FileTypes.GET_FILES_LOCAL_SUCCESS, requestLoop);
}

export default filesSaga;
