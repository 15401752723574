import { takeLatest, fork, put } from 'redux-saga/effects';
import {
  localBackendAvailable,
  localBackendNotAvailable
} from '../../reducers/app';
import { Types } from '../../reducers/settings';
import {
  Actions as NotificationsActions,
  MessageTypes
} from '../../reducers/notifications';
import { getSettings } from '../../reducers/request';

export function* updated(response) {
  yield put(
    NotificationsActions.showNotification({
      message:
        (response && response.payload && response.payload.message) ||
        'Settings changed.',
      type: MessageTypes.Success,
      flash: true
    })
  );
  yield put(getSettings());
}

export function* setLocalBackendAvailable() {
  yield put(localBackendAvailable());
}

export function* setLocalBackendNotAvailable() {
  yield put(localBackendNotAvailable());
}

function* settingsSaga() {
  yield fork(takeLatest, Types.UPDATE_SETTINGS_SUCCESS, updated);
  yield fork(
    takeLatest,
    Types.CHECK_LOCAL_BACKEND_SUCCESS,
    setLocalBackendAvailable
  );
  yield fork(
    takeLatest,
    Types.CHECK_LOCAL_BACKEND_FAILED,
    setLocalBackendNotAvailable
  );
}

export default settingsSaga;
