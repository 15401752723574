import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  ErrorIcon,
  LoadingIcon,
  RefreshIcon,
  SuccessIcon,
  WarningIcon
} from '../../../../../icons';

class ThermalImages extends Component {
  static propTypes = {
    thermalImages: PropTypes.arrayOf(PropTypes.shape()),
    examinationId: PropTypes.string,
    fromServer: PropTypes.bool,
    status: PropTypes.string,
    enableResendData: PropTypes.bool,
    download: PropTypes.func,
    refreshThermal: PropTypes.func,
    examinationActionDisable: PropTypes.func
  };

  render() {
    const {
      thermalImages,
      download,
      examinationId,
      fromServer,
      status,
      refreshThermal,
      enableResendData,
      examinationActionDisable
    } = this.props;
    const isLoading = thermalImages.some(
      image =>
        image.status &&
        (image.status === 'pending' || image.status === 'started')
    );
    const isError =
      thermalImages.some(image => image.status && image.status === 'failed') ||
      status === 'failed';
    const isFiles = thermalImages.length > 0;

    let mainIcon = <WarningIcon />;

    // Define the main icon
    if (isFiles) {
      if (isError) {
        mainIcon = <ErrorIcon />;
      } else if (isLoading) {
        mainIcon = <LoadingIcon />;
      } else {
        mainIcon = <SuccessIcon />;
      }
    }

    return (
      <div>
        <h1 style={{ marginBottom: 10 }}>
          {mainIcon}
          Infrared camera thermal images
        </h1>
        <div className="files-box box">
          {thermalImages && thermalImages.length > 0 ? (
            thermalImages.map(image => {
              const globalStatus = status === 'failed' ? status : image.status;

              return (
                <div key={image.file} className="file">
                  <p
                    role="presentation"
                    onClick={() => {
                      download(
                        examinationId,
                        image.type,
                        image.file,
                        fromServer
                      );
                    }}
                  >
                    {(() => {
                      switch (globalStatus) {
                        case 'started':
                        case 'pending':
                          return <LoadingIcon />;
                        case 'failed':
                          return <ErrorIcon />;
                        case 'completed':
                        case 'success':
                          return <SuccessIcon />;
                        default:
                          return null;
                      }
                    })()}
                    {image.file}
                  </p>
                </div>
              );
            })
          ) : (
            <p className="no-files">No files found!</p>
          )}
        </div>
        {enableResendData ? (
          <button
            type="button"
            onClick={() => {
              examinationActionDisable();
              refreshThermal(examinationId);
            }}
          >
            <RefreshIcon className="icon-white" />
            Refresh
          </button>
        ) : null}
      </div>
    );
  }
}
export default ThermalImages;
