import PropTypes from 'prop-types';

export const examination = PropTypes.shape({
  // carvDelta: null
  clInjury: PropTypes.string,
  clReplacement: PropTypes.string,
  clSurgery: PropTypes.string,
  comment: PropTypes.string,
  createdAt: PropTypes.string,
  date: PropTypes.string,
  deletedAt: PropTypes.oneOf([PropTypes.null, PropTypes.string]),
  dominantSide: PropTypes.oneOf([PropTypes.null, PropTypes.string]),
  examEndTime: PropTypes.string,
  examStartTime: PropTypes.string,
  examType: PropTypes.string,
  files: PropTypes.string,
  forms: PropTypes.string,
  hasData: PropTypes.bool,
  height: PropTypes.number,
  id: PropTypes.number,
  patientId: PropTypes.number,
  status: PropTypes.string,
  updatedAt: PropTypes.string,
  version: PropTypes.string,
  // videoData: null
  weight: PropTypes.number
});

export const patient = PropTypes.shape({
  address: PropTypes.string,
  createdAt: PropTypes.string,
  dateOfBirth: PropTypes.string,
  deletedAt: PropTypes.oneOf([PropTypes.null, PropTypes.string]),
  dominantSide: PropTypes.string,
  email: PropTypes.string,
  examinations: PropTypes.arrayOf(PropTypes.shape({ examination })),
  firstName: PropTypes.string,
  gender: PropTypes.string,
  id: PropTypes.number,
  lastName: PropTypes.string,
  mothersName: PropTypes.string,
  phone: PropTypes.string,
  placeOfBirth: PropTypes.string,
  updatedAt: PropTypes.oneOf([PropTypes.null, PropTypes.string]),
  userId: PropTypes.number
});

export const field = PropTypes.shape({
  changed: PropTypes.bool,
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string
});

export const patientFields = PropTypes.shape({
  address: PropTypes.objectOf({ field }),
  dateOfBirth: PropTypes.objectOf({ field }),
  email: PropTypes.objectOf({ field }),
  firstName: PropTypes.objectOf({ field }),
  lastName: PropTypes.objectOf({ field }),
  phone: PropTypes.objectOf({ field })
});
