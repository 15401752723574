import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class ThermalPathView extends Component {
  static propTypes = {
    thermalPath: PropTypes.string,
    isChanging: PropTypes.bool,
    cancelChange: PropTypes.func,
    changedData: PropTypes.shape(),
    startChange: PropTypes.func,
    setData: PropTypes.func,
    save: PropTypes.func
  };

  render() {
    const {
      thermalPath,
      isChanging,
      cancelChange,
      changedData,
      startChange,
      setData,
      save
    } = this.props;

    return isChanging ? (
      <div className="setting">
        <h2>Thermal path</h2>
        <input
          type="text"
          placeholder="path/to/images"
          value={changedData.thermalPath}
          onChange={e => {
            setData({ name: 'thermalPath', value: e.target.value });
          }}
        />
        <button type="button" onClick={() => cancelChange()}>
          Cancel
        </button>
        <button type="button" onClick={() => save()}>
          Save
        </button>
      </div>
    ) : (
      <div className="setting">
        <h2>Thermal path</h2>
        <input
          disabled
          type="text"
          placeholder="path/to/images"
          value={thermalPath}
        />
        <button type="button" onClick={() => startChange()}>
          Change
        </button>
      </div>
    );
  }
}
