import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoginView from './login-view';

class Login extends Component {
  static propTypes = {
    signIn: PropTypes.func.isRequired,
    signInErrors: PropTypes.arrayOf(PropTypes.any).isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      isKeepMeLoggedIn: false
    };
    this.handleLogin = this.handleLogin.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  // Handles login request
  handleLogin(e) {
    const { isKeepMeLoggedIn } = this.state;
    const { signIn } = this.props;

    e.preventDefault();

    // TODO:
    // Data needs to be encrypted
    signIn({
      username: e.target.username.value,
      password: e.target.password.value,
      isKeepMeLoggedIn
    });
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.checked });
  }

  render() {
    const { signInErrors } = this.props;
    const { isKeepMeLoggedIn } = this.state;
    return (
      <LoginView
        handleLogin={this.handleLogin}
        errors={signInErrors}
        isKeepMeLoggedIn={isKeepMeLoggedIn}
        handleChange={this.handleChange}
      />
    );
  }
}

export default Login;
