import moment from 'moment';
import i18n from '../../i18n';

function validate(field) {
  if (field.value === '' && field.required && field.changed) {
    return {
      status: true,
      message: i18n.t('modalValidation.mustBeFilled')
    };
  }

  if (field.changed) {
    switch (field.name) {
      case 'weight':
        if (!isNum(field.value) && field.value !== '') {
          return {
            status: true,
            message: i18n.t('modalValidation.mustBeANumber')
          };
        }
        break;
      case 'height':
        if (!isNum(field.value) && field.value !== '') {
          return {
            status: true,
            message: i18n.t('modalValidation.mustBeANumber')
          };
        }
        break;
      case 'email':
        if (field.value !== '') {
          const mailRegex = /\b[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z]{2,}\b/;
          if (!mailRegex.test(field.value)) {
            return {
              status: true,
              message: i18n.t('modalValidation.notValidEmail')
            };
          }
        }
        break;
      case 'phone':
        if (field.value !== '') {
          let phone = field.value;
          phone = phone.replace(/ /g, '');
          phone = phone.replace(/-/g, '');

          const hunMobile = /^\+36[- ]{0,1}[0-9]{2}[- ]{0,1}[0-9]{3}[- ]{0,1}[0-9]{4}$/;
          const hunLandline = /^\+36[- ]{0,1}1[- ]{0,1}[0-9]{3}[- ]{0,1}[0-9]{3}$/;
          const europeMobile = /^\+(?!36)[0-9]{9,}$/;
          const europeMobileStart = /^\+(?!36)[0-9]{2,3}/;
          const USMobile = /^\+1-\([0-9]{3}\)-[0-9]{3}-[0-9]{4}$/;
          if (
            !phone.match(hunMobile) &&
            !phone.match(hunLandline) &&
            !phone.match(europeMobile) &&
            !field.value.match(USMobile)
          ) {
            if (field.value.startsWith('+36')) {
              return {
                status: true,
                message: `${i18n.t(
                  'modalValidation.hungarianPhone'
                )}: (+36 1 123 456), (+36 30 123 4567)`
              };
            }
            if (field.value.startsWith('+1')) {
              return {
                status: true,
                message: `${i18n.t(
                  'modalValidation.usPhone'
                )}: (+1-(123)-123-4567)`
              };
            }
            if (field.value.match(europeMobileStart)) {
              return {
                status: true,
                message: i18n.t('modalValidation.internationalPhone')
              };
            }
            return {
              status: true,
              message: i18n.t('modalValidation.startPhone')
            };
          }
        }
        break;

      case 'dateOfBirth':
        if (moment(field.value) - moment() > 0) {
          return {
            status: true,
            message: i18n.t('modalValidation.laterThanToday')
          };
        }
        break;

      case 'date':
        if (moment(field.value) - moment() > 0) {
          return {
            status: true,
            message: i18n.t('modalValidation.laterThanToday')
          };
        }
        break;

      case 'statement':
        if (field.checked === false) {
          return {
            status: true,
            message: i18n.t('modalValidation.mustBeChecked')
          };
        }
        break;

      default:
        return {
          status: false,
          message: ''
        };
    }
  }
  return {
    status: false,
    message: ''
  };
}

let isNum = data => {
  const regex = /^([0-9]+[.])?[0-9]+$/;
  if (regex.test(data)) {
    return true;
  }
  return false;
};

export default validate;
