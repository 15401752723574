import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Patient from './patient';
import { getPatient, deletePatient } from '../../reducers/request';

const mapStateToProps = (state, props) => {
  const { patients } = state;
  const { location } = props;
  const { match } = props;

  // extract the examination id from the url
  const regExp = /patients\/(\d*)/i;
  const matches = regExp.exec(location.pathname);
  const patientId = matches && matches.length > 1 && matches[1];

  const patient =
    patients &&
    patients.patients &&
    patients.patients.find(e => e.id && e.id.toString() === patientId);

  const isPatientFetching = patients && patients.isFetching;
  const errors = patients && patients.errors;

  return { match, patient, patientId, isPatientFetching, errors };
};

export default withRouter(
  connect(mapStateToProps, { getPatient, deletePatient })(Patient)
);
