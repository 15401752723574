/* globals */

import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import i18n from '../../i18n';

// The new overview
import Evaluation from '../../components/examination/tabs/_evaluation';
import Overview from '../../components/examination/tabs/_overview';
import NotFound from '../../components/not-found';

import '../../styles/examination.css';
import PatientInfo from '../../components/examination/patient-info';
import ExaminationInfo from '../../components/examination/examination-info';
import TabNavigation from '../../components/examination/tab-navigation';
import { DeleteIcon } from '../../components/icons';
import Confirm from '../../components/modals/confirm/confirm';
// import Report from '../../components/examination/tabs/_report';

import patientModel from '../../models';

export default class Examination extends Component {
  static propTypes = {
    deleteExamination: PropTypes.func,
    patient: PropTypes.shape({
      patient: patientModel.patient
    }).isRequired,
    examination: PropTypes.shape({
      examination: PropTypes.shape({
        id: PropTypes.number,
        patientId: PropTypes.number,
        weight: PropTypes.number,
        height: PropTypes.number,
        date: PropTypes.string,
        examType: PropTypes.string,
        dominantSide: PropTypes.string,
        comment: PropTypes.string,
        hasData: PropTypes.bool
      })
    }).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string
    }),
    isLocalBackendAvailable: PropTypes.bool,
    isConfirmOpen: PropTypes.bool,
    openConfirm: PropTypes.func,
    closeConfirm: PropTypes.func,
    history: PropTypes.shape()
  };

  renderData() {
    const {
      isLocalBackendAvailable,
      examination: { examination },
      patient: { patient }
    } = this.props;

    return (
      <>
        <Route
          exact
          path="/examinations/:id/overview"
          render={() => (
            <Overview
              examination={examination}
              isLocalBackendAvailable={isLocalBackendAvailable}
            />
          )}
        />
        <Route
          exact
          path="/examinations/:id/evaluation"
          render={() => (
            <Evaluation examination={examination} patient={patient} />
          )}
        />
        {/* <Route
          exact
          path="/examinations/:id/report"
          render={() => <Report examination={examination} />}
        /> */}
      </>
    );
  }

  render() {
    const {
      deleteExamination,
      isConfirmOpen,
      openConfirm,
      history,
      closeConfirm,
      patient: { patient },
      examination: { examination }
    } = this.props;

    if (!examination || !examination.id) return <NotFound />;

    return (
      <div className="examination">
        <Confirm
          closeConfirm={closeConfirm}
          confirmFunction={() => {
            deleteExamination(examination.id);
            history.push(`/patients/${examination.patientId}`);
          }}
          confirmMessage="You are about to delete an examination. Proceed?"
          isConfirmOpen={isConfirmOpen}
        />
        <div className="title-wrapper">
          <h1 className="route-title">{i18n.t(`navigation.examination`)}</h1>
          <button
            className="delete-button"
            type="button"
            onClick={() => {
              openConfirm();
            }}
          >
            <DeleteIcon className="icon-delete" />
            delete examination
          </button>
        </div>
        <div className="info">
          <PatientInfo patient={patient} examination={examination} />
          <ExaminationInfo patient={patient} examination={examination} />
        </div>
        <TabNavigation examination={examination} />
        <div className="details-wrapper">
          <div className="details">{this.renderData()}</div>
        </div>
      </div>
    );
  }
}
