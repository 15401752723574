import { connect } from 'react-redux';
import { examinationActionDisable } from '../../../../../../reducers/app';
import { download, refreshThermal } from '../../../../../../reducers/request';
import ThermalImages from './thermal-images';

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
  download,
  refreshThermal,
  examinationActionDisable
})(ThermalImages);
