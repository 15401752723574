import { takeLatest, fork, put, select } from 'redux-saga/effects';
// import { Types } from '../../reducers/local-examination';
import { Types as TypesExam } from '../../reducers/examinations';
import { getLocalExamination } from '../../reducers/request';
import {
  Actions as NotificationsActions,
  MessageTypes
} from '../../reducers/notifications';

const isLocalYieldable = state => state.app.isLocalBackendAvailable;

export function* getLocal(response) {
  if (!response || !response.payload || !response.payload.data) {
    return;
  }

  const { id, examination } = response.payload.data;

  if (response.type === 'UPDATE_REMOTE_EXAMINATION_SUCCESS') {
    yield put(
      NotificationsActions.showNotification({
        message: response && response.payload && response.payload.message,
        type: MessageTypes.Success,
        flash: true
      })
    );
    // yield put(getLocalExamination(id));
  } else {
    if (
      examination &&
      (examination.status === 'confirmed' || examination.status === 'completed')
    ) {
      return;
    }

    const isLocalBackendAvailable = yield select(isLocalYieldable);

    if (isLocalBackendAvailable) {
      yield put(getLocalExamination(id));
    }
  }
}

function* examinationSaga() {
  yield fork(takeLatest, TypesExam.GET_EXAMINATION_SUCCESS, getLocal);
  yield fork(takeLatest, TypesExam.UPDATE_REMOTE_EXAMINATION_SUCCESS, getLocal);
}

export default examinationSaga;
