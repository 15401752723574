import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types'; // eslint-disable-line

import i18n from 'i18n-js';

import '../../styles/examination-list.css';
import moment from 'moment';

export default class ExaminationsListView extends Component {
  static propTypes = {
    examinations: PropTypes.shape({
      isFetching: PropTypes.bool.isRequired,
      examinations: PropTypes.arrayOf(PropTypes.any).isRequired
    }),
    isDisplayPatientName: PropTypes.bool.isRequired,
    displayCount: PropTypes.number
  };

  static renderTestResults(forms) {
    let lysholm = null;
    let tegner = null;

    if (forms) {
      const formData = JSON.parse(forms);
      lysholm = formData.find(f => f.form_id === 'lysholm');
      tegner = formData.find(f => f.form_id === 'tegner');
    }

    return (
      <div className="column-test-results">
        {lysholm && lysholm.form_value ? (
          <p>
            <span>Lysholm: </span>
            {lysholm.form_value}
          </p>
        ) : null}
        {tegner && tegner.form_value ? (
          <p>
            <span>Tegner: </span>
            {tegner.form_value}
          </p>
        ) : null}
      </div>
    );
  }

  renderExaminations() {
    const { examinations, isDisplayPatientName, displayCount } = this.props;

    let sortedExaminations =
      (examinations &&
        examinations.examinations &&
        examinations.examinations.sort((a, b) =>
          moment(b.date).diff(moment(a.date))
        )) ||
      [];

    if (displayCount) {
      sortedExaminations = sortedExaminations.slice(0, displayCount);
    }

    return sortedExaminations.map(examination => (
      <div className="exam-wrapper" key={examination.id}>
        <Link
          key={examination.id}
          to={`/examinations/${examination.id}/overview`}
          style={{ textDecoration: 'none' }}
        >
          <div className="list-item box">
            <div className="column-exam-date">
              {examination.date &&
                new Date(examination.date).toLocaleDateString('hu-HU')}
            </div>
            {isDisplayPatientName ? (
              <div className="column-name">
                {`${examination.lastName} ${examination.firstName}`}
              </div>
            ) : null}

            {ExaminationsListView.renderTestResults(examination.forms)}

            <div
              className={`column-exam-comment ${
                isDisplayPatientName ? '' : 'column-exam-comment-long'
              }`}
            >
              {examination.comment}
            </div>
          </div>
        </Link>
      </div>
    ));
  }

  render() {
    const { isDisplayPatientName } = this.props;
    return (
      <div className="examination-list examinations-grid">
        <div className="list-item list-header">
          <div className="column-exam-date">
            {i18n.t('examinationsList.date')}
          </div>
          {isDisplayPatientName ? (
            <div className="column-name">
              {i18n.t('examinationsList.patientName')}
            </div>
          ) : null}
          <div className="column-test-results">
            {i18n.t('examinationsList.testResults')}
          </div>
          <div
            className={`column-exam-comment ${
              isDisplayPatientName ? '' : 'column-exam-comment-long'
            }`}
          >
            {i18n.t('examinationsList.comment')}
          </div>
        </div>
        {this.renderExaminations()}
      </div>
    );
  }
}
