import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ProgressView from './progress-view';

export default class Progress extends Component {
  static propTypes = {
    examination: PropTypes.shape(),
    isButtonClickable: PropTypes.bool,
    runningId: PropTypes.number,
    localExamination: PropTypes.shape(),
    startExamination: PropTypes.func,
    stopExamination: PropTypes.func,
    examinationActionDisable: PropTypes.func,
    updateExamination: PropTypes.func,
    cleanupExamination: PropTypes.func,
    getExamination: PropTypes.func,
    flushLocalFiles: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.timer = 0;
    this.state = {
      elapsedTime: null,
      isConfirmOpen: false,
      isStopping: false
    };

    this.openConfirm = this.openConfirm.bind(this);
    this.closeConfirm = this.closeConfirm.bind(this);
    this.startExamination = this.startExamination.bind(this);
    this.stopExamination = this.stopExamination.bind(this);
    this.confirmExamination = this.confirmExamination.bind(this);
    this.resetStopping = this.resetStopping.bind(this);
  }

  // Open confirm modal
  openConfirm() {
    this.setState({
      isConfirmOpen: true
    });
  }

  // Close confirm modal
  closeConfirm() {
    this.setState({
      isConfirmOpen: false
    });
  }

  /**
   * Start examination with these steps:
   * 1. flush local files from `files-reducer` state
   * 2. start examination with examinationId
   * 3. start checking progress
   */
  startExamination(examinationId) {
    const { startExamination, flushLocalFiles } = this.props;
    flushLocalFiles();
    startExamination(examinationId);
  }

  /**
   * Stop examination with these steps:
   * 1. Set local state `isStopping` to true, so the view will not display any button
   * 2. Set `app-reducer` to disable buttons if refresh happens
   * 3. Stop examination with examinationId
   * 4. Get instant progress to refresh `examination-reducer` progress field
   */
  stopExamination(examinationId) {
    const { stopExamination, examinationActionDisable } = this.props;
    this.setState({
      isStopping: true
    });
    examinationActionDisable();
    stopExamination(examinationId);
  }

  // Update examination to confirmed status
  confirmExamination(examinationId) {
    const { updateExamination } = this.props;
    updateExamination(examinationId, { status: 'confirmed' });
  }

  // Reset isStopping to false
  resetStopping() {
    this.setState({
      isStopping: false
    });
  }

  render() {
    const {
      localExamination,
      examination,
      isButtonClickable,
      runningId,
      updateExamination,
      cleanupExamination,
      getExamination
    } = this.props;

    const { elapsedTime, isConfirmOpen, isStopping } = this.state;

    return (
      <ProgressView
        isButtonClickable={isButtonClickable}
        runningId={runningId}
        elapsedTime={elapsedTime}
        localExamination={localExamination}
        examination={examination}
        startExamination={this.startExamination}
        stopExamination={this.stopExamination}
        isConfirmOpen={isConfirmOpen}
        openConfirm={this.openConfirm}
        closeConfirm={this.closeConfirm}
        confirmExamination={this.confirmExamination}
        updateExamination={updateExamination}
        cleanupExamination={cleanupExamination}
        getExamination={getExamination}
        isStopping={isStopping}
        resetStopping={this.resetStopping}
      />
    );
  }
}
