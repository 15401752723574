import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AngleTableView from './angle-table-view';

export default class AngleTable extends Component {
  static propTypes = {
    keyPoints: PropTypes.arrayOf(PropTypes.shape()),
    unformattedKeyPoints: PropTypes.shape(),
    isDragging: PropTypes.bool,
    calculateReportData: PropTypes.func,
    calculateSpecialReportData: PropTypes.func,
    skeleton: PropTypes.arrayOf(PropTypes.shape()),
    examination: PropTypes.shape()
  };

  static checkIfError(calculatedData) {
    let isError = false;
    calculatedData.forEach(data => {
      if (!data.left || !data.right) {
        isError = true;
      }
    });
    return isError;
  }

  constructor(props) {
    super(props);
    this.state = {
      selectedPoint: ''
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.isDragging === false) {
      return true;
    }
    return false;
  }

  handleInputChange(e) {
    const regex = /^-?\d+\.?\d*$/;

    if (regex.test(e.target.value) || e.target.value === '') {
      this.setState({
        selectedPoint: e.target.value
      });
    }
  }

  render() {
    const {
      keyPoints,
      unformattedKeyPoints,
      skeleton,
      calculateReportData,
      calculateSpecialReportData,
      examination
    } = this.props;

    const { selectedPoint } = this.state;

    const calculatedData = calculateReportData(
      keyPoints,
      skeleton,
      examination
    );

    const calculatedSpecialData = calculateSpecialReportData(
      unformattedKeyPoints,
      skeleton,
      examination,
      selectedPoint
    );

    const isError = AngleTable.checkIfError(calculatedData);

    return (
      <AngleTableView
        data={calculatedData}
        specialData={calculatedSpecialData}
        isError={isError}
        selectedPoint={selectedPoint}
        handleInputChange={this.handleInputChange}
      />
    );
  }
}
