import { connect } from 'react-redux';
import Login from './login';
import { signIn } from '../../reducers/auth';

const mapStateToProps = ({ auth: { user }, app: { signInErrors } }) => ({
  user,
  signInErrors
});

export default connect(mapStateToProps, { signIn })(Login);
