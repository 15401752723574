import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class ThermalLimitView extends Component {
  static propTypes = {
    thermalLimit: PropTypes.string,
    isChanging: PropTypes.bool,
    cancelChange: PropTypes.func,
    changedData: PropTypes.shape(),
    startChange: PropTypes.func,
    setData: PropTypes.func,
    save: PropTypes.func
  };

  render() {
    const {
      thermalLimit,
      isChanging,
      cancelChange,
      changedData,
      startChange,
      setData,
      save
    } = this.props;

    return isChanging ? (
      <div className="setting">
        <h2>Thermal images limit</h2>
        <input
          type="text"
          placeholder="Thermal limit eg.: 5"
          value={changedData.thermalLimit}
          onChange={e => {
            setData({ name: 'thermalLimit', value: e.target.value });
          }}
        />
        <button type="button" onClick={() => cancelChange()}>
          Cancel
        </button>
        <button type="button" onClick={() => save()}>
          Save
        </button>
      </div>
    ) : (
      <div className="setting">
        <h2>Thermal images limit</h2>
        <input
          disabled
          type="text"
          placeholder="No thermal limit set yet"
          value={thermalLimit}
        />
        <button type="button" onClick={() => startChange()}>
          Change
        </button>
      </div>
    );
  }
}
