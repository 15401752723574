import { toast } from 'react-toastify';

import env from '../../config';
import ApiHandlerService, { ResponseTypes } from '../../services/api-handler';

export default class PrintApi {
  static print(variables, token) {
    const payload = variables && variables.payload;
    const type = variables && variables.type;

    const url = `${env.API_BASE_URL}/print`;
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Cache-Control': 'no-store'
      },
      body: JSON.stringify({
        type,
        payload
      }),
      method: 'POST',
      responseType: 'blob'
    };

    let responseType = ResponseTypes.blob;
    if (type === 'report' || type === 'report_new') {
      responseType = ResponseTypes.json;
    }
    const response = ApiHandlerService.fetch(url, config, responseType);

    response.then(resp => {
      if (resp && resp.ok) {
        if (type === 'report' || type === 'report_new') {
          const { fileName } = (resp.data && resp.data.data) || {};
          toast.success(`${fileName} saved to server`); // @LANG
        }
      }
    });

    return response;
  }
}
