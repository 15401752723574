/* globals document */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  PreviousIcon,
  PauseIcon,
  PlayIcon,
  NextIcon,
  ArrowLeftIcon,
  ArrowRightIcon
} from '../../../../icons/icons';
import { jointTypes } from '../../../../../services/joints';

export default class Controls extends Component {
  static propTypes = {
    isReportOpen: PropTypes.bool,
    videoRef: PropTypes.shape(),
    isPlaying: PropTypes.bool,
    time: PropTypes.number,
    skeleton: PropTypes.arrayOf(PropTypes.shape()),
    skeletonForCalculation: PropTypes.arrayOf(PropTypes.shape()),
    duration: PropTypes.number,
    setDraggedTime: PropTypes.func,
    setDragging: PropTypes.func,
    jumpTo: PropTypes.func,
    jumpToFrame: PropTypes.func,
    calculatedFrameData: PropTypes.arrayOf(PropTypes.shape())
  };

  /**
   * Renders data headers and values for the `type` given
   * from `calculatedFrameData`
   */
  static renderCalculatedFrameData(calculatedFrameData, type) {
    const dataToDisplay = calculatedFrameData.filter(d => d.type === type);

    let decimals = 4;

    if (type === 'kinect') {
      decimals = 1;
    }

    if (dataToDisplay.length === 0) {
      return (
        <div>
          <p>{`No data for ${type} calculations`}</p>
        </div>
      );
    }

    return dataToDisplay.map(data => (
      <div key={data.name} className={data.type}>
        <h2>{data.name}</h2>
        <p>
          {`${data && data.value && data.value.toFixed(decimals)} ${
            data.unit || ''
          }`}
        </p>
      </div>
    ));
  }

  /**
   * Renders body join coordinates when video is paused.
   */
  static renderCoordinates(time, skeleton, dimension) {
    if (!time || !skeleton) {
      return (
        <div>
          <p>No data</p>
        </div>
      );
    }

    const actualBody =
      skeleton.find(s => s.time === time) &&
      skeleton.find(s => s.time === time).body;

    if (skeleton.find(s => s.time === time)) {
      const filteredBody = [];

      Object.keys(jointTypes).forEach(jointType => {
        filteredBody.push({
          name: jointType,
          coordinates: actualBody[jointTypes[jointType]]
        });
      });

      let i = 0;

      return (
        <table>
          <tbody>
            <tr>
              <th>{` `}</th>
              <th>x</th>
              <th>y</th>
              {dimension === '3d' ? <th>z</th> : null}
            </tr>
            {filteredBody.map(data => {
              i += 1;
              return (
                <tr key={`row-${i}`}>
                  <td>{data && data.name}</td>
                  <td>
                    {data &&
                      data.coordinates &&
                      data.coordinates.x &&
                      data.coordinates.x.toFixed(2)}
                  </td>
                  <td>
                    {data &&
                      data.coordinates &&
                      data.coordinates.y &&
                      data.coordinates.y.toFixed(2)}
                  </td>
                  {dimension === '3d' ? (
                    <td>
                      {data &&
                        data.coordinates &&
                        data.coordinates.z &&
                        data.coordinates.z.toFixed(2)}
                    </td>
                  ) : null}
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      showCoordinates: false,
      currentDimension: '3d'
    };
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.toogleCoordinates = this.toogleCoordinates.bind(this);
    this.switchDimensions = this.switchDimensions.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyPress);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyPress);
  }

  /**
   * Handles key pressing events:
   * - **Right Arrow**: Jump to next frame
   * - **Left Arrow**: Jump to previous frame
   * - **Space**: Starts or stops playback
   */
  handleKeyPress(event) {
    const { jumpToFrame, videoRef, isPlaying, isReportOpen } = this.props;
    if (!isReportOpen) {
      if (event.keyCode === 37) {
        jumpToFrame('previousTime');
      }
      if (event.keyCode === 39) {
        jumpToFrame('nextTime');
      }
      if (event.keyCode === 32) {
        event.preventDefault();
        if (isPlaying) {
          videoRef.current.pause();
        } else {
          videoRef.current.play();
        }
      }
    }
  }

  switchDimensions(e) {
    this.setState({ currentDimension: e.currentTarget.value });
  }

  toogleCoordinates() {
    const { showCoordinates } = this.state;
    this.setState({ showCoordinates: !showCoordinates });
  }

  render() {
    const {
      videoRef,
      isPlaying,
      time,
      skeleton,
      skeletonForCalculation,
      duration,
      setDraggedTime,
      calculatedFrameData,
      setDragging,
      jumpTo,
      jumpToFrame
    } = this.props;

    const { showCoordinates, currentDimension } = this.state;

    // Define time in moment object for easy formatting
    const timeMoment = moment()
      .hours(0)
      .minutes(0)
      .seconds(0)
      .millisecond(time * 1000);
    let durationMoment = null;
    if (duration) {
      durationMoment = moment().hours(0).minutes(0).seconds(duration);
    }

    return (
      <>
        <div className="video-info">
          <p>
            {`${timeMoment.format('mm:ss.SSS')} / ${
              duration ? durationMoment.format('mm:ss') : 'loading...'
            }`}
          </p>
        </div>
        {calculatedFrameData && !isPlaying ? (
          <div className="calculations">
            <div className="calculated-data calculated-data-left">
              {Controls.renderCalculatedFrameData(
                calculatedFrameData,
                'kinect'
              )}
            </div>
            <div className="calculated-data calculated-data-right">
              <input
                type="radio"
                value="2d"
                onChange={this.switchDimensions}
                checked={currentDimension === '2d'}
              />
              2D
              <input
                type="radio"
                value="3d"
                onChange={this.switchDimensions}
                checked={currentDimension === '3d'}
              />
              3D
              <button type="button" onClick={this.toogleCoordinates}>
                Toggle coordinates
              </button>
              <div className={showCoordinates ? 'd' : 'd-none'}>
                {currentDimension === '3d'
                  ? Controls.renderCoordinates(
                      time,
                      skeletonForCalculation,
                      currentDimension
                    )
                  : Controls.renderCoordinates(
                      time,
                      skeleton,
                      currentDimension
                    )}
              </div>
            </div>
          </div>
        ) : null}

        <div className="keypoint-controls">
          <button type="button" onClick={e => jumpTo(e, 'previous')}>
            <ArrowLeftIcon className="icon-white icon-no-margin" />
          </button>
          <button type="button" onClick={e => jumpTo(e, 'next')}>
            <ArrowRightIcon className="icon-white icon-no-margin" />
          </button>
        </div>

        <div className="controls">
          <button type="button" onClick={() => jumpToFrame('previousTime')}>
            <PreviousIcon className="icon-white icon-no-margin" />
          </button>
          {isPlaying ? (
            <button
              type="button"
              onClick={() => {
                videoRef.current.pause();
              }}
            >
              <PauseIcon className="icon-white icon-no-margin" />
            </button>
          ) : (
            <button
              type="button"
              onClick={() => {
                videoRef.current.play();
              }}
            >
              <PlayIcon className="icon-white icon-no-margin" />
            </button>
          )}
          <button type="button" onClick={() => jumpToFrame('nextTime')}>
            <NextIcon className="icon-white icon-no-margin" />
          </button>
        </div>
        <div className="slider">
          {duration ? (
            <input
              className="time-seeker"
              type="range"
              min={0}
              step={0.01}
              max={duration}
              value={time}
              onMouseDown={() => setDragging(true)}
              onInput={e => setDraggedTime(e)}
              onChange={e => setDraggedTime(e)}
              onMouseUpCapture={e => {
                setDraggedTime(e, true);
                setDragging(false);
              }}
            />
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </>
    );
  }
}
