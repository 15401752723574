import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';
import { SuccessIcon } from '../../icons';
import EditExamination from '../../modals/edit-examination';

export default class ExaminationInfoView extends Component {
  static propTypes = {
    examination: PropTypes.shape(),
    patient: PropTypes.shape(),
    closeModal: PropTypes.func,
    openModal: PropTypes.func,
    isModalOpen: PropTypes.bool,
    defaultModalValues: PropTypes.shape()
  };

  static renderFromData(form) {
    switch (form.form_id) {
      case 'lysholm':
      case 'tegner':
        return (
          <div
            key={form.form_id}
            className="form-container form-container-with-value"
          >
            <p className="form-p">
              <SuccessIcon />
              {i18n.t(`examinationActions.${form.form_id}`)}
            </p>
            <div className="form-value">
              <p>
                <span>Result: </span>
                {form.form_value}
              </p>
            </div>
          </div>
        );
      case 'sf36':
        return (
          <div
            key={form.form_id}
            className="form-container form-container-with-value"
          >
            <p className="form-p">
              <SuccessIcon />
              {i18n.t(`examinationActions.${form.form_id}`)}
            </p>
            <div className="form-value">
              {Object.keys(form.form_value).map(valueType => (
                <p key={valueType}>
                  <span>
                    {i18n.t(`formValues.sf36.${valueType}`)}
                    {': '}
                  </span>
                  {form.form_value[valueType].value}
                </p>
              ))}
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  static renderForms(forms, patientName, examinationId) {
    const urls = {
      lysholm: `https://docs.google.com/forms/d/e/1FAIpQLSd0f8D412jyZuxVqCsOwq0DplHw9GvbBomfxdgH5q7MB8kqVQ/viewform?usp=pp_url&entry.1813430799=${patientName}&entry.1747937191=${examinationId}`,
      tegner: `https://docs.google.com/forms/d/e/1FAIpQLSdGLApzRBncS6CYOTcEmcpe8BFm81d-j7jaSigqfeoo9-evMw/viewform?usp=pp_url&entry.1014377610=${patientName}&entry.1857878722=${examinationId}`,
      sf36: `https://docs.google.com/forms/d/e/1FAIpQLSeokZrR3VwKlOq1hh022OpIaseTkqgug8ZxdPiiPwzep7X6YQ/viewform?usp=pp_url&entry.195451209=${patientName}&entry.1344972314=${examinationId}`
    };

    const formTypes = ['lysholm', 'tegner', 'sf36'];
    const displayedForms = [];

    formTypes.forEach(formType => {
      const actualForm = forms && forms.find(x => x.form_id === formType);
      if (actualForm) {
        displayedForms.push(ExaminationInfoView.renderFromData(actualForm));
      } else {
        displayedForms.push(
          <div className="form-container" key={formType}>
            <a href={urls[formType]} rel="noopener noreferrer" target="_blank">
              <button className="btn-small btn-inline" type="button">
                <p>{i18n.t(`examinationActions.${formType}`)}</p>
              </button>
            </a>
          </div>
        );
      }
    });

    return displayedForms;
  }

  render() {
    const {
      examination,
      closeModal,
      openModal,
      isModalOpen,
      defaultModalValues,
      patient
    } = this.props;

    const patientName = `${patient.firstName}+${patient.lastName}`;

    return (
      <>
        {isModalOpen ? (
          <EditExamination
            isOpen={isModalOpen}
            closeModal={closeModal}
            defaults={defaultModalValues}
            examId={examination.id}
            patientId={patient.id}
            patient={patient}
          />
        ) : null}
        <div className="examination-info">
          <div className="title-box">
            <h1 className="title title-inline">
              {i18n.t('examinationInfo.examinationDetails')}
            </h1>
            <button
              className="btn-small btn-inline"
              onClick={openModal}
              type="button"
            >
              <p>{i18n.t('examinationActions.editDetails')}</p>
            </button>
            {ExaminationInfoView.renderForms(
              examination.forms,
              patientName,
              examination.id
            )}
          </div>

          <div className="box examination-info-list">
            <div className="examination-info-grid">
              <div className="elem">
                <h1>{i18n.t('examinationInfo.dateOfExamination')}</h1>
                <p>{examination.date}</p>
              </div>
              {examination.comment ? (
                <div className="elem">
                  <h1>{i18n.t(`examinationInfo.comment`)}</h1>
                  <p>{examination.comment}</p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </>
    );
  }
}
