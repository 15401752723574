import { connect } from 'react-redux';
import Evaluation from './evaluation';
import {
  getEvaluation,
  updateEvaluation,
  updateRemoteExamination
} from '../../../../reducers/request';
import { cleanEvaluation } from '../../../../reducers/evaluation';

const mapStateToProps = state => {
  const { evaluation } = state;
  return {
    evaluation
  };
};

export default connect(mapStateToProps, {
  getEvaluation,
  updateEvaluation,
  cleanEvaluation,
  updateRemoteExamination
})(Evaluation);
