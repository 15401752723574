// Base Request
export const REQUEST = 'REQUEST';
export const SEND_REQUEST_SUCCESS = 'SEND_REQUEST_SUCCESS';
export const REHYDRATE = 'persist/REHYDRATE';

// Patient Requests
export const ADD_PATIENT = 'ADD_PATIENT';
export const GET_PATIENTS = 'GET_PATIENTS';
export const GET_PATIENT = 'GET_PATIENT';
export const UPDATE_PATIENT = 'UPDATE_PATIENT';
export const DELETE_PATIENT = 'DELETE_PATIENT';

// Progress Requests
export const GET_PROGRESS = 'GET_PROGRESS';
export const GET_PROGRESS_INSTANT = 'GET_PROGRESS_INSTANT';
export const KILL_PROGRESSES = 'KILL_PROGRESSES';

// User Requests
export const GET_USER = 'GET_USER';

// Local Files Request
export const REFRESH_THERMAL = 'REFRESH_THERMAL';
export const REFRESH_CARV = 'REFRESH_CARV';

// Download Request
export const DOWNLOAD = 'DOWNLOAD';

// Examination Request
export const ADD_EXAMINATION = 'ADD_EXAMINATION';
export const UPDATE_EXAMINATION = 'UPDATE_EXAMINATION';
export const UPDATE_REMOTE_EXAMINATION = 'UPDATE_REMOTE_EXAMINATION';
export const GET_ALL_EXAMINATIONS = 'GET_ALL_EXAMINATIONS';
export const GET_EXAMINATIONS = 'GET_EXAMINATIONS';
export const GET_EXAMINATION = 'GET_EXAMINATION';
export const DELETE_EXAMINATION = 'DELETE_EXAMINATION';
export const GET_LAST_EXAMINATION = 'GET_LAST_EXAMINATION';
export const START_EXAMINATION = 'START_EXAMINATION';
export const STOP_EXAMINATION = 'STOP_EXAMINATION';
export const RESEND_EXAMINATION_DATA = 'RESEND_EXAMINATION_DATA';
export const CLEAN_UP_EXAMINATION = 'CLEAN_UP_EXAMINATION';
export const GET_LOCAL_EXAMINATION = 'GET_LOCAL_EXAMINATION';

// Settings Requests
export const GET_SETTINGS = 'GET_SETTINGS';
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const CHECK_LOCAL_BACKEND = 'CHECK_LOCAL_BACKEND';

// Evaluation Requests
export const GET_EVALUATION = 'GET_EVALUATION';
export const UPDATE_EVALUATION = 'UPDATE_EVALUATION';

export const Types = {
  REQUEST,
  SEND_REQUEST_SUCCESS,
  REHYDRATE
};

export const ActionTypes = {
  GET_USER,
  ADD_EXAMINATION,
  UPDATE_EXAMINATION,
  UPDATE_REMOTE_EXAMINATION,
  GET_ALL_EXAMINATIONS,
  GET_EXAMINATIONS,
  GET_EXAMINATION,
  DELETE_EXAMINATION,
  GET_LAST_EXAMINATION,
  START_EXAMINATION,
  STOP_EXAMINATION,
  RESEND_EXAMINATION_DATA,
  GET_EVALUATION,
  UPDATE_EVALUATION,
  ADD_PATIENT,
  GET_PATIENT,
  GET_PATIENTS,
  UPDATE_PATIENT,
  DELETE_PATIENT,
  GET_PROGRESS,
  GET_PROGRESS_INSTANT,
  GET_LOCAL_EXAMINATION,
  GET_SETTINGS,
  UPDATE_SETTINGS,
  KILL_PROGRESSES,
  DOWNLOAD,
  REFRESH_THERMAL,
  CLEAN_UP_EXAMINATION,
  CHECK_LOCAL_BACKEND,
  REFRESH_CARV
};

export const addPatient = payload => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.ADD_PATIENT,
    requestId: 'addPatient',
    variables: {
      payload
    }
  }
});

export const getPatient = id => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.GET_PATIENT,
    requestId: 'getPatient',
    variables: {
      id
    }
  }
});

export const deletePatient = id => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.DELETE_PATIENT,
    requestId: 'deletePatient',
    variables: {
      id
    }
  }
});

export const getPatients = (username = '*', limit = 20) => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.GET_PATIENTS,
    requestId: 'getPatients',
    variables: {
      username,
      limit
    }
  }
});

export const updatePatient = (id, payload) => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.UPDATE_PATIENT,
    requestId: 'updatePatient',
    variables: {
      id,
      payload
    }
  }
});

export const addExamination = payload => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.ADD_EXAMINATION,
    requestId: 'addExamination',
    variables: {
      payload
    }
  }
});

export const updateExamination = (id, payload) => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.UPDATE_EXAMINATION,
    requestId: 'updateExamination',
    variables: {
      id,
      payload
    }
  }
});

export const updateRemoteExamination = (id, payload) => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.UPDATE_REMOTE_EXAMINATION,
    requestId: 'updateRemoteExamination',
    variables: {
      id,
      payload
    }
  }
});

export const getExamination = id => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.GET_EXAMINATION,
    requestId: 'getExamination',
    variables: {
      id
    }
  }
});

export const deleteExamination = id => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.DELETE_EXAMINATION,
    requestId: 'deleteExamination',
    variables: {
      id
    }
  }
});

export const getExaminations = patientId => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.GET_EXAMINATIONS,
    requestId: 'getExaminations',
    variables: {
      id: patientId
    }
  }
});

export const getAllExaminations = (username = '*', limit = 20) => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.GET_ALL_EXAMINATIONS,
    requestId: 'getAllExaminations',
    variables: {
      limit,
      username
    }
  }
});

export const getLastExamination = patientId => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.GET_LAST_EXAMINATION,
    requestId: 'getLastExamination',
    variables: {
      id: patientId
    }
  }
});

export const startExamination = id => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.START_EXAMINATION,
    requestId: 'startExamination',
    variables: {
      id
    }
  }
});

export const stopExamination = id => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.STOP_EXAMINATION,
    requestId: 'stopExamination',
    variables: {
      id
    }
  }
});

export const getEvaluation = id => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.GET_EVALUATION,
    requestId: 'getEvaluation',
    variables: {
      id
    }
  }
});

export const updateEvaluation = (examinationId, payload) => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.UPDATE_EVALUATION,
    requestId: 'updateEvaluation',
    variables: {
      examinationId,
      payload
    }
  }
});

export const getUser = () => ({
  type: 'REQUEST',
  payload: {
    requestId: 'getUser',
    action: ActionTypes.GET_USER
  }
});

export const print = (type, payload) => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.PRINT,
    requestId: 'print',
    variables: {
      type,
      payload
    }
  }
});

export const getLocalExamination = examinationId => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.GET_LOCAL_EXAMINATION,
    requestId: 'getLocalExamination',
    variables: {
      examinationId
    }
  }
});

export const getSettings = () => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.GET_SETTINGS,
    requestId: 'getSettings'
  }
});

export const updateSettings = settingToUpdate => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.UPDATE_SETTINGS,
    requestId: 'updateSettings',
    variables: {
      settingToUpdate
    }
  }
});

export const killPendingProgresses = () => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.KILL_PROGRESSES,
    requestId: 'killPendingProgresses',
    variables: {}
  }
});

export const download = (id, type, file, fromServer) => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.DOWNLOAD,
    requestId: 'download',
    variables: {
      id,
      type,
      file,
      fromServer
    }
  }
});

export const refreshThermal = id => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.REFRESH_THERMAL,
    requestId: 'refreshThermal',
    variables: {
      id
    }
  }
});

export const refreshCarv = id => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.REFRESH_CARV,
    requestId: 'refreshCarv',
    variables: {
      id
    }
  }
});

export const cleanupExamination = id => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.CLEAN_UP_EXAMINATION,
    requestId: 'cleanupExamination',
    variables: {
      id
    }
  }
});

export const checkLocalBackend = () => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.CHECK_LOCAL_BACKEND,
    requestId: 'checkLocalBackend'
  }
});

export const Actions = {
  addExamination,
  updateExamination,
  updateRemoteExamination,
  getAllExaminations,
  getExamination,
  getExaminations,
  getLastExamination,
  startExamination,
  stopExamination,
  getEvaluation,
  updateEvaluation,
  addPatient,
  getPatient,
  getPatients,
  updatePatient,
  getUser,
  getLocalExamination,
  getSettings,
  updateSettings,
  killPendingProgresses,
  download,
  refreshThermal,
  cleanupExamination,
  print,
  refreshCarv,
  deletePatient,
  deleteExamination
};
