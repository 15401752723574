import env from '../../config';
import ApiHandlerService from '../../services/api-handler';

export default class ProgressApi {
  /**
   * Kill the pending process.
   *
   * @static
   * @returns
   * @memberof SettingsApi
   */
  static kill() {
    // Send start request to local backend
    const url = `${env.LOCAL_API_BASE_URL}/progresses/kill`;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-store'
      },
      method: 'POST'
    };

    return ApiHandlerService.fetch(url, config);
  }
}
