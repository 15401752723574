/* eslint-disable jsx-a11y/media-has-caption */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Player extends Component {
  static propTypes = {
    videoSource: PropTypes.string,
    videoRef: PropTypes.shape(),
    setElapsedTime: PropTypes.func,
    setPlaying: PropTypes.func,
    setPaused: PropTypes.func,
    setDuration: PropTypes.func
  };

  componentDidMount() {
    const { videoRef } = this.props;
    if (videoRef && videoRef.current) {
      videoRef.current.load();
    }
  }

  shouldComponentUpdate(nextProps) {
    const { videoSource } = this.props;
    if (nextProps.videoSource !== videoSource) return true;
    return false;
  }

  render() {
    const {
      videoSource,
      videoRef,
      setElapsedTime,
      setPlaying,
      setPaused,
      setDuration
    } = this.props;

    return (
      <div className="player">
        <video
          ref={videoRef}
          onPlayingCapture={() => {
            setPlaying();
            this.timer = setInterval(() => {
              setElapsedTime();
              if (videoRef && videoRef.current && videoRef.current.paused) {
                clearInterval(this.timer);
              }
              if (!videoRef || (videoRef && !videoRef.current)) {
                clearInterval(this.timer);
              }
            }, 100);
          }}
          onPauseCapture={() => {
            setPaused();
            clearInterval(this.timer);
            this.timer = null;
          }}
          onPause={() => {
            setPaused();
            clearInterval(this.timer);
            this.timer = null;
          }}
          onLoadedMetadataCapture={() => setDuration()}
          src={`${videoSource}?t=${Date.now()}`}
          type="video/quicktime"
          playsInline
        >
          {/* <source src={videoSource} type="video/mp4" /> */}
        </video>
      </div>
    );
  }
}
