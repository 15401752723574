import { connect } from 'react-redux';
import { examinationActionDisable } from '../../../../../../reducers/app';
import { download, refreshCarv } from '../../../../../../reducers/request';
import CarvData from './carv-data';

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
  download,
  examinationActionDisable,
  refreshCarv
})(CarvData);
