import React, { Component } from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line
import '../../../../styles/overview.css';
import OverviewView from './overview-view';
import Progress from '../../progress';

class Overview extends Component {
  static propTypes = {
    thermalImages: PropTypes.arrayOf(PropTypes.shape()),
    kinectVideo: PropTypes.arrayOf(PropTypes.shape()),
    carvData: PropTypes.arrayOf(PropTypes.shape()),
    getLocalExamination: PropTypes.func,
    getExamination: PropTypes.func,
    examinationId: PropTypes.string,
    shouldLoopLocal: PropTypes.bool,
    status: PropTypes.string,
    examination: PropTypes.shape(),
    isLocalBackendAvailable: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.getStatusLooper = null;
  }

  componentWillUnmount() {
    // stop timer
    clearInterval(this.getStatusLooper);
  }

  render() {
    const {
      thermalImages,
      kinectVideo,
      carvData,
      examinationId,
      shouldLoopLocal,
      status,
      getLocalExamination,
      isLocalBackendAvailable,
      examination,
      getExamination
    } = this.props;

    // update the status if necessary
    if (shouldLoopLocal && !this.getStatusLooper) {
      // allows the progress check frequency to be modified
      this.getStatusLooper = setInterval(() => {
        getLocalExamination(examinationId);
      }, 3000);
    } else if (!shouldLoopLocal && this.getStatusLooper) {
      clearInterval(this.getStatusLooper);
      this.getStatusLooper = null;
      getExamination(examinationId);
    }

    return (
      <>
        {isLocalBackendAvailable ? (
          <Progress examination={examination} />
        ) : null}
        <OverviewView
          examinationId={examinationId}
          thermalImages={thermalImages}
          kinectVideo={kinectVideo}
          carvData={carvData}
          shouldLoopLocal={shouldLoopLocal}
          status={status}
        />
      </>
    );
  }
}

export default Overview;
