import React, { Component } from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line
import ExaminationListView from './examination-list-view';

class ExaminationList extends Component {
  static propTypes = {
    examinations: PropTypes.shape({
      isFetching: PropTypes.bool.isRequired,
      examinations: PropTypes.arrayOf(PropTypes.any).isRequired
    }),
    displayCount: PropTypes.number,
    isDisplayPatientName: PropTypes.bool
  };

  render() {
    const { examinations, displayCount } = this.props;
    let { isDisplayPatientName } = this.props;
    if (isDisplayPatientName !== false) isDisplayPatientName = true;
    return (
      <ExaminationListView
        examinations={examinations}
        isDisplayPatientName={isDisplayPatientName}
        displayCount={displayCount}
      />
    );
  }
}

export default ExaminationList;
