import { jointTypes } from '../joints';
import env from '../../config';

/**
 * Class that deals with the on-the fly calculations.
 * Every calculation is defined in the
 * `DynaKnee_calculations_spec_20190716.xls` file.
 * *(Can be downloaded from confluence)*
 *
 * Build-up:
 *
 * 1. Basic helper functions
 * 2. Moving Average calculations
 * 3. Kinect data calculations
 * 4. Carv data calculations
 * 5. Report calculations
 */
export default class Calculations {
  // Sum an array of numbers
  static sum(arr) {
    if (arr.length === 0) return null;
    const s = arr.reduce((a, b) => {
      return a + b;
    });
    return s;
  }

  // Get average of an array
  static avg(arr) {
    const sum = Calculations.sum(arr);
    if (arr.length === 0) {
      return null;
    }
    return sum / arr.length;
  }

  // Get min value of an array
  static min(arr) {
    const sortedArr = arr.sort((a, b) => a - b);
    return sortedArr[0];
  }

  // Get max value of an array
  static max(arr) {
    const sortedArr = arr.sort((a, b) => b - a);
    return sortedArr[0];
  }

  // Calculation for moving average chart (currently not in use)

  // static getSpineBaseV(skeleton) {
  //   const spineBaseV = [];
  //   skeleton.forEach((data, index) => {
  //     if (!data.body || data.body.length === 0) {
  //       return;
  //     }
  //     const actualSpineBase = data.body[jointTypes.SpineBase].y;
  //     const actualSpineBaseV = { time: data.time };
  //     if (index > 0) {
  //       const previousSpineBase =
  //         skeleton[index - 1].body[jointTypes.SpineBase].y;
  //       actualSpineBaseV.value = actualSpineBase - previousSpineBase;
  //     } else {
  //       actualSpineBaseV.value = 0;
  //     }
  //     spineBaseV.push(actualSpineBaseV);
  //   });
  //   return spineBaseV;
  // }

  // static getSpineBaseAcc(spineBaseV) {
  //   const spineBaseAcc = [];
  //   spineBaseV.forEach((data, index) => {
  //     const actualSpineBaseAcc = { time: data.time };
  //     if (index > 0) {
  //       actualSpineBaseAcc.value = data.value - spineBaseV[index - 1].value;
  //     } else {
  //       actualSpineBaseAcc.value = data.value;
  //     }
  //     spineBaseAcc.push(actualSpineBaseAcc);
  //   });
  //   return spineBaseAcc;
  // }

  // static movingAverage(skeleton, movingAverage = 25) {
  //   const spineBaseV = Calculations.getSpineBaseV(skeleton);
  //   const spineBaseAcc = Calculations.getSpineBaseAcc(spineBaseV);

  //   const movingAverageData = [];

  //   const beforeWindow = Math.floor(movingAverage / 2);
  //   const afterWindow = Math.floor(movingAverage / 2);

  //   spineBaseAcc.forEach((data, index) => {
  //     const averageData = [];

  //     let usedBeforeWindow = beforeWindow;

  //     if (index < beforeWindow) {
  //       usedBeforeWindow = index;
  //     }

  //     for (let i = index - usedBeforeWindow; i < usedBeforeWindow; i += 1) {
  //       averageData.push(spineBaseAcc[i].value);
  //     }

  //     averageData.push(data.value);

  //     let usedAfterWindow = afterWindow;

  //     if (index + afterWindow >= spineBaseAcc.length) {
  //       usedAfterWindow = spineBaseAcc.length - index;
  //     }

  //     for (let i = index + 1; i < index + usedAfterWindow; i += 1) {
  //       averageData.push(spineBaseAcc[i].value);
  //     }

  //     movingAverageData.push({
  //       x: data.time,
  //       y: Calculations.avg(averageData)
  //     });
  //   });

  //   return movingAverageData;
  // }

  /**
   * Calculation for chart displaying knee position relative to the hip.
   */
  static kneeHipX(data, skeleton, time, keyPoints) {
    let i;

    if (
      !keyPoints.left_leg_stand_points ||
      !keyPoints.right_leg_stand_points ||
      !keyPoints.left_leg_points ||
      !keyPoints.right_leg_points
    ) {
      return null;
    }

    // Sort standing points based on the time of the video (earlier is first)
    keyPoints.left_leg_stand_points.sort((a, b) => a.value - b.value);
    keyPoints.right_leg_stand_points.sort((a, b) => a.value - b.value);
    keyPoints.left_leg_points.sort((a, b) => a.value - b.value);
    keyPoints.right_leg_points.sort((a, b) => a.value - b.value);

    const standingPoints = [
      keyPoints.left_leg_stand_points[0],
      keyPoints.right_leg_stand_points[0]
    ];
    standingPoints.sort((a, b) => a.value - b.value);

    if (time + 2 < standingPoints[1].value) {
      if (standingPoints[0] === keyPoints.left_leg_stand_points[0]) {
        // Left leg
        i = 0;

        keyPoints.left_leg_stand_points.forEach(() => {
          if (
            keyPoints.left_leg_stand_points[i + 1] &&
            keyPoints.left_leg_stand_points[i + 1].value < time
          ) {
            i += 1;
          }
        });

        const standPointBody =
          skeleton &&
          skeleton.find(
            p => p.time === keyPoints.left_leg_stand_points[i].value
          ) &&
          skeleton.find(
            p => p.time === keyPoints.left_leg_stand_points[i].value
          ).body;

        if (!standPointBody) {
          return null;
        }

        return (
          standPointBody[jointTypes.HipLeft].x -
          standPointBody[jointTypes.KneeLeft].x -
          (data[jointTypes.HipLeft].x - data[jointTypes.KneeLeft].x)
        );
      }

      // Right leg
      i = 0;

      keyPoints.right_leg_stand_points.forEach(() => {
        if (
          keyPoints.right_leg_stand_points[i + 1] &&
          keyPoints.right_leg_stand_points[i + 1].value < time
        ) {
          i += 1;
        }
      });

      const standPointBody =
        skeleton &&
        skeleton.find(
          p => p.time === keyPoints.right_leg_stand_points[i].value
        ) &&
        skeleton.find(p => p.time === keyPoints.right_leg_stand_points[i].value)
          .body;

      if (!standPointBody) {
        return null;
      }

      return (
        -1 *
        (standPointBody[jointTypes.HipRight].x -
          standPointBody[jointTypes.KneeRight].x -
          (data[jointTypes.HipRight].x - data[jointTypes.KneeRight].x))
      );
    }

    if (standingPoints[1] === keyPoints.left_leg_stand_points[0]) {
      // Left leg
      i = 0;

      keyPoints.left_leg_stand_points.forEach(() => {
        if (
          keyPoints.left_leg_stand_points[i + 1] &&
          keyPoints.left_leg_stand_points[i + 1].value < time
        ) {
          i += 1;
        }
      });

      const standPointBody =
        skeleton &&
        skeleton.find(
          p => p.time === keyPoints.left_leg_stand_points[i].value
        ) &&
        skeleton.find(p => p.time === keyPoints.left_leg_stand_points[i].value)
          .body;

      if (!standPointBody) {
        return null;
      }

      return (
        standPointBody[jointTypes.HipLeft].x -
        standPointBody[jointTypes.KneeLeft].x -
        (data[jointTypes.HipLeft].x - data[jointTypes.KneeLeft].x)
      );
    }

    // Right leg
    i = 0;

    keyPoints.right_leg_stand_points.forEach(() => {
      if (
        keyPoints.right_leg_stand_points[i + 1] &&
        keyPoints.right_leg_stand_points[i + 1].value < time
      ) {
        i += 1;
      }
    });

    const standPointBody =
      skeleton &&
      skeleton.find(
        p => p.time === keyPoints.right_leg_stand_points[i].value
      ) &&
      skeleton.find(p => p.time === keyPoints.right_leg_stand_points[i].value)
        .body;

    if (!standPointBody) {
      return null;
    }

    return (
      -1 *
      (standPointBody[jointTypes.HipRight].x -
        standPointBody[jointTypes.KneeRight].x -
        (data[jointTypes.HipRight].x - data[jointTypes.KneeRight].x))
    );
  }

  /**
   * Calculation for the left knee shift relative to the hip.
   * Displayed on the video as Left knee shift: + medial / - lateral
   */
  static kneeHipLeftX(data, skeleton, keyPoints) {
    const standingPoints =
      keyPoints && keyPoints.find(p => p.type === 'left-leg-stand-points');

    if (!standingPoints) {
      return null;
    }

    const standPointBody =
      skeleton &&
      skeleton.find(p => p.time === standingPoints.value) &&
      skeleton.find(p => p.time === standingPoints.value).body;

    if (!standPointBody) {
      return null;
    }

    return (
      standPointBody[jointTypes.HipLeft].x -
      standPointBody[jointTypes.KneeLeft].x -
      (data[jointTypes.HipLeft].x - data[jointTypes.KneeLeft].x)
    );
  }

  /**
   * Calculation for the left knee shift relative to the hip.
   * Displayed on the video as Right knee shift: + medial / - lateral
   */
  static kneeHipRightX(data, skeleton, keyPoints) {
    const standingPoints =
      keyPoints && keyPoints.find(p => p.type === 'right-leg-stand-points');

    if (!standingPoints) {
      return null;
    }

    const standPointBody =
      skeleton &&
      skeleton.find(p => p.time === standingPoints.value) &&
      skeleton.find(p => p.time === standingPoints.value).body;

    if (!standPointBody) {
      return null;
    }

    return (
      -1 *
      (standPointBody[jointTypes.HipRight].x -
        standPointBody[jointTypes.KneeRight].x -
        (data[jointTypes.HipRight].x - data[jointTypes.KneeRight].x))
    );
  }

  /**
   * Returns the length of the left side lower limb.
   */
  static spineBaseAnkleLeftY(data) {
    const ankleLeft = data[jointTypes.AnkleLeft];
    const spineBase = data[jointTypes.SpineBase];
    return ankleLeft.y - spineBase.y;
  }

  /**
   * Returns the length of the right side lower limb.
   */
  static spineBaseAnkleRightY(data) {
    const ankleRight = data[jointTypes.AnkleRight];
    const spineBase = data[jointTypes.SpineBase];
    return ankleRight.y - spineBase.y;
  }

  /**
   * **A5**
   * calculation */
  static qAngleLeft(data) {
    const leftAnkle = data[jointTypes.AnkleLeft];
    const leftKnee = data[jointTypes.KneeLeft];
    const leftWrist = data[jointTypes.WristLeft];

    const kneeWristX = leftKnee.x - leftWrist.x;
    const kneeWristY = leftWrist.y - leftKnee.y;
    const tnBeta = kneeWristX / kneeWristY;
    const leftBeta = Math.atan(tnBeta) * (180 / Math.PI);

    const kneeAnkleX = leftKnee.x - leftAnkle.x;
    const kneeAnkleY = leftKnee.y - leftAnkle.y;
    const tnAlpha = kneeAnkleX / kneeAnkleY;
    const leftAlpha = Math.atan(tnAlpha) * (180 / Math.PI);

    return leftAlpha + leftBeta;
  }

  /**
   * **A6**
   * calculation */
  static qAngleRight(data) {
    const rightAnkle = data[jointTypes.AnkleRight];
    const rightKnee = data[jointTypes.KneeRight];
    const rightWrist = data[jointTypes.WristRight];

    const kneeWristX = -1 * (rightKnee.x - rightWrist.x);
    const kneeWristY = rightWrist.y - rightKnee.y;
    const tnBeta = kneeWristX / kneeWristY;
    const rightBeta = Math.atan(tnBeta) * (180 / Math.PI);

    const kneeAnkleX = -1 * (rightKnee.x - rightAnkle.x);
    const kneeAnkleY = rightKnee.y - rightAnkle.y;
    const tnAlpha = kneeAnkleX / kneeAnkleY;
    const rightAlpha = Math.atan(tnAlpha) * (180 / Math.PI);

    return rightAlpha + rightBeta;
  }

  static wristSpineBaseLeft(data) {
    const wristLeft = data[jointTypes.WristLeft];
    const spineBase = data[jointTypes.SpineBase];
    return Math.abs(wristLeft.y - spineBase.y);
  }

  static wristSpineBaseRight(data) {
    const wristRight = data[jointTypes.WristRight];
    const spineBase = data[jointTypes.SpineBase];
    return Math.abs(wristRight.y - spineBase.y);
  }

  static spineBaseHeight(data) {
    const spineBase = data[jointTypes.SpineBase];
    return spineBase.y * -1;
  }

  static spineMidX(data) {
    const spineMid = data[jointTypes.SpineMid];
    return spineMid.x * -1;
  }

  /**
   * Calculates the largest valgus knee difference between two keypoints
   */
  static calculateValgusKneeDiff(type, data, skeleton) {
    let standPointsArray;
    let standPoints;
    let standPointKnee;
    let calculation;
    let kneeValues;
    let i = 0;
    const keyPointMargin = env.SQUAT_KEYPOINT_MARGIN / 1000 || 2;
    const kneeMinValues = [];

    if (type === 'left-leg-points') {
      standPointsArray = data.filter(d => d.type === 'left-leg-stand-points');
    } else if (type === 'right-leg-points') {
      standPointsArray = data.filter(d => d.type === 'right-leg-stand-points');
    }

    data.forEach(point => {
      kneeValues = [];

      if (
        standPointsArray[i + 1] &&
        point.value > standPointsArray[i + 1].value
      ) {
        i += 1;
      }

      if (type === 'left-leg-points' && standPointsArray.length > 0) {
        standPoints = Calculations.spineBaseAnkleLeftY(
          standPointsArray[i].body
        );
        standPointKnee = standPointsArray[i].body[jointTypes.KneeLeft].x;
      } else if (type === 'right-leg-points' && standPointsArray.length > 0) {
        standPoints = Calculations.spineBaseAnkleRightY(
          standPointsArray[i].body
        );
        standPointKnee = standPointsArray[i].body[jointTypes.KneeRight].x;
      }

      if (point.type === type) {
        skeleton.forEach(sPoint => {
          if (
            sPoint.time >= point.value - keyPointMargin &&
            sPoint.time <= point.value + keyPointMargin
          ) {
            switch (type) {
              case 'left-leg-points':
                if (standPoints !== 0) {
                  calculation =
                    (standPointKnee / standPoints) * -100 -
                    (sPoint.body[jointTypes.KneeLeft].x / standPoints) * -100;
                  kneeValues.push(calculation);
                }
                break;
              case 'right-leg-points':
                if (standPoints !== 0) {
                  calculation =
                    (standPointKnee / standPoints) * 100 -
                    (sPoint.body[jointTypes.KneeRight].x / standPoints) * 100;
                  kneeValues.push(calculation);
                }
                break;
              default:
                break;
            }
          }
        });

        if (kneeValues.length > 0) {
          kneeMinValues.push(Calculations.min(kneeValues));
        }
      }
    });

    return kneeMinValues;
  }

  /**
   * Calculates the largest varus knee difference between two keypoints
   */
  static calculateVarusKneeDiff(type, data, skeleton) {
    let standPointsArray;
    let standPoints;
    let standPointKnee;
    let calculation;
    let kneeValues;
    let i = 0;
    const keyPointMargin = env.SQUAT_KEYPOINT_MARGIN / 1000 || 2;
    const kneeMaxValues = [];

    if (type === 'left-leg-points') {
      standPointsArray = data.filter(d => d.type === 'left-leg-stand-points');
    } else if (type === 'right-leg-points') {
      standPointsArray = data.filter(d => d.type === 'right-leg-stand-points');
    }

    data.forEach(point => {
      kneeValues = [];

      if (
        standPointsArray[i + 1] &&
        point.value > standPointsArray[i + 1].value
      ) {
        i += 1;
      }

      if (type === 'left-leg-points' && standPointsArray.length > 0) {
        standPoints = Calculations.spineBaseAnkleLeftY(
          standPointsArray[i].body
        );
        standPointKnee = standPointsArray[i].body[jointTypes.KneeLeft].x;
      } else if (type === 'right-leg-points' && standPointsArray.length > 0) {
        standPoints = Calculations.spineBaseAnkleRightY(
          standPointsArray[i].body
        );
        standPointKnee = standPointsArray[i].body[jointTypes.KneeRight].x;
      }

      if (point.type === type) {
        skeleton.forEach(sPoint => {
          if (
            sPoint.time >= point.value - keyPointMargin &&
            sPoint.time <= point.value + keyPointMargin
          ) {
            switch (type) {
              case 'left-leg-points':
                if (standPoints !== 0) {
                  calculation =
                    (standPointKnee / standPoints) * -100 -
                    (sPoint.body[jointTypes.KneeLeft].x / standPoints) * -100;
                  kneeValues.push(calculation);
                }
                break;
              case 'right-leg-points':
                if (standPoints !== 0) {
                  calculation =
                    (standPointKnee / standPoints) * 100 -
                    (sPoint.body[jointTypes.KneeRight].x / standPoints) * 100;
                  kneeValues.push(calculation);
                }
                break;
              default:
                break;
            }
          }
        });

        if (kneeValues.length > 0) {
          if (Calculations.max(kneeValues) > 0) {
            kneeMaxValues.push(Calculations.max(kneeValues));
          } else {
            kneeMaxValues.push(0);
          }
        }
      }
    });

    return kneeMaxValues;
  }

  /**
   * Calculates the lowest squat depth between two keypoints
   */
  static calculateMinSquatDepths(type, data, skeleton) {
    let standPointsArray;
    let standPoints;
    let standPointSpineBase;
    let calculation;
    let squatValues;
    let i = 0;
    const keyPointMargin = env.SQUAT_KEYPOINT_MARGIN / 1000 || 2;
    const squatMinValues = [];

    if (type === 'left-leg-points') {
      standPointsArray = data.filter(d => d.type === 'left-leg-stand-points');
    } else if (type === 'right-leg-points') {
      standPointsArray = data.filter(d => d.type === 'right-leg-stand-points');
    }

    data.forEach(point => {
      squatValues = [];

      if (
        standPointsArray[i + 1] &&
        point.value > standPointsArray[i + 1].value
      ) {
        i += 1;
      }

      if (type === 'left-leg-points' && standPointsArray.length > 0) {
        standPoints = Calculations.spineBaseAnkleLeftY(
          standPointsArray[i].body
        );
        standPointSpineBase = standPointsArray[i].body[jointTypes.SpineBase].y;
      } else if (type === 'right-leg-points' && standPointsArray.length > 0) {
        standPoints = Calculations.spineBaseAnkleRightY(
          standPointsArray[i].body
        );
        standPointSpineBase = standPointsArray[i].body[jointTypes.SpineBase].y;
      }

      if (point.type === type) {
        skeleton.forEach(sPoint => {
          if (
            sPoint.time >= point.value - keyPointMargin &&
            sPoint.time <= point.value + keyPointMargin
          ) {
            switch (type) {
              case 'left-leg-points':
                if (standPoints !== 0) {
                  calculation =
                    (standPointSpineBase / standPoints) * 100 -
                    (sPoint.body[jointTypes.SpineBase].y / standPoints) * 100;
                  squatValues.push(calculation);
                }
                break;
              case 'right-leg-points':
                if (standPoints !== 0) {
                  calculation =
                    (standPointSpineBase / standPoints) * 100 -
                    (sPoint.body[jointTypes.SpineBase].y / standPoints) * 100;
                  squatValues.push(calculation);
                }
                break;
              default:
                break;
            }
          }
        });

        if (squatValues.length > 0) {
          if (Calculations.min(squatValues) < 0) {
            squatMinValues.push(Calculations.min(squatValues));
          } else {
            squatMinValues.push(0);
          }
        }
      }
    });

    return squatMinValues;
  }

  /**
   * Calculation for squat depth displayed on the Squat depth/knee valgus chart.
   */
  static squatDepth(data, skeleton, time, keyPoints) {
    let standPointBody;
    let standSpineBaseAnkle;
    let calculation;

    if (!keyPoints.left_leg_stand_points || !keyPoints.right_leg_stand_points) {
      return null;
    }

    const standingPoints = [
      keyPoints.left_leg_stand_points[0],
      keyPoints.right_leg_stand_points[0]
    ];
    standingPoints.sort((a, b) => a.value - b.value);

    if (time + 2 < standingPoints[1].value) {
      standPointBody = skeleton.find(p => p.time === standingPoints[0].value);

      if (!standPointBody) {
        return null;
      }

      if (standingPoints[0] === keyPoints.left_leg_stand_points[0]) {
        standSpineBaseAnkle = Calculations.spineBaseAnkleLeftY(
          standPointBody.body
        );
      } else {
        standSpineBaseAnkle = Calculations.spineBaseAnkleRightY(
          standPointBody.body
        );
      }

      if (standSpineBaseAnkle === 0) {
        return null;
      }

      calculation =
        (standPointBody.body[jointTypes.SpineBase].y / standSpineBaseAnkle) *
          100 -
        (data[jointTypes.SpineBase].y / standSpineBaseAnkle) * 100;

      return calculation;
    }

    standPointBody = skeleton.find(p => p.time === standingPoints[1].value);

    if (standingPoints[1] === keyPoints.left_leg_stand_points[0]) {
      standSpineBaseAnkle = Calculations.spineBaseAnkleLeftY(
        standPointBody.body
      );
    } else {
      standSpineBaseAnkle = Calculations.spineBaseAnkleRightY(
        standPointBody.body
      );
    }

    if (standSpineBaseAnkle === 0) {
      return null;
    }

    calculation =
      (standPointBody.body[jointTypes.SpineBase].y / standSpineBaseAnkle) *
        100 -
      (data[jointTypes.SpineBase].y / standSpineBaseAnkle) * 100;

    return calculation;
  }

  /**
   * Calculation for knee lateral movement displayed on the Squat depth/knee valgus chart.
   */
  static kneeValgus(data, skeleton, time, keyPoints) {
    let standPointBody;
    let standSpineBaseAnkle;
    let calculation;
    let i;

    if (!keyPoints.left_leg_stand_points || !keyPoints.right_leg_stand_points) {
      return null;
    }

    const standingPoints = [
      keyPoints.left_leg_stand_points[0],
      keyPoints.right_leg_stand_points[0]
    ];
    standingPoints.sort((a, b) => a.value - b.value);

    if (time + 2 < standingPoints[1].value) {
      if (standingPoints[0] === keyPoints.left_leg_stand_points[0]) {
        // Left leg
        i = 0;

        keyPoints.left_leg_stand_points.forEach(() => {
          if (
            keyPoints.left_leg_stand_points[i + 1] &&
            keyPoints.left_leg_stand_points[i + 1].value < time
          ) {
            i += 1;
          }
        });

        standPointBody =
          skeleton &&
          skeleton.find(
            p => p.time === keyPoints.left_leg_stand_points[i].value
          ) &&
          skeleton.find(
            p => p.time === keyPoints.left_leg_stand_points[i].value
          ).body;

        if (!standPointBody) {
          return null;
        }

        standSpineBaseAnkle = Calculations.spineBaseAnkleLeftY(standPointBody);

        if (!standSpineBaseAnkle) {
          return null;
        }
      } else {
        // Right leg
        i = 0;

        keyPoints.right_leg_stand_points.forEach(() => {
          if (
            keyPoints.right_leg_stand_points[i + 1] &&
            keyPoints.right_leg_stand_points[i + 1].value < time
          ) {
            i += 1;
          }
        });

        standPointBody =
          skeleton &&
          skeleton.find(
            p => p.time === keyPoints.right_leg_stand_points[i].value
          ) &&
          skeleton.find(
            p => p.time === keyPoints.right_leg_stand_points[i].value
          ).body;

        if (!standPointBody) {
          return null;
        }

        standSpineBaseAnkle = Calculations.spineBaseAnkleRightY(standPointBody);

        if (!standSpineBaseAnkle) {
          return null;
        }
      }

      if (standingPoints[0] === keyPoints.left_leg_stand_points[0]) {
        // Left leg
        calculation =
          -1 *
          ((standPointBody[jointTypes.KneeLeft].x / standSpineBaseAnkle) * 100 -
            (data[jointTypes.KneeLeft].x / standSpineBaseAnkle) * 100);
      } else {
        // Right leg
        calculation =
          (standPointBody[jointTypes.KneeRight].x / standSpineBaseAnkle) * 100 -
          (data[jointTypes.KneeRight].x / standSpineBaseAnkle) * 100;
      }

      return calculation;
    }

    if (standingPoints[1] === keyPoints.left_leg_stand_points[0]) {
      // Left leg
      i = 0;

      keyPoints.left_leg_stand_points.forEach(() => {
        if (
          keyPoints.left_leg_stand_points[i + 1] &&
          keyPoints.left_leg_stand_points[i + 1].value < time
        ) {
          i += 1;
        }
      });

      standPointBody =
        skeleton &&
        skeleton.find(
          p => p.time === keyPoints.left_leg_stand_points[i].value
        ) &&
        skeleton.find(p => p.time === keyPoints.left_leg_stand_points[i].value)
          .body;

      if (!standPointBody) {
        return null;
      }

      standSpineBaseAnkle = Calculations.spineBaseAnkleLeftY(standPointBody);

      if (!standSpineBaseAnkle) {
        return null;
      }
    } else {
      // Right leg
      i = 0;

      keyPoints.right_leg_stand_points.forEach(() => {
        if (
          keyPoints.right_leg_stand_points[i + 1] &&
          keyPoints.right_leg_stand_points[i + 1].value < time
        ) {
          i += 1;
        }
      });

      standPointBody =
        skeleton &&
        skeleton.find(
          p => p.time === keyPoints.right_leg_stand_points[i].value
        ) &&
        skeleton.find(p => p.time === keyPoints.right_leg_stand_points[i].value)
          .body;

      if (!standPointBody) {
        return null;
      }

      standSpineBaseAnkle = Calculations.spineBaseAnkleRightY(standPointBody);

      if (!standSpineBaseAnkle) {
        return null;
      }
    }

    if (standingPoints[1] === keyPoints.left_leg_stand_points[0]) {
      // Left leg
      calculation =
        -1 *
        ((standPointBody[jointTypes.KneeLeft].x / standSpineBaseAnkle) * 100 -
          (data[jointTypes.KneeLeft].x / standSpineBaseAnkle) * 100);
    } else {
      // Right leg
      calculation =
        (standPointBody[jointTypes.KneeRight].x / standSpineBaseAnkle) * 100 -
        (data[jointTypes.KneeRight].x / standSpineBaseAnkle) * 100;
    }

    return calculation;
  }

  static squatVerticalDiff(type, data) {
    const startData = data.find(point => point.type === 'start-points');
    const squatData = data.filter(point => point.type === type);

    if (!startData || squatData.length === 0) {
      return null;
    }

    const startSpineBaseVertical = startData.body[jointTypes.SpineBase].y;
    const squatVerticalValues = squatData.map(
      d => d.body[jointTypes.SpineBase].y
    );

    const averageSquatValue = Calculations.avg(squatVerticalValues);

    return Math.abs(startSpineBaseVertical - averageSquatValue);
  }

  static squatVerticalDiffPercentage(type, data, examination) {
    if (
      !examination ||
      !examination.height ||
      (examination && examination.height && examination.height <= 0)
    ) {
      return null;
    }

    const squatVerticalDiff = Calculations.squatVerticalDiff(type, data);

    if (!squatVerticalDiff) {
      return null;
    }

    return (squatVerticalDiff / examination.height) * 100;
  }

  // Evaluation table calculations

  /**
   * Returns the average valgus knee difference.
   */
  static avgValgusKneeDiff(type, data, skeleton) {
    const kneeMinValues = Calculations.calculateValgusKneeDiff(
      type,
      data,
      skeleton
    );

    if (kneeMinValues.length > 0) {
      return Math.abs(Calculations.avg(kneeMinValues));
    }

    return null;
  }

  /**
   * Returns the maximum valgus knee difference.
   */
  static maxValgusKneeDiff(type, data, skeleton) {
    const kneeMinValues = Calculations.calculateValgusKneeDiff(
      type,
      data,
      skeleton
    );

    if (kneeMinValues.length > 0) {
      return Math.abs(Calculations.min(kneeMinValues));
    }

    return null;
  }

  /**
   * Returns the average varus knee difference.
   * Used in the evaluation table.
   */
  static avgVarusKneeDiff(type, data, skeleton) {
    const kneeMaxValues = Calculations.calculateVarusKneeDiff(
      type,
      data,
      skeleton
    );

    if (kneeMaxValues.length > 0) {
      return Math.abs(Calculations.avg(kneeMaxValues));
    }

    return null;
  }

  /**
   * Returns the maximum varus knee difference.
   */
  static maxVarusKneeDiff(type, data, skeleton) {
    const kneeMaxValues = Calculations.calculateVarusKneeDiff(
      type,
      data,
      skeleton
    );

    if (kneeMaxValues.length > 0) {
      return Math.abs(Calculations.max(kneeMaxValues));
    }

    return null;
  }

  /**
   * Returns the average knee range of lateral movement (valgus and varus).
   */
  static avgKneeDiff(type, data, skeleton) {
    const valgusValues = Calculations.calculateValgusKneeDiff(
      type,
      data,
      skeleton
    );
    const varusValues = Calculations.calculateVarusKneeDiff(
      type,
      data,
      skeleton
    );
    const values = [];

    if (valgusValues.length === varusValues.length) {
      for (let i = 0; i < valgusValues.length; i += 1) {
        if (varusValues[i] < 0) {
          values.push(valgusValues[i] + varusValues[i]);
        } else {
          values.push(valgusValues[i] - varusValues[i]);
        }
      }
    }

    if (values.length > 0) {
      return Math.abs(Calculations.avg(values));
    }

    return null;
  }

  /**
   * Returns the maximum knee range of lateral movement (valgus and varus).
   */
  static maxKneeDiff(type, data, skeleton) {
    const valgusValues = Calculations.calculateValgusKneeDiff(
      type,
      data,
      skeleton
    );
    const varusValues = Calculations.calculateVarusKneeDiff(
      type,
      data,
      skeleton
    );
    const values = [];

    if (valgusValues.length === varusValues.length) {
      for (let i = 0; i < valgusValues.length; i += 1) {
        if (varusValues[i] < 0) {
          values.push(valgusValues[i] + varusValues[i]);
        } else {
          values.push(valgusValues[i] - varusValues[i]);
        }
      }
    }

    if (values.length > 0) {
      return Math.abs(Calculations.min(values));
    }

    return null;
  }

  /**
   * Returns the average squat depth.
   */
  static avgSquatDepth(type, data, skeleton) {
    const squatMinValues = Calculations.calculateMinSquatDepths(
      type,
      data,
      skeleton
    );

    if (squatMinValues.length > 0) {
      return Math.abs(Calculations.avg(squatMinValues));
    }

    return null;
  }

  /**
   * Returns the maximum squat depth.
   */
  static maxSquatDepth(type, data, skeleton) {
    const squatMinValues = Calculations.calculateMinSquatDepths(
      type,
      data,
      skeleton
    );

    if (squatMinValues.length > 0) {
      return Math.abs(Calculations.min(squatMinValues));
    }

    return null;
  }

  /**
   * Returns the symmetry index of the calculated average knee differences, left/right, right/left.
   * 100 is full symmetry.
   */
  static symmetryKneeX(data, skeleton) {
    const avgKneeDiffLeft = Calculations.avgKneeDiff(
      'left-leg-points',
      data,
      skeleton
    );
    const avgKneeDiffRight = Calculations.avgKneeDiff(
      'right-leg-points',
      data,
      skeleton
    );

    if (avgKneeDiffLeft > avgKneeDiffRight) {
      if (avgKneeDiffLeft === 0) {
        return null;
      }
      return (avgKneeDiffRight / avgKneeDiffLeft) * 100;
    }

    if (avgKneeDiffRight === 0) {
      return null;
    }
    return (avgKneeDiffLeft / avgKneeDiffRight) * 100;
  }

  /**
   * Returns the symmetry index of the calculated average squats, left/right, right/left.
   * 100 is full symmetry.
   */
  static symmetrySquat(data, skeleton) {
    const avgSquatDepthLeft = Calculations.avgSquatDepth(
      'left-leg-points',
      data,
      skeleton
    );
    const avgSquatDepthRight = Calculations.avgSquatDepth(
      'right-leg-points',
      data,
      skeleton
    );

    if (avgSquatDepthLeft > avgSquatDepthRight) {
      if (avgSquatDepthLeft === 0) {
        return null;
      }
      return (avgSquatDepthRight / avgSquatDepthLeft) * 100;
    }

    if (avgSquatDepthRight === 0) {
      return null;
    }
    return (avgSquatDepthLeft / avgSquatDepthRight) * 100;
  }

  /**
   * Calculation for average valgus knee difference at selected squat percentage.
   * Returns an object with left and right foot values.
   */
  static avgValgusKneeDiffWithSelectedPoint(
    data,
    examination,
    keyPoints,
    selectedPoint
  ) {
    if (
      !examination ||
      !examination.height ||
      (examination && examination.height && examination.height <= 0) ||
      !selectedPoint ||
      Number.isNaN(selectedPoint) ||
      selectedPoint >= 0 ||
      selectedPoint < -50 ||
      !keyPoints.left_leg_stand_points ||
      !keyPoints.right_leg_stand_points
    ) {
      return null;
    }

    // Sort standing points based on the time of the video (earlier is first)
    keyPoints.left_leg_stand_points.sort((a, b) => a.value - b.value);
    keyPoints.right_leg_stand_points.sort((a, b) => a.value - b.value);

    const squatDepthArray = [];
    let singleSquatDepth;
    let i = 0;

    data.forEach(d => {
      const { body, time } = d;

      singleSquatDepth = Calculations.squatDepth(body, data, time, keyPoints);

      if (singleSquatDepth || singleSquatDepth === 0) {
        squatDepthArray.push({
          frame: i,
          time: i / 30,
          point: singleSquatDepth.toFixed(0)
        });
      }

      i += 1;
    });

    const filteredSquatDepthArray = squatDepthArray.filter(
      d => Number(d.point).toFixed(0) === Number(selectedPoint).toFixed(0)
    );

    if (!filteredSquatDepthArray) {
      return null;
    }

    const kneeDiffArray = [];

    data.forEach(d => {
      kneeDiffArray.push(
        Calculations.kneeValgus(d.body, data, d.time, keyPoints)
      );
    });

    if (kneeDiffArray.length === 0) {
      return null;
    }

    const filteredKneeDiffArray = [];
    i = 0;

    filteredSquatDepthArray.forEach(() => {
      filteredKneeDiffArray.push({
        frame: filteredSquatDepthArray[i].frame,
        time: filteredSquatDepthArray[i].time,
        point: kneeDiffArray[filteredSquatDepthArray[i].frame]
      });
      i += 1;
    });

    const leftValgusArray = [];
    const rightValgusArray = [];

    if (
      keyPoints.left_leg_stand_points[0].value <
      keyPoints.right_leg_stand_points[0].value
    ) {
      filteredKneeDiffArray.forEach(d => {
        if (d.point < 0) {
          if (
            d.time >= keyPoints.left_leg_stand_points[0].value &&
            d.time < keyPoints.right_leg_stand_points[0].value
          ) {
            leftValgusArray.push(d.point);
          } else if (d.time <= keyPoints.left_leg_stand_points[0].value) {
            rightValgusArray.push(d.point);
          }
        }
      });
    } else {
      filteredKneeDiffArray.forEach(d => {
        if (d.point < 0) {
          if (
            d.time <= keyPoints.left_leg_stand_points[0].value &&
            d.time > keyPoints.right_leg_stand_points[0].value
          ) {
            rightValgusArray.push(d.point);
          } else if (d.time >= keyPoints.left_leg_stand_points[0].value) {
            leftValgusArray.push(d.point);
          }
        }
      });
    }

    return {
      left: Math.abs(Calculations.avg(leftValgusArray)),
      right: Math.abs(Calculations.avg(rightValgusArray))
    };
  }

  /**
   * Calculation for average varus knee difference at selected squat percentage.
   * Returns an object with left and right foot values.
   */
  static avgVarusKneeDiffWithSelectedPoint(
    data,
    examination,
    keyPoints,
    selectedPoint
  ) {
    if (
      !examination ||
      !examination.height ||
      (examination && examination.height && examination.height <= 0) ||
      !selectedPoint ||
      Number.isNaN(selectedPoint) ||
      selectedPoint >= 0 ||
      selectedPoint < -50 ||
      !keyPoints.left_leg_stand_points ||
      !keyPoints.right_leg_stand_points
    ) {
      return null;
    }

    // Sort standing points based on the time of the video (earlier is first)
    keyPoints.left_leg_stand_points.sort((a, b) => a.value - b.value);
    keyPoints.right_leg_stand_points.sort((a, b) => a.value - b.value);

    const squatDepthArray = [];
    let singleSquatDepth;
    let i = 0;

    data.forEach(d => {
      const { body, time } = d;

      singleSquatDepth = Calculations.squatDepth(body, data, time, keyPoints);

      if (singleSquatDepth || singleSquatDepth === 0) {
        squatDepthArray.push({
          frame: i,
          time: i / 30,
          point: singleSquatDepth.toFixed(0)
        });
      }

      i += 1;
    });

    const filteredSquatDepthArray = squatDepthArray.filter(
      d => Number(d.point).toFixed(0) === Number(selectedPoint).toFixed(0)
    );

    if (!filteredSquatDepthArray) {
      return null;
    }

    const kneeDiffArray = [];

    data.forEach(d => {
      kneeDiffArray.push(
        Calculations.kneeValgus(d.body, data, d.time, keyPoints)
      );
    });

    if (kneeDiffArray.length === 0) {
      return null;
    }

    const filteredKneeDiffArray = [];
    i = 0;

    filteredSquatDepthArray.forEach(() => {
      filteredKneeDiffArray.push({
        frame: filteredSquatDepthArray[i].frame,
        time: filteredSquatDepthArray[i].time,
        point: kneeDiffArray[filteredSquatDepthArray[i].frame]
      });
      i += 1;
    });

    const leftVarusArray = [];
    const rightVarusArray = [];

    if (
      keyPoints.left_leg_stand_points[0].value <
      keyPoints.right_leg_stand_points[0].value
    ) {
      filteredKneeDiffArray.forEach(d => {
        if (d.point > 0) {
          if (
            d.time >= keyPoints.left_leg_stand_points[0].value &&
            d.time < keyPoints.right_leg_stand_points[0].value
          ) {
            leftVarusArray.push(d.point);
          } else if (d.time <= keyPoints.left_leg_stand_points[0].value) {
            rightVarusArray.push(d.point);
          }
        }
      });
    } else {
      filteredKneeDiffArray.forEach(d => {
        if (d.point > 0) {
          if (
            d.time <= keyPoints.left_leg_stand_points[0].value &&
            d.time > keyPoints.right_leg_stand_points[0].value
          ) {
            rightVarusArray.push(d.point);
          } else if (d.time >= keyPoints.left_leg_stand_points[0].value) {
            leftVarusArray.push(d.point);
          }
        }
      });
    }

    return {
      left: Math.abs(Calculations.avg(leftVarusArray)),
      right: Math.abs(Calculations.avg(rightVarusArray))
    };
  }
}
