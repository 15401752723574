import env from '../../config';
import ApiHandlerService from '../../services/api-handler';

export default class SettingsApi {
  static get() {
    const url = `${env.LOCAL_API_BASE_URL}/settings`;

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-store'
      },
      method: 'GET'
    };

    return ApiHandlerService.fetch(url, config);
  }

  static update(variables) {
    const url = `${env.LOCAL_API_BASE_URL}/settings`;
    const settingToUpdate = variables && variables.settingToUpdate;

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-store'
      },
      body: JSON.stringify({
        payload: {
          settings: settingToUpdate
        }
      }),
      method: 'PUT'
    };

    return ApiHandlerService.fetch(url, config);
  }

  static async checkLocalBackend() {
    // Check wheter local backend is available
    const url = `${env.LOCAL_API_BASE_URL}/ping`;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-store'
      },
      method: 'GET'
    };

    try {
      return await ApiHandlerService.fetch(url, config);
    } catch {
      return { ok: false };
    }
  }
}
