import { REQUEST, UPDATE_EVALUATION } from '../request';

// GET evaluation data
const GET_EVALUATION_SUCCESS = 'GET_EVALUATION_SUCCESS';
const GET_EVALUATION_FAILED = 'GET_EVALUATION_FAILED';

// UPDATE evaluation
const UPDATE_EVALUATION_SUCCESS = 'UPDATE_EVALUATION_SUCCESS';
const UPDATE_EVALUATION_FAILED = 'UPDATE_EVALUATION_FAILED';

const CLEAN_EVALUATION = 'CLEAN_EVALUATION';

export const Types = {
  GET_EVALUATION_SUCCESS,
  GET_EVALUATION_FAILED,
  UPDATE_EVALUATION_SUCCESS,
  UPDATE_EVALUATION_FAILED,
  REQUEST
};

export const initialState = {
  isFetching: false,
  isUpdating: false,
  recorded: {},
  data: {},
  error: null
};

const evaluation = (state = initialState, action) => {
  if (!action) return state;

  switch (action.type) {
    case REQUEST:
      if (action.payload.action === UPDATE_EVALUATION) {
        return {
          ...state,
          isUpdating: true
        };
      }
      return {
        ...state,
        isFetching: true
      };
    // On GET success -> recorded and data that users can change both returned
    case GET_EVALUATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        recorded:
          action.payload && action.payload.data && action.payload.data.recorded,
        data: action.payload && action.payload.data && action.payload.data.data,
        error: null
      };
    // On UPDATE success -> only data that users can change returned
    case UPDATE_EVALUATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isUpdating: false,
        data: {
          ...state.data,
          keyPoints:
            action.payload &&
            action.payload.data &&
            action.payload.data.evaluation &&
            action.payload.data.evaluation.keyPoints,
          calculations:
            action.payload &&
            action.payload.data &&
            action.payload.data.evaluation &&
            action.payload.data.evaluation.calculations,
          comments:
            action.payload &&
            action.payload.data &&
            action.payload.data.evaluation &&
            action.payload.data.evaluation.comments
        },
        error: null
      };
    case UPDATE_EVALUATION_FAILED:
    case GET_EVALUATION_FAILED:
      return {
        ...state,
        isFetching: false,
        isUpdating: false,
        recorded: state.recorded || {},
        data: state.data || {},
        errors: null
      };
    case CLEAN_EVALUATION:
      return initialState;
    case 'FLUSH':
      return initialState;
    default:
      return state;
  }
};

export const cleanEvaluation = () => ({
  type: CLEAN_EVALUATION
});

export const Actions = { cleanEvaluation };

export default evaluation;
