/* globals navigator Blob document */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import EvaluationView from './evaluation-view';
import Loader from '../../../loader';
import NotFound from '../../../not-found';
import Calculations from '../../../../services/calculations';
import {
  jointTypes,
  jointTypesAzure,
  azureJointNames
} from '../../../../services/joints';

export default class Evaluation extends Component {
  static propTypes = {
    getEvaluation: PropTypes.func,
    updateEvaluation: PropTypes.func,
    examination: PropTypes.shape(),
    evaluation: PropTypes.shape(),
    patient: PropTypes.shape(),
    updateRemoteExamination: PropTypes.func,
    cleanEvaluation: PropTypes.func
  };

  /**
   * Formats `keyPoints` from backend for frontend usage
   */
  static formatMappableKeyPoints(keyPoints) {
    // If no keypoints got -> return empty array
    if (!keyPoints) {
      return [];
    }

    // Define list of fronted formatted keyPoints
    const mappableKeyPoints = [];
    let i = 0;

    // Iterate through keyPoints' keys
    Object.keys(keyPoints).forEach(key => {
      // Iterate through actual key's list
      keyPoints[key].forEach(point => {
        // Increment id
        i += 1;
        // Push the defined object
        // to the frontend keypoint list
        const obj = {
          id: i,
          type: key.split('_').join('-')
        };

        if (typeof point === 'number') {
          obj.value = point;
        }

        if (typeof point === 'object') {
          obj.value = point.value;
          obj.manualQAnglePoints = point.points;
        }

        mappableKeyPoints.push(obj);
      });
    });

    return mappableKeyPoints;
  }

  static calculateDomainValues(chartElement) {
    const domain = [];

    domain[0] =
      chartElement &&
      chartElement.data &&
      Math.round(
        Number(
          chartElement.data.map(a => a).sort((a, b) => b.y - a.y)[
            chartElement.data.length - 1
          ].y
        ) - 5
      );
    domain[1] =
      chartElement &&
      chartElement.data &&
      Math.round(
        Number(chartElement.data.map(a => a).sort((a, b) => b.y - a.y)[0].y) + 5
      );

    return domain;
  }

  /**
   * Format Chart data from kinect json (skeleton)
   *
   * Skeleton contains:
   * - `time` -> timestamp
   * - `body` -> array of joints with `x`, `y` and `z` coordinates
   *
   * Returns `chartData`, array of objects.
   * An object contains:
   * - `name` of the chart
   * - `data` for the chart
   * - `secondLineData` for the chart (only in squatKneeValgus)
   * - `domain` for charts
   * - `options` for charts - optional, this cointains if the values are percentages (default is cm)
   */
  static formatChartData(skeleton, version = 'v1', keyPoints) {
    // Define chart data object with empty data arrays
    const chartData = {
      spineBaseV: {
        id: 'spineBaseV',
        title: 'Spine Base X',
        data: [],
        domain: [null, null]
      },
      spineMidX: {
        id: 'spineChest',
        title: 'Chest',
        data: [],
        domain: [null, null],
        options: { isLeftRight: true }
      },
      kneeX: {
        id: 'kneeX',
        title: 'Knee Left/Right X',
        data: [],
        domain: [null, null],
        options: { isValgusVarus: true }
      },
      wristDiffV: {
        id: 'wristDiffV',
        title: 'Wrist Diff V',
        data: [],
        domain: [-30, 30]
      },
      squatKneeValgus: {
        id: 'squatKneeValgus',
        title: 'Squat depth/knee valgus',
        data: [],
        secondLineData: [],
        domain: [-50, 15],
        options: { isPercentage: true }
      }
    };

    // If no json data, return chart data
    if (!skeleton) {
      return chartData;
    }

    // Iterate through skeleton
    skeleton.forEach(data => {
      // Define values for charts
      if (data && data.body && data.body.length >= 25) {
        let inverter = 1;
        if (version === 'azure') {
          inverter = -1;
        }
        const { time } = data;
        const spineBaseYValue = data.body[jointTypes.SpineBase].y * inverter;
        const spineMidXValue = data.body[jointTypes.SpineMid].x * inverter;
        const wristLeftV = data.body[jointTypes.WristLeft].y * inverter;
        const wristRightV = data.body[jointTypes.WristRight].y * inverter;

        // Add proper objects to every array (chart)
        chartData.spineBaseV.data.push({
          x: time,
          y: spineBaseYValue
        });
        chartData.spineMidX.data.push({
          x: time,
          y: spineMidXValue
        });
        chartData.kneeX.data.push({
          x: time,
          y: Calculations.kneeHipX(data.body, skeleton, time, keyPoints)
        });
        chartData.wristDiffV.data.push({
          x: time,
          y: wristRightV - wristLeftV
        });
        chartData.squatKneeValgus.data.push({
          x: time,
          y: Calculations.squatDepth(data.body, skeleton, time, keyPoints)
        });
        chartData.squatKneeValgus.secondLineData.push({
          x: time,
          y: Calculations.kneeValgus(data.body, skeleton, time, keyPoints)
        });
      }
    });

    // Calculate min and max values of those have null for domain
    const { spineBaseV, spineMidX, kneeX } = chartData;

    spineBaseV.domain = Evaluation.calculateDomainValues(spineBaseV);
    spineMidX.domain = Evaluation.calculateDomainValues(spineMidX);
    kneeX.domain = Evaluation.calculateDomainValues(kneeX);

    return chartData;
  }

  /**
   * Select nearst Carv data to `videoTime`
   */
  static selectNearestCarv(videoTime, carvData) {
    // If no skeleton data -> return null
    if (!carvData) {
      return null;
    }

    // Parse time
    const time = parseFloat(videoTime);

    // Define nearest and lastIndex
    // Set them null to default
    let nearestTime = null;
    let nearestIndex = null;

    // Iterate through skeleton data
    carvData.forEach((data, index) => {
      // Define actual data's timestamp
      const stamp = data.time && Number(data.time);

      if (!stamp) {
        return;
      }

      // If actual timestamp is nearer than
      // the previously defined one:
      // -> Set nearest to actual timestamp
      // -> Set index to actual index
      if (
        nearestTime === null ||
        Math.abs(stamp - time) < Math.abs(nearestTime - time)
      ) {
        nearestTime = stamp;
        nearestIndex = index;
      }
    });

    // If nearest is null (not found data) -> return null
    if (nearestTime === null) {
      return null;
    }

    // Return the body data for nearest time
    const actualCarv = carvData[nearestIndex];
    return actualCarv;
  }

  /**
   * Selects the `skeleton` data nearest to `videoTime`
   */
  static selectNearestData(videoTime, skeleton, returnType = 'body') {
    // If no skeleton data -> return null
    if (!skeleton) {
      return null;
    }

    if (
      returnType === 'body' &&
      (videoTime < skeleton[0].time ||
        videoTime > skeleton[skeleton.length - 1].time)
    ) {
      return null;
    }

    // Parse time
    const time = parseFloat(videoTime);

    // Define nearest and lastIndex
    // Set them null to default
    let nearestTime = null;
    let nearestIndex = null;

    // Iterate through skeleton data
    for (let index = 0; index < skeleton.length; index += 1) {
      const data = skeleton[index];
      // Define actual data's timestamp
      const stamp = Number(data.time);

      // If actual timestamp is nearer than
      // the previously defined one:
      // -> Set nearest to actual timestamp
      // -> Set index to actual index
      if (
        nearestTime === null ||
        Math.abs(stamp - time) < Math.abs(nearestTime - time)
      ) {
        nearestTime = stamp;
        nearestIndex = index;
      }

      if (nearestTime !== null && stamp - time > 0) {
        break;
      }
    }

    // If nearest is null (not found data) -> return null
    if (nearestTime === null) {
      return null;
    }

    // Return the body data for nearest time
    const actualBody = skeleton[nearestIndex].body;

    if (actualBody.length === 0) {
      return null;
    }

    if (returnType === 'time') {
      if (
        videoTime < skeleton[0].time ||
        videoTime > skeleton[skeleton.length - 1].time
      ) {
        return videoTime;
      }
      return Number(skeleton[nearestIndex].time);
    }

    if (returnType === 'nextTime') {
      let nextIndex = nearestIndex + 1;
      let actualTime = nearestTime;
      while (nextIndex < skeleton.length - 1) {
        if (actualTime !== Number(skeleton[nextIndex].time)) {
          return Number(skeleton[nextIndex].time);
        }
        actualTime = skeleton[nextIndex].time;
        nextIndex += 1;
      }
      return actualTime;
    }

    if (returnType === 'previousTime') {
      let nextIndex = nearestIndex - 1;
      let actualTime = nearestTime;
      while (nextIndex >= 0) {
        if (actualTime !== Number(skeleton[nextIndex].time)) {
          return Number(skeleton[nextIndex].time);
        }
        actualTime = skeleton[nextIndex].time;
        nextIndex -= 1;
      }
      return actualTime;
    }

    return actualBody;
  }

  /**
   * Exports report data as CSV
   */
  static exportTimeSeries(skeleton, examination) {
    const csvHeaders = ['examID', 'timestamp', 'SpineBaseV', 'WristDiff'];

    const examID = examination.id;
    let csvData = `${csvHeaders.join(',')}`;

    if (!skeleton) {
      return;
    }

    csvData += '\n';

    let inverter = 1;
    if (examination.version === 'azure') {
      inverter = -1;
    }

    skeleton.forEach(s => {
      const wristLeftV = s.body[jointTypes.WristLeft].y * inverter;
      const wristRightV = s.body[jointTypes.WristRight].y * inverter;

      const timestamp = moment()
        .hours(0)
        .minutes(0)
        .seconds(0)
        .milliseconds(s.time * 1000)
        .format('mm:ss.SSS');

      const spineBaseV = Calculations.spineBaseHeight(s.body).toFixed(1);
      const wristDiffV = (wristRightV - wristLeftV).toFixed(1);

      const row = [examID, timestamp, spineBaseV, wristDiffV];

      csvData += `${row.join(',')}\n`;
    });

    const fileName = `exam_${examID}_time_series.csv`;

    const blob = new Blob([csvData], {
      type: 'text/csv;charset=utf-8;'
    });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.click();
      }
    }
  }

  /**
   * Exports exaination's raw dataset as CSV
   */
  static exportRawDataset(skeleton, examination, carv, keyPoints) {
    // Base headers
    const csvHeaders = ['ts', 'video_time', 'is_keypoint', 'keypoint_type'];
    const ankles = {
      [jointTypesAzure.AnkleLeft]: 'left',
      [jointTypesAzure.AnkleRight]: 'right'
    };

    // Raw kinect data headers
    const keyOrder = [];
    Object.keys(jointTypesAzure).forEach(joint => {
      if (Object.keys(azureJointNames).includes(joint)) {
        csvHeaders.push(`${azureJointNames[joint]}_x`);
        csvHeaders.push(`${azureJointNames[joint]}_y`);
        csvHeaders.push(`${azureJointNames[joint]}_z`);

        if (Object.keys(ankles).includes(jointTypesAzure[joint].toString())) {
          csvHeaders.push(`Q_angle_${ankles[jointTypesAzure[joint]]}`);
        }

        keyOrder.push(jointTypesAzure[joint]);
      }
    });

    // Manual Q angle headers
    csvHeaders.push(
      'manual_sias_x',
      'manual_sias_y',
      'manual_knee_x',
      'manual_knee_y',
      'manual_ankle_x',
      'manual_ankle_y',
      'manual_Q_angle'
    );

    // Carv headers

    // Properties from raw carv data
    const carvProperties = [
      'ax',
      'ay',
      'az',
      'pitch',
      'qw',
      'qx',
      'qy',
      'qz',
      'roll',
      'yaw',
      'time'
    ];

    ['carv_left', 'carv_right'].forEach(carvSide => {
      carvProperties.forEach(property => {
        csvHeaders.push(`${carvSide}_${property}`);
      });
      for (let x = 0; x < 48; x += 1) {
        csvHeaders.push(`${carvSide}_${x + 1}`);
      }
    });

    // Get exam ID
    const examID = examination.id;
    let csvData = `${csvHeaders.join(',')}`;

    // If no skeleton is found => return empty csv
    if (!skeleton) {
      return;
    }

    csvData += '\n';

    // Select and define raw dataset for every data kinect has a timestamp for
    skeleton.forEach(s => {
      const timestamp = s.time; // timestamp
      const videoTime = moment()
        .hours(0)
        .minutes(0)
        .seconds(0)
        .milliseconds(s.time * 1000)
        .format('mm:ss.SSS'); // video_time
      const actualKeyPoint = keyPoints.find(k => k.value === s.time);
      const isKeypoint = actualKeyPoint ? 1 : 0; // is_keypoint
      const keypointType = (actualKeyPoint && actualKeyPoint.type) || ''; // keypoint_type

      const leftCarvData = Evaluation.selectNearestCarv(
        s.time,
        carv && carv.left_carv
      );
      const rightCarvData = Evaluation.selectNearestCarv(
        s.time,
        carv && carv.right_carv
      );

      // Add defined data to row
      const row = [timestamp, videoTime, isKeypoint, keypointType];

      // Add every joint's data as mapped above
      keyOrder.forEach(jointKey => {
        row.push(s.body[jointKey].x, s.body[jointKey].y, s.body[jointKey].z);
        if (Object.keys(ankles).includes(jointKey.toString())) {
          if (ankles[jointKey] === 'left') {
            row.push(Calculations.qAngleLeft(s.body));
          } else if (ankles[jointKey] === 'right') {
            row.push(Calculations.qAngleRight(s.body));
          }
        }
      });

      // Try to define manual Q angle data
      // If can, add them to row, else add empty strings
      const hip = { x: '', y: '' };
      const knee = { x: '', y: '' };
      const ankle = { x: '', y: '' };
      let manualQAngle = '';
      if (actualKeyPoint && actualKeyPoint.manualQAnglePoints) {
        actualKeyPoint.manualQAnglePoints.forEach(point => {
          switch (point.type) {
            case 1:
              hip.x = point.x;
              hip.y = point.y;
              break;
            case 2:
              knee.x = point.x;
              knee.y = point.y;
              break;
            case 3:
              ankle.x = point.x;
              ankle.y = point.y;
              break;
            default:
              break;
          }
        });

        let inverter = -1;
        if (examination.version === 'azure') {
          inverter = 1;
        }

        const bulkBody = [];
        bulkBody[jointTypes.WristLeft] = hip;
        bulkBody[jointTypes.WristRight] = hip;
        bulkBody[jointTypes.KneeLeft] = knee;
        bulkBody[jointTypes.KneeRight] = knee;
        bulkBody[jointTypes.AnkleLeft] = ankle;
        bulkBody[jointTypes.AnkleRight] = ankle;

        if (actualKeyPoint.type.includes('left')) {
          manualQAngle = Calculations.qAngleLeft(bulkBody) * inverter;
        } else if (actualKeyPoint.type.includes('right')) {
          manualQAngle = Calculations.qAngleRight(bulkBody) * inverter;
        }
      }
      row.push(hip.x, hip.y, knee.x, knee.y, ankle.x, ankle.y, manualQAngle);

      // Try to define carv data for each left and right
      // If can, add defined data, else add empty strings to fill row
      [leftCarvData, rightCarvData].forEach(carvData => {
        carvProperties.forEach(property => {
          if (carvData && carvData[property]) {
            row.push(carvData[property]);
          } else {
            row.push('');
          }
        });

        if (carvData && carvData.force && carvData.force.length === 48) {
          carvData.force.forEach(force => {
            row.push(force);
          });
        } else {
          row.push(Array(48).fill(''));
        }
      });

      csvData += `${row.join(',')}\n`;
    });

    const fileName = `exam_${examID}_raw_data.csv`;

    const blob = new Blob([csvData], {
      type: 'text/csv;charset=utf-8;'
    });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.click();
      }
    }
  }

  /**
   * Calculates data for the report
   */
  static calculateReportData(keyPoints, skeleton, examination) {
    if (!keyPoints || !skeleton) {
      return null;
    }

    const keyPointsWithBody = [];
    keyPoints.forEach(point => {
      const bodyData = Evaluation.selectNearestData(point.value, skeleton);
      if (bodyData) {
        keyPointsWithBody.push({
          ...point,
          body: bodyData
        });
      }
    });

    const calculated = [];

    const squatVerticalPercentageLeft = Calculations.squatVerticalDiffPercentage(
      'left-leg-points',
      keyPointsWithBody,
      examination
    );

    const squatVerticalPercentageRight = Calculations.squatVerticalDiffPercentage(
      'right-leg-points',
      keyPointsWithBody,
      examination
    );

    // Valgus knee difference
    const avgValgusKneeDiffLeft = Calculations.avgValgusKneeDiff(
      'left-leg-points',
      keyPointsWithBody,
      skeleton
    );

    const avgValgusKneeDiffRight = Calculations.avgValgusKneeDiff(
      'right-leg-points',
      keyPointsWithBody,
      skeleton
    );

    const maxValgusKneeDiffLeft = Calculations.maxValgusKneeDiff(
      'left-leg-points',
      keyPointsWithBody,
      skeleton
    );

    const maxValgusKneeDiffRight = Calculations.maxValgusKneeDiff(
      'right-leg-points',
      keyPointsWithBody,
      skeleton
    );

    // Varus knee difference
    const avgVarusKneeDiffLeft = Calculations.avgVarusKneeDiff(
      'left-leg-points',
      keyPointsWithBody,
      skeleton
    );

    const avgVarusKneeDiffRight = Calculations.avgVarusKneeDiff(
      'right-leg-points',
      keyPointsWithBody,
      skeleton
    );

    const maxVarusKneeDiffLeft = Calculations.maxVarusKneeDiff(
      'left-leg-points',
      keyPointsWithBody,
      skeleton
    );

    const maxVarusKneeDiffRight = Calculations.maxVarusKneeDiff(
      'right-leg-points',
      keyPointsWithBody,
      skeleton
    );

    // Knee difference
    const avgKneeDiffLeft = Calculations.avgKneeDiff(
      'left-leg-points',
      keyPointsWithBody,
      skeleton
    );

    const avgKneeDiffRight = Calculations.avgKneeDiff(
      'right-leg-points',
      keyPointsWithBody,
      skeleton
    );

    const maxKneeDiffLeft = Calculations.maxKneeDiff(
      'left-leg-points',
      keyPointsWithBody,
      skeleton
    );

    const maxKneeDiffRight = Calculations.maxKneeDiff(
      'right-leg-points',
      keyPointsWithBody,
      skeleton
    );

    // Squat depth
    const avgSquatDepthLeft = Calculations.avgSquatDepth(
      'left-leg-points',
      keyPointsWithBody,
      skeleton
    );

    const avgSquatDepthRight = Calculations.avgSquatDepth(
      'right-leg-points',
      keyPointsWithBody,
      skeleton
    );

    const maxSquatDepthLeft = Calculations.maxSquatDepth(
      'left-leg-points',
      keyPointsWithBody,
      skeleton
    );

    const maxSquatDepthRight = Calculations.maxSquatDepth(
      'right-leg-points',
      keyPointsWithBody,
      skeleton
    );

    // Symmetry
    const symmetryKneeX = Calculations.symmetryKneeX(
      keyPointsWithBody,
      skeleton
    );

    const symmetrySquat = Calculations.symmetrySquat(
      keyPointsWithBody,
      skeleton
    );

    calculated.push(
      {
        key: 'Squat depth',
        left: squatVerticalPercentageLeft,
        right: squatVerticalPercentageRight,
        margin: true
      },
      {
        key: 'Average knee valgus (%)',
        left: avgValgusKneeDiffLeft,
        right: avgValgusKneeDiffRight
      },
      {
        key: 'Maximum knee valgus knee (%)',
        left: maxValgusKneeDiffLeft,
        right: maxValgusKneeDiffRight,
        margin: true
      },
      {
        key: 'Average knee varus (%)',
        left: avgVarusKneeDiffLeft,
        right: avgVarusKneeDiffRight
      },
      {
        key: 'Maximum knee varus (%)',
        left: maxVarusKneeDiffLeft,
        right: maxVarusKneeDiffRight,
        margin: true
      },
      {
        key: 'Average Knee range of lateral movement (valgus+varus) (%)',
        left: avgKneeDiffLeft,
        right: avgKneeDiffRight
      },
      {
        key: 'Maximum Knee range of lateral movement (valgus+varus) (%)',
        left: maxKneeDiffLeft,
        right: maxKneeDiffRight,
        margin: true
      },
      {
        key: 'Average squat depth (%)',
        left: avgSquatDepthLeft,
        right: avgSquatDepthRight
      },
      {
        key: 'Maximum squat depth (%)',
        left: maxSquatDepthLeft,
        right: maxSquatDepthRight,
        margin: true
      },
      {
        key: 'Symmetry Index (knee lateral movement)',
        left: symmetryKneeX,
        right: symmetryKneeX
      },
      {
        key: 'Symmetry Index (squat depth)',
        left: symmetrySquat,
        right: symmetrySquat
      }
    );

    return calculated;
  }

  /**
   * Calculates data with inputs for the report
   */
  static calculateSpecialReportData(
    keyPoints,
    skeleton,
    examination,
    selectedPoint
  ) {
    if (!keyPoints || !skeleton) {
      return null;
    }

    const selectedPointInverse = selectedPoint * -1;

    const avgValgus = Calculations.avgValgusKneeDiffWithSelectedPoint(
      skeleton,
      examination,
      keyPoints,
      selectedPointInverse
    );

    const avgVarus = Calculations.avgVarusKneeDiffWithSelectedPoint(
      skeleton,
      examination,
      keyPoints,
      selectedPointInverse
    );

    return {
      avgValgusLeft: avgValgus && avgValgus.left,
      avgValgusRight: avgValgus && avgValgus.right,
      avgVarusLeft: avgVarus && avgVarus.left,
      avgVarusRight: avgVarus && avgVarus.right
    };
  }

  componentDidMount() {
    const { getEvaluation, examination } = this.props;
    if (examination && examination.id) {
      // Fetch evaluation on mount
      getEvaluation(examination.id);
    }
  }

  componentWillUnmount() {
    const { cleanEvaluation } = this.props;
    cleanEvaluation();
  }

  render() {
    const {
      evaluation,
      updateEvaluation,
      examination,
      updateRemoteExamination,
      patient
    } = this.props;

    // While evaluation is not loaded, render loader
    if (!evaluation || (evaluation && evaluation.isFetching)) {
      return <Loader message="Loading evaluation" isWrapped />;
    }

    // Define data pieces from evaluation
    const { recorded, data } = evaluation;
    const { examinationId } = data;

    // If recorded data is not found -> render not found
    if (
      !recorded ||
      !data ||
      (recorded && !recorded.kinect) ||
      (recorded && !recorded.skeleton)
    ) {
      return <NotFound isWrapped message="Evaluation data not found" icon />;
    }

    // Define video source
    const { videoSource, carv, kinect, skeleton } = recorded;
    let carvData = {};
    let skeletonForDisplay = [];
    let skeletonForCalculation = [];
    let keyPoints = {};

    if (kinect) {
      skeletonForCalculation = JSON.parse(kinect);

      // Filter out parts where data is not present
      skeletonForCalculation = skeletonForCalculation.filter(
        s => s.body && s.body.length !== 0
      );

      // Kinect standard unit is meters
      let unitModifier = 100;

      // @IMPORTANT:
      // After kinect update on 2020-02-01, the new standard unit is milimeters
      if (moment(examination.date).isAfter(moment('2020-02-01'))) {
        unitModifier = 0.1;
      }

      // Convert raw data values (meter) to centimeter
      skeletonForCalculation = skeletonForCalculation.map(s => {
        const convertedSkeleton = s;
        if (convertedSkeleton.body) {
          convertedSkeleton.body = convertedSkeleton.body.map(b => {
            const convertedBody = b;
            if (convertedBody.x) {
              convertedBody.x *= unitModifier;
            }
            if (convertedBody.y) {
              convertedBody.y *= unitModifier;
            }
            if (convertedBody.z) {
              convertedBody.z *= unitModifier;
            }
            return convertedBody;
          });
        }
        return convertedSkeleton;
      });
    }

    if (skeleton) {
      skeletonForDisplay = JSON.parse(skeleton);
    }

    if (skeletonForCalculation.length === 0) {
      return <NotFound isWrapped message="Kinect data not found" icon />;
    }

    // Define keyPoints from data
    if (data && data.keyPoints) {
      keyPoints = JSON.parse(data && data.keyPoints);
    }

    // Define carv
    if (carv) {
      carvData = JSON.parse(carv);
    }

    // Define chartData and domain from skeleton
    const chartData = Evaluation.formatChartData(
      skeletonForCalculation,
      examination.version,
      keyPoints
    );

    // Format keyPoints for frontend usage
    const mappableKeyPoints = Evaluation.formatMappableKeyPoints(keyPoints);

    if (
      !videoSource ||
      !skeletonForCalculation ||
      !skeletonForDisplay ||
      !keyPoints
    ) {
      return <Loader />;
    }

    return (
      <EvaluationView
        comments={data.comments}
        patient={patient}
        carv={carvData}
        examinationId={examinationId}
        examination={examination}
        videoSource={videoSource}
        skeleton={skeletonForDisplay}
        skeletonForCalculation={skeletonForCalculation}
        keyPoints={mappableKeyPoints}
        unformattedKeyPoints={keyPoints}
        chartData={chartData}
        updateEvaluation={updateEvaluation}
        selectNearestData={Evaluation.selectNearestData}
        selectNearestCarv={Evaluation.selectNearestCarv}
        calculateReportData={Evaluation.calculateReportData}
        calculateSpecialReportData={Evaluation.calculateSpecialReportData}
        updateRemoteExamination={updateRemoteExamination}
        exportTimeSeries={Evaluation.exportTimeSeries}
        exportRawDataset={Evaluation.exportRawDataset}
      />
    );
  }
}
