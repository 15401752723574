import { combineReducers } from 'redux';
import app from './app';
import auth from './auth';
import examinations from './examinations';
import patients from './patients';
import print from './print';
import evaluation from './evaluation';
import localExamination from './local-examination';
import settings from './settings';

export default combineReducers({
  app,
  auth,
  examinations,
  patients,
  print,
  evaluation,
  examination: localExamination,
  settings
});
