import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CarvCredentialsView from './carv-credentials-view';

export default class CarvCredentials extends Component {
  static propTypes = {
    carvCredentials: PropTypes.string,
    updateSettings: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      isChanging: false,
      changedData: {
        username: '',
        password: ''
      }
    };
    this.save = this.save.bind(this);
    this.cancelChange = this.cancelChange.bind(this);
    this.startChange = this.startChange.bind(this);
    this.setData = this.setData.bind(this);
  }

  setData(obj) {
    this.setState(prevState => ({
      ...prevState,
      changedData: { ...prevState.changedData, [obj.name]: obj.value }
    }));
  }

  save() {
    const { updateSettings } = this.props;
    const { changedData } = this.state;
    updateSettings({ carvCredentials: { ...changedData } });
    this.cancelChange();
  }

  cancelChange() {
    this.setState({
      isChanging: false,
      changedData: {
        username: '',
        password: ''
      }
    });
  }

  startChange() {
    const { carvCredentials } = this.props;

    let settingUserName = '';
    let settingPassword = '';

    let carvCredentialsObject;

    if (carvCredentials) {
      try {
        carvCredentialsObject = JSON.parse(carvCredentials);
      } catch {
        carvCredentialsObject = null;
      }
    }

    if (carvCredentialsObject) {
      const { username, password } = carvCredentialsObject;
      settingUserName = username;
      settingPassword = password;
    }

    this.setState({
      isChanging: true,
      changedData: {
        username: settingUserName,
        password: settingPassword
      }
    });
  }

  render() {
    const { isChanging, changedData } = this.state;
    const { carvCredentials } = this.props;

    let carvCredentialsObject;

    if (carvCredentials) {
      try {
        carvCredentialsObject = JSON.parse(carvCredentials);
      } catch {
        carvCredentialsObject = null;
      }
    }

    return (
      <CarvCredentialsView
        changedData={changedData}
        isChanging={isChanging}
        carvCredentials={carvCredentialsObject}
        startChange={this.startChange}
        cancelChange={this.cancelChange}
        setData={this.setData}
        save={this.save}
      />
    );
  }
}
