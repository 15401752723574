import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ThermalPathView from './thermal-path-view';

export default class ThermalPath extends Component {
  static propTypes = {
    thermalPath: PropTypes.string,
    updateSettings: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      isChanging: false,
      changedData: {
        thermalPath: ''
      }
    };
    this.save = this.save.bind(this);
    this.cancelChange = this.cancelChange.bind(this);
    this.startChange = this.startChange.bind(this);
    this.setData = this.setData.bind(this);
  }

  setData(obj) {
    this.setState(prevState => ({
      ...prevState,
      changedData: { ...prevState.changedData, [obj.name]: obj.value }
    }));
  }

  save() {
    const { updateSettings } = this.props;
    const { changedData } = this.state;
    updateSettings({ ...changedData });
    this.cancelChange();
  }

  cancelChange() {
    this.setState({
      isChanging: false,
      changedData: {
        thermalPath: ''
      }
    });
  }

  startChange() {
    const { thermalPath } = this.props;
    this.setState({
      isChanging: true,
      changedData: {
        thermalPath
      }
    });
  }

  render() {
    const { isChanging, changedData } = this.state;
    const { thermalPath } = this.props;

    return (
      <ThermalPathView
        changedData={changedData}
        isChanging={isChanging}
        thermalPath={thermalPath}
        startChange={this.startChange}
        cancelChange={this.cancelChange}
        setData={this.setData}
        save={this.save}
      />
    );
  }
}
