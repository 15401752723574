import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Chart from '../chart';

/**
 * Component that contains a `Chart` and sliders as keypoints
 */
export default class KeyPoints extends Component {
  static propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    duration: PropTypes.number,
    time: PropTypes.number,
    data: PropTypes.arrayOf(PropTypes.shape()),
    secondLineData: PropTypes.arrayOf(PropTypes.shape()),
    keyPoints: PropTypes.arrayOf(PropTypes.shape()),
    setDraggedKeyPoint: PropTypes.func,
    setToCurrentTime: PropTypes.func,
    setSelected: PropTypes.func,
    setDragging: PropTypes.func,
    selected: PropTypes.number,
    chartDomain: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.number)
    ]),
    chartOptions: PropTypes.shape(),
    bubbleStyle: PropTypes.shape()
  };

  constructor(props) {
    super(props);
    this.state = {
      aboveValueShown: -1
    };
    this.onKeyPointMouseEnter = this.onKeyPointMouseEnter.bind(this);
    this.onKeyPointMouseLeave = this.onKeyPointMouseLeave.bind(this);
  }

  onKeyPointMouseEnter(id) {
    this.setState({
      aboveValueShown: id
    });
  }

  onKeyPointMouseLeave() {
    this.setState({
      aboveValueShown: -1
    });
  }

  renderKeyPoints() {
    const { onKeyPointMouseEnter, onKeyPointMouseLeave } = this;

    const {
      keyPoints,
      id,
      duration,
      data,
      secondLineData,
      setDraggedKeyPoint,
      setDragging,
      setToCurrentTime,
      selected,
      setSelected,
      bubbleStyle
    } = this.props;

    const { aboveValueShown } = this.state;

    if (!duration) {
      return null;
    }

    // Filtering out jumping points
    const filteredKeypoints = keyPoints.filter(k => k.type !== 'jump-points');

    // Sorting keypoints based on their values (time)
    filteredKeypoints.sort((a, b) => a.value - b.value);

    // Creating a blank line for leg change in Squat depth/knee valgus chart
    let previousKeypoint;
    let i = 0;

    // Rendering
    const renderedKeypoints = filteredKeypoints.map(slider => {
      if (i > 0) {
        previousKeypoint = filteredKeypoints[i - 1];
      }
      i += 1;
      return (
        <React.Fragment key={slider.id}>
          <style>
            {aboveValueShown === slider.id
              ? `.slider.${id}${slider.id}::before {
              content: '${
                data.find(element => element.x === slider.value).y ||
                data.find(element => element.x === slider.value).y === 0
                  ? data
                      .find(element => element.x === slider.value)
                      .y.toFixed(1)
                  : null
              }';
              position: absolute;
              left: ${(slider.value * 100) / duration}%;
              color: #fff;
              padding: 4px 10px;
              border: 2px solid #fff;
              border-radius: 4px;
              font-weight: 600;
              transform: translate(-50%, -30px);
            }`
              : ''}
          </style>

          {previousKeypoint &&
          ((slider.type === 'left-leg-stand-points' &&
            previousKeypoint.type === 'right-leg-points') ||
            (slider.type === 'right-leg-stand-points' &&
              previousKeypoint.type === 'left-leg-points')) &&
          (id === 'squatKneeValgus' || id === 'kneeX') ? (
            <input
              type="range"
              className="slider black"
              min={0}
              max={duration}
              step={1 / 30}
              value={slider.value - 2}
              readOnly
            />
          ) : (
            ''
          )}

          <input
            onDoubleClick={e => setToCurrentTime(e, slider.id)}
            name={slider.id}
            type="range"
            className={`slider ${slider.type} ${id}${slider.id} ${
              slider.id === selected ? `selected-point` : ``
            }`}
            min={0}
            max={duration}
            step={1 / 30}
            value={slider.value}
            onMouseDown={() => setDragging(true)}
            onMouseUp={e => {
              return setDragging(false, true, { event: e, id: slider.id });
            }}
            onClick={() => setSelected(slider.id)}
            onChange={e => setDraggedKeyPoint(e, slider.id)}
            onMouseEnter={() => onKeyPointMouseEnter(slider.id)}
            onMouseLeave={() => onKeyPointMouseLeave()}
          />
          <output
            className={`bubble ${slider.type} ${
              secondLineData ? 'small' : null
            }`}
            style={
              bubbleStyle && bubbleStyle.id === slider.id
                ? bubbleStyle.style
                : { left: `${(slider.value * 100) / duration}%` }
            }
          >
            {data.find(element => element.x === slider.value) &&
            (data.find(element => element.x === slider.value).y ||
              data.find(element => element.x === slider.value).y === 0)
              ? data.find(element => element.x === slider.value).y.toFixed(1)
              : null}
            {secondLineData &&
            secondLineData.find(element => element.x === slider.value) &&
            (secondLineData.find(element => element.x === slider.value).y ||
              secondLineData.find(element => element.x === slider.value).y ===
                0)
              ? `/${secondLineData
                  .find(element => element.x === slider.value)
                  .y.toFixed(1)}`
              : null}
          </output>
        </React.Fragment>
      );
    });

    return renderedKeypoints;
  }

  render() {
    const {
      title,
      duration,
      data,
      secondLineData,
      time,
      chartDomain,
      chartOptions
    } = this.props;
    const calculatedPercentage = duration && (time / duration) * 100;

    const maxDomain =
      chartDomain[1] === (0 || null)
        ? { x: duration }
        : { y: chartDomain[1], x: duration };
    const minDomain =
      chartDomain[0] === (0 || null) ? { x: 0 } : { x: 0, y: chartDomain[0] };

    if (chartOptions) {
      // Setting up custom values for the domain
      if (chartOptions.isValgusVarus) {
        if (minDomain.y > 0) {
          minDomain.y = `varus ${minDomain.y}`;
        } else {
          minDomain.y = `valgus ${Math.abs(minDomain.y)}`;
        }

        if (maxDomain.y > 0) {
          maxDomain.y = `varus ${maxDomain.y}`;
        } else {
          maxDomain.y = `valgus ${Math.abs(maxDomain.y)}`;
        }
      } else if (chartOptions.isLeftRight) {
        if (minDomain.y > 0) {
          minDomain.y = `R ${minDomain.y}`;
        } else {
          minDomain.y = `L ${Math.abs(minDomain.y)}`;
        }

        if (maxDomain.y > 0) {
          maxDomain.y = `R ${maxDomain.y}`;
        } else {
          maxDomain.y = `L ${Math.abs(maxDomain.y)}`;
        }
      }
    }

    const percentOrCm = chartOptions && chartOptions.isPercentage ? '%' : 'cm';

    return (
      <>
        <p className="key-points-title">{title}</p>
        <div className="key-points">
          <div className="domain-min-max">
            <p className="max">{`${maxDomain.y} ${percentOrCm}`}</p>
            <p className="min">{`${minDomain.y} ${percentOrCm}`}</p>
          </div>
          <Chart
            duration={duration}
            data={data}
            secondLineData={secondLineData}
            domain={chartDomain}
            options={chartOptions}
          />
          {calculatedPercentage ? (
            <div
              className="actual-time"
              style={{ width: `${calculatedPercentage}%` }}
            />
          ) : null}
          {this.renderKeyPoints()}
        </div>
      </>
    );
  }
}
