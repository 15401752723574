import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ExaminationInfoView from './examination-info-view';

export default class ExaminationInfo extends Component {
  static propTypes = {
    patient: PropTypes.shape(),
    examination: PropTypes.shape()
  };

  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({
      isModalOpen: true
    });
  }

  closeModal() {
    this.setState({
      isModalOpen: false
    });
  }

  formatExamination() {
    const { examination } = this.props;
    return {
      id: examination.id,
      date: moment(examination.date).format('YYYY-MM-DD HH:mm'),
      comment: examination.comment,
      forms: examination.forms ? JSON.parse(examination.forms) : []
    };
  }

  formatDefaultModalValues() {
    const { examination } = this.props;
    return {
      weight: examination.weight > 0 ? examination.weight : null,
      height: examination.height > 0 ? examination.height : null,
      date: moment(examination.date).format('YYYY-MM-DDTHH:mm') || 'n/a',
      //   dominantSide: (patient && patient.dominantSide) || '' || 'n/a',
      examType: examination.examType || 'n/a',
      comment: examination.comment || 'n/a',
      clReplacement: examination.clReplacement,
      clInjury: examination.clInjury,
      clSurgery: examination.clSurgery
    };
  }

  render() {
    const { patient } = this.props;
    const { isModalOpen } = this.state;
    const examination = this.formatExamination();
    const defaultModalValues = this.formatDefaultModalValues();

    return (
      <ExaminationInfoView
        examination={examination}
        patient={patient}
        isModalOpen={isModalOpen}
        openModal={this.openModal}
        closeModal={this.closeModal}
        defaultModalValues={defaultModalValues}
      />
    );
  }
}
