import env from '../../config';
import ApiHandlerService from '../../services/api-handler';

export default class EvaluationApi {
  static get(variables, token) {
    const examId = variables && variables.id;
    const url = `${env.API_BASE_URL}/evaluation?examination_id=${examId}`;
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Cache-Control': 'no-store'
      },
      method: 'GET'
    };

    return ApiHandlerService.fetch(url, config);
  }

  static update(variables, token) {
    const payload = variables && variables.payload;
    const examId = variables && variables.examinationId;

    if (!examId) throw Error('Id not specified');
    if (payload.id) {
      delete payload.id;
    }

    const url = `${env.API_BASE_URL}/evaluation?examination_id=${examId}`;
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Cache-Control': 'no-store'
      },
      body: JSON.stringify({
        payload
      }),
      method: 'PUT'
    };

    return ApiHandlerService.fetch(url, config);
  }
}
