// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./fonts/ClearSans-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./fonts/ClearSans-Light.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./fonts/ClearSans-Medium.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./fonts/ClearSans-Bold.ttf");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./fonts/ClearSans-Italic.ttf");
var ___CSS_LOADER_URL_IMPORT_5___ = require("./fonts/ClearSans-MediumItalic.ttf");
var ___CSS_LOADER_URL_IMPORT_6___ = require("./fonts/ClearSans-BoldItalic.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
// Module
exports.push([module.id, "@font-face {\n  font-family: 'Clear Sans';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  font-weight: normal;\n  font-style: normal; }\n\n@font-face {\n  font-family: 'Clear Sans';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  font-weight: lighter;\n  font-style: normal; }\n\n@font-face {\n  font-family: 'Clear Sans';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n  font-weight: bolder;\n  font-style: normal; }\n\n@font-face {\n  font-family: 'Clear Sans';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n  font-weight: bold;\n  font-style: normal; }\n\n@font-face {\n  font-family: 'Clear Sans';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");\n  font-weight: normal;\n  font-style: italic; }\n\n@font-face {\n  font-family: 'Clear Sans';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ");\n  font-weight: bolder;\n  font-style: italic; }\n\n@font-face {\n  font-family: 'Clear Sans';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ");\n  font-weight: bold;\n  font-style: italic; }\n\n.progress {\n  padding: 20px;\n  margin-bottom: 20px; }\n  .progress p {\n    line-height: 1.2; }\n  .progress .flex-container {\n    display: flex;\n    align-items: center; }\n    .progress .flex-container > * {\n      margin-right: 20px; }\n", ""]);
// Exports
module.exports = exports;
