/* eslint-disable react/jsx-wrap-multilines */
import React, { Component } from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import {
  TextField,
  FormHelperText,
  Radio,
  RadioGroup,
  FormLabel
} from '@material-ui/core';

import i18n from '../../i18n';

import validate from './validation';
import Statement from '../statement-pdf';

class BaseModal extends Component {
  static propTypes = {
    modalType: PropTypes.string,
    buttons: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        doReset: PropTypes.bool,
        doValidate: PropTypes.bool,
        ignoreFieldsValidation: PropTypes.arrayOf(PropTypes.string),
        submit: PropTypes.func.isRequired
      })
    ).isRequired,
    fields: PropTypes.objectOf(PropTypes.any).isRequired,
    isOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      fields: props.fields,
      modalType: props.modalType
    };
    this.theme = createMuiTheme({
      palette: {
        primary: {
          main: '#ffffff'
        },
        secondary: {
          main: '#d2232a'
        },
        error: {
          main: '#d2232a'
        },
        text: {
          primary: '#afafaf',
          secondary: '#afafaf'
        },
        background: {
          paper: '#23232b'
        },
        action: {
          selected: '#d2232a'
        }
      }
    });
  }

  // componentWillReceiveProps(nextProps) {
  //   this.setState({
  //     fields: nextProps.fields,
  //     modalType: nextProps.modalType
  //   });
  // }

  handleChecked(event) {
    const { state } = this;

    const fields = {
      ...state.fields,
      [event.target.name]: {
        ...state.fields[event.target.name],
        checked: event.target.checked,
        changed: true
      }
    };

    this.setState({ fields });
  }

  setPhoneStarterChar(event) {
    if (event.target.value === '') {
      const { state } = this;

      const fields = {
        ...state.fields,
        [event.target.name]: {
          ...state.fields[event.target.name],
          value: '+',
          changed: true
        }
      };

      this.setState({ fields });
    }
  }

  handleChange = event => {
    const { state } = this;
    let { value } = event.target;

    if (event.target.name === 'phone') {
      if (event.target.value[0] !== '+') value = `+${event.target.value}`;
    }

    const fields = {
      ...state.fields,
      [event.target.name]: {
        ...state.fields[event.target.name],
        value,
        changed: true
      }
    };

    this.setState({ fields });
  };

  submit = event => {
    event.preventDefault();

    const target = event.target || event.srcElement;
    const { buttons } = this.props;
    const button = buttons.find(b => b.name === target.name);

    if (
      button.doValidate === false ||
      this.validateForm(button.ignoreFieldsValidation)
    ) {
      // has custom event
      if (button && button.submit) {
        button.submit(this, event);
      }
      if (button.doReset !== false) {
        this.resetState();
      }
    }
  };

  deletePhoneStarterChar(event) {
    if (event.target.value === '+') {
      const { state } = this;

      const fields = {
        ...state.fields,
        [event.target.name]: {
          ...state.fields[event.target.name],
          value: '',
          changed: true
        }
      };

      this.setState({ fields });
    }
  }

  generateButtons() {
    const { buttons } = this.props;
    /* eslint-disable react/button-has-type */
    return buttons.map(button => (
      <button
        key={button.text}
        name={button.name}
        type={button.type}
        onClick={this.submit}
      >
        {button.text}
      </button>
    ));
    /* eslint-enable react/button-has-type */
  }

  generateFields() {
    const { fields, modalType } = this.state;
    return Object.keys(fields).map(fieldName => {
      const field = fields[fieldName];
      switch (field.type) {
        case 'date':
          return (
            <FormControl
              fullWidth
              style={{ margin: '10px 0px' }}
              key={field.name}
            >
              <TextField
                onChange={e => {
                  this.handleChange(e);
                }}
                value={field.value}
                required={field.required}
                fullWidth
                name={field.name}
                label={field.label}
                type="date"
                InputLabelProps={{
                  shrink: true
                }}
                error={validate(field).status}
                helperText={validate(field).message}
              />
            </FormControl>
          );

        case 'datetime-local':
          return (
            <FormControl
              fullWidth
              style={{ margin: '10px 0px' }}
              key={field.name}
            >
              <TextField
                onChange={e => {
                  this.handleChange(e);
                }}
                value={field.value}
                required={field.required}
                fullWidth
                name={field.name}
                label={field.label}
                type="datetime-local"
                InputLabelProps={{
                  shrink: true
                }}
                error={validate(field).status}
                helperText={validate(field).message}
              />
            </FormControl>
          );

        case 'textarea':
          return (
            <FormControl
              fullWidth
              style={{ margin: '10px 0px' }}
              key={field.name}
            >
              <TextField
                onChange={e => {
                  this.handleChange(e);
                }}
                value={field.value}
                required={field.required}
                InputLabelProps={{
                  shrink: true
                }}
                name={field.name}
                label={field.label}
                placeholder={field.placeholder}
                multiline
                margin="normal"
                error={validate(field).status}
                helperText={validate(field).message}
              />
            </FormControl>
          );

        case 'text':
          return (
            <FormControl
              fullWidth
              style={{ margin: '10px 0px' }}
              key={field.name}
            >
              <TextField
                onChange={e => {
                  this.handleChange(e);
                }}
                value={field.value}
                InputLabelProps={{
                  shrink: true,
                  style: { fontSize: '13pt' }
                }}
                onFocus={e => {
                  if (field.name === 'phone') {
                    return this.setPhoneStarterChar(e);
                  }
                  return null;
                }}
                onBlur={e => {
                  if (field.name === 'phone') {
                    return this.deletePhoneStarterChar(e);
                  }
                  return null;
                }}
                required={field.required}
                name={field.name}
                placeholder={field.placeholder}
                label={field.label}
                error={validate(field).status}
                helperText={validate(field).message}
              />
            </FormControl>
          );
        case 'select':
          return (
            <FormControl
              fullWidth
              style={{ margin: '10px 0px' }}
              key={field.name}
            >
              <TextField
                select
                onChange={e => {
                  this.handleChange(e);
                }}
                value={field.value}
                InputLabelProps={{
                  shrink: true,
                  style: { fontSize: '13pt' }
                }}
                required={field.required}
                name={field.name}
                placeholder={field.placeholder}
                label={field.label}
                error={validate(field).status}
                helperText={validate(field).message}
              >
                {field.values.map(value => (
                  <MenuItem
                    key={value}
                    value={value}
                    className="modal-menu-item"
                  >
                    {i18n.t(`${modalType}Info.${field.name}s.${value}`)}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          );
        case 'checkbox':
          return (
            <FormControl
              fullWidth
              style={{ margin: '10px 0px' }}
              key={field.name}
              error={validate(field).status}
            >
              <FormControlLabel
                label={field.label}
                control={
                  <Checkbox
                    name={field.name}
                    checked={field.checked}
                    onChange={e => this.handleChecked(e)}
                    required={field.required}
                  />
                }
              />
              <FormHelperText style={{ marginTop: -10, marginBottom: 20 }}>
                {validate(field).message}
              </FormHelperText>
            </FormControl>
          );
        case 'radio':
          return (
            <FormControl
              fullWidth
              style={{ margin: '10px 0px' }}
              key={field.name}
            >
              <FormLabel style={{ fontSize: 12 }}>{field.label}</FormLabel>
              <RadioGroup
                name={field.name}
                onChange={this.handleChange}
                row
                value={field.value}
              >
                {field.values.map(value => (
                  <FormControlLabel
                    key={value}
                    value={value}
                    control={<Radio />}
                    label={i18n.t(`${modalType}Info.${field.name}s.${value}`)}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          );
        default:
          return null;
      }
    });
  }

  resetState() {
    const { closeModal } = this.props;
    const { fields } = this.props;

    this.setState({
      fields
    });

    closeModal();
  }

  validateForm(ignoreElements) {
    const { state } = this;
    const fields = { ...state.fields };

    let notValid = false;

    if (fields) {
      Object.keys(fields).forEach(fieldName => {
        fields[fieldName].changed = true;
      });

      if (ignoreElements && ignoreElements.length > 0) {
        ignoreElements.forEach(element => {
          fields[element].changed = false;
        });
      }

      this.setState({ fields });

      Object.keys(fields).forEach(fieldName => {
        notValid = notValid || validate(fields[fieldName]).status;
      });
    }

    return !notValid;
  }

  renderDocument() {
    const { modalType, fields } = this.state;
    if (modalType === 'patient') {
      return <Statement fields={fields} />;
    }
    return null;
  }

  render() {
    const { isOpen } = this.props;
    return (
      <>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={isOpen}
          // onClose={() => this.resetState()}
          style={{ overflow: 'scroll' }}
        >
          <form
            className="modal-view box"
            autoComplete="off"
            style={{ outline: 'none' }}
          >
            <div className="document-to-print">{this.renderDocument()}</div>
            <div className="fields-to-hide">
              <MuiThemeProvider theme={this.theme}>
                {this.generateFields()}
              </MuiThemeProvider>
              <div className="actions">
                <button
                  className="btn-red"
                  type="button"
                  onClick={() => this.resetState()}
                >
                  {i18n.t('modalActions.cancel')}
                </button>
                {this.generateButtons()}
              </div>
            </div>
          </form>
        </Modal>
      </>
    );
  }
}

export default BaseModal;
