import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import Examination from './views/examination';
import Examinations from './views/examinations';
import Patient from './views/patient';
import Patients from './views/patients';
import Recent from './views/recent';
import Settings from './views/settings';

export default (
  <>
    <Switch>
      <Route exact path="/" render={() => <Redirect to="/recent" />} />
      <Route exact path="/recent" component={Recent} />
      <Route exact path="/patients" component={Patients} />
      <Route exact path="/patients/:id" component={Patient} />
      <Route exact path="/examinations" component={Examinations} />
      <Route exact path="/settings" component={Settings} />
      <Route path="/examinations/:id" component={Examination} />
      <Redirect to="/recent" />
    </Switch>
    <Switch>
      <Redirect
        exact
        from="/examinations/:id"
        to="/examinations/:id/overview"
      />
    </Switch>
  </>
);
