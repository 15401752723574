import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RecentView from './recent-view';

class Recent extends Component {
  static propTypes = {
    patients: PropTypes.objectOf(PropTypes.any),
    examinations: PropTypes.objectOf(PropTypes.any),
    getPatients: PropTypes.func.isRequired,
    getAllExaminations: PropTypes.func.isRequired
  };

  async componentDidMount() {
    const { getPatients, getAllExaminations } = this.props;
    getPatients();
    getAllExaminations();
  }

  render() {
    const { patients, examinations } = this.props;
    return <RecentView patients={patients} examinations={examinations} />;
  }
}

export default Recent;
