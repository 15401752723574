import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Menu from '../../components/menu';
import routes from '../../routes';
import MainView from './main-view';
import Login from '../login';
import Loader from '../../components/loader';

class Main extends Component {
  static propTypes = {
    user: PropTypes.shape({
      username: PropTypes.string.isRequired,
      token: PropTypes.string.isRequired
    }),
    isFetching: PropTypes.bool.isRequired,
    isMenuOpen: PropTypes.bool.isRequired,
    isFetchingLocalBackend: PropTypes.bool,
    checkLocalBackend: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      // This will check wheter local backend fetch was initiated
      isBackendChecked: false
    };
  }

  menuStyle(elem) {
    const { isMenuOpen } = this.props;
    if (isMenuOpen) {
      return '';
    }
    return `${elem}-closed-menu`;
  }

  isSignedIn() {
    const { user } = this.props;
    const { isFetching } = this.props;

    const AppElements = (
      <div className="app">
        <Menu />
        <div className={`main-view ${this.menuStyle('main-view')}`}>
          {routes}
        </div>
      </div>
    );

    const LoginElements = <Login />;
    const LoadingElements = <Loader />;

    if (isFetching) {
      return LoadingElements;
    }

    if (user && user.token) {
      return AppElements;
    }

    return LoginElements;
  }

  render() {
    const { isBackendChecked } = this.state;
    const { isFetchingLocalBackend, checkLocalBackend } = this.props;

    // If local backend's availability is not checked yet
    if (!isBackendChecked) {
      // –> check it
      checkLocalBackend();
      // Set state accordingly
      this.setState({ isBackendChecked: true });

      // Show loader while it is not checked
      return <Loader />;
    }

    // If local backend's availability is under check
    if (isFetchingLocalBackend) {
      // -> Show loader
      return <Loader />;
    }

    const elements = this.isSignedIn();
    return <MainView elements={elements} />;
  }
}

export default Main;
