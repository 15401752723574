/* eslint react/jsx-wrap-multilines: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../i18n';

import { getModalFields, ModalTypes } from '../modal-fields';
import EditExaminationView from './edit-examination-view';

class EditExamination extends Component {
  static propTypes = {
    defaults: PropTypes.shape({
      weight: PropTypes.number,
      height: PropTypes.number,
      date: PropTypes.string,
      dominantSide: PropTypes.string,
      examType: PropTypes.string,
      comment: PropTypes.string
    }).isRequired,
    patientId: PropTypes.number,
    patient: PropTypes.shape({}),
    examId: PropTypes.number.isRequired,
    isOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    updateRemoteExamination: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.fields = getModalFields(ModalTypes.Examination, props.defaults);
    this.buttons = this.setupButtons();
    this.state = {
      fields: null
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps !== prevState) {
      return {
        fields: getModalFields(ModalTypes.Examination, nextProps.defaults)
      };
    }
    return null;
  }

  // Deprecated since React 16.9.0
  // componentWillReceiveProps(nextProps) {
  //   this.fields = getModalFields(ModalTypes.Examination, nextProps.defaults);
  // }

  componentDidUpdate() {
    const { fields } = this.state;
    this.fields = fields;
  }

  setupButtons() {
    return [
      {
        name: 'editExamination',
        type: 'submit',
        text: i18n.t('modalActions.saveExamination'),
        doValidate: true, // optional (true by default)
        submit: that => {
          this.editExamination(that);
        }
      }
    ];
  }

  editExamination(that) {
    const { examId, patientId, updateRemoteExamination } = this.props;
    const { state } = that;
    const fields = { ...state.fields };

    const newExamination = {
      weight: parseFloat(fields.weight.value) || -1,
      height: parseFloat(fields.height.value) || -1,
      date: new Date(fields.date.value).toISOString(),
      // examType: fields.examType.value.toLowerCase(),
      // Bulk examType:
      // @TODO: Remove if necessary
      clInjury: fields.clInjury.value,
      clSurgery: fields.clSurgery.value,
      clReplacement: fields.clReplacement.value,
      examType: 'treadmill',
      comment: fields.comment.value,
      patientId
    };

    updateRemoteExamination(examId, newExamination);
  }

  render() {
    const { isOpen, closeModal } = this.props;
    return (
      <EditExaminationView
        modalType="examination"
        fields={this.fields}
        buttons={this.buttons}
        isOpen={isOpen}
        closeModal={closeModal}
      />
    );
  }
}

export default EditExamination;
