// reload .....
const config = {
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
  API_BASE_URL_INTERNAL:
    process.env.REACT_APP_API_BASE_URL_INTERNAL ||
    process.env.REACT_APP_API_BASE_URL,
  LOCAL_API_BASE_URL: process.env.REACT_APP_LOCAL_API_BASE_URL,
  IMAGES_BASE_URL: process.env.REACT_APP_IMAGES_BASE_URL,
  SAGA_BUFFER_SIZE: process.env.REACT_APP_SAGA_BUFFER_SIZE || 10,
  SQUAT_KEYPOINT_MARGIN: process.env.REACT_APP_SQUAT_KEYPOINT_MARGIN || 2000
};

export default config;
