import React, { Component } from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line
import NewPatient from '../../components/modals/new-patient';

import i18n from '../../i18n';

import '../../styles/patients.css';
import PatientsList from '../../components/patient-list';
import SearchPatients from '../../components/search/search-patients';

export default class PatientsView extends Component {
  static propTypes = {
    patients: PropTypes.shape({
      isFetching: PropTypes.bool.isRequired,
      patients: PropTypes.arrayOf(PropTypes.any).isRequired
    }),
    searchedPatients: PropTypes.arrayOf(PropTypes.any).isRequired,
    search: PropTypes.shape({
      show: PropTypes.string
    }),
    isModalOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired
  };

  renderSearchedPatients() {
    const { searchedPatients } = this.props;
    return (
      <PatientsList
        shouldSort
        patients={{ patients: searchedPatients, isFetching: false }}
      />
    );
  }

  render() {
    const { isModalOpen, closeModal, openModal, patients } = this.props;
    return (
      <div className="content-wrapper patients">
        <SearchPatients patients={patients.patients} />
        <NewPatient isOpen={isModalOpen} closeModal={closeModal} />
        <h1 className="route-title">{i18n.t('navigation.patients')}</h1>
        <div className="new-patient">
          <button className="btn-zoom" onClick={openModal} type="button">
            {i18n.t('patientActions.newPatient')}
          </button>
        </div>
        <PatientsList shouldSort patients={patients || {}} />
      </div>
    );
  }
}
