import React, { Component } from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line

import ExaminationsList from '../../components/examination-list';
import NewExamintion from '../../components/modals/new-examination';

import i18n from '../../i18n';

import EditPatient from '../../components/modals/edit-patient';
import Confirm from '../../components/modals/confirm/confirm';
import { DeleteIcon } from '../../components/icons';

import patientModel from '../../models';

export default class PatientView extends Component {
  static propTypes = {
    patient: patientModel.patient,
    isFetching: PropTypes.bool,
    isPatientModalOpen: PropTypes.bool.isRequired,
    isExamModalOpen: PropTypes.bool.isRequired,
    closePatientModal: PropTypes.func.isRequired,
    openPatientModal: PropTypes.func.isRequired,
    closeExamModal: PropTypes.func.isRequired,
    openExamModal: PropTypes.func.isRequired,
    isConfirmModalOpen: PropTypes.bool,
    defaultModalValuesExam: PropTypes.shape(),
    defaultModalValuesPatient: PropTypes.shape(),
    closeConfirmModal: PropTypes.func,
    openConfirmModal: PropTypes.func,
    history: PropTypes.shape(),
    deletePatient: PropTypes.func
  };

  renderPatientInfos() {
    const { patient } = this.props;
    const orderOfKeys = [
      'placeOfBirth',
      'dateOfBirth',
      'gender',
      'dominantSide',
      'mothersName',
      'address',
      'email',
      'phone'
    ];

    return orderOfKeys.map(key => {
      if (patient[key] && patient[key] !== '') {
        if (key.toString() === 'dateOfBirth') {
          return (
            <div className="patient-info" key={key}>
              <p>{i18n.t(`patientInfo.${key}`)}</p>
              <h1>{new Date(patient[key]).toLocaleDateString('hu-HU')}</h1>
            </div>
          );
        }
        if (key.toString() === 'gender') {
          return (
            <div className="patient-info" key={key}>
              <p>{i18n.t(`patientInfo.${key}`)}</p>
              <h1>{i18n.t(`patientInfo.genders.${patient[key]}`)}</h1>
            </div>
          );
        }
        if (key.toString() === 'dominantSide') {
          return (
            <div className="patient-info" key={key}>
              <p>{i18n.t(`patientInfo.${key}`)}</p>
              <h1>{i18n.t(`patientInfo.dominantSides.${patient[key]}`)}</h1>
            </div>
          );
        }
        return (
          <div className="patient-info" key={key}>
            <p>{i18n.t(`patientInfo.${key}`)}</p>
            <h1>{patient[key]}</h1>
          </div>
        );
      }
      return null;
    });
  }

  render() {
    const {
      patient,
      isFetching,
      isConfirmModalOpen,
      closeConfirmModal,
      openConfirmModal,
      history,
      deletePatient,
      isPatientModalOpen,
      closePatientModal,
      defaultModalValuesPatient,
      isExamModalOpen,
      closeExamModal,
      defaultModalValuesExam,
      openExamModal,
      openPatientModal
    } = this.props;

    const patientName = patient && `${patient.lastName} ${patient.firstName}`;
    const examinations = (patient && patient.examinations) || [];

    return (
      <div className="content-wrapper patient">
        <Confirm
          closeConfirm={closeConfirmModal}
          confirmFunction={() => {
            deletePatient(patient.id);
            history.push('/patients');
          }}
          confirmMessage="You are about to delete a patient. Proceed?"
          isConfirmOpen={isConfirmModalOpen}
        />
        <EditPatient
          isOpen={isPatientModalOpen}
          closeModal={closePatientModal}
          defaults={defaultModalValuesPatient || null}
          patientId={patient.id}
        />
        <h1 className="route-title">{i18n.t('navigation.patient')}</h1>

        <div className="head">
          <h1>{patientName}</h1>
          <button
            className="delete-button"
            type="button"
            onClick={() => openConfirmModal()}
          >
            <DeleteIcon className="icon-delete" />
            delete patient
          </button>
          <button
            style={{
              display: 'block'
            }}
            onClick={() => openPatientModal()}
            type="button"
          >
            <p>{i18n.t('patientActions.editPatient')}</p>
          </button>
        </div>

        <div className="patient-grid">
          <div className="patient-info-list">
            <h1>{i18n.t('patientInfo.details')}</h1>
            <div className="patient-info-box box">
              {this.renderPatientInfos()}
            </div>
          </div>

          <div className="examinations">
            <h1>{i18n.t('navigation.examinations')}</h1>
            <div className="new-examination">
              <NewExamintion
                patientId={patient.id}
                isOpen={isExamModalOpen}
                closeModal={closeExamModal}
                defaults={defaultModalValuesExam || null}
              />

              <button
                className="btn-zoom"
                onClick={() => openExamModal()}
                type="button"
              >
                {i18n.t('examinationActions.newExamination')}
              </button>
            </div>
            <ExaminationsList
              examinations={{ examinations, isFetching }}
              isDisplayPatientName={false}
            />
          </div>
        </div>
      </div>
    );
  }
}
