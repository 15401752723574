/**
 * Not a real reducer, only types are defined. The reducer logic
 * is split apart between other real reducers.
 */

const GET_PROGRESS_SUCCESS = 'GET_PROGRESS_SUCCESS';
const GET_PROGRESS_FAILED = 'GET_PROGRESS_FAILED';
const GET_PROGRESS_INSTANT_SUCCESS = 'GET_PROGRESS_INSTANT_SUCCESS';
const GET_PROGRESS_INSTANT_FAILED = 'GET_PROGRESS_INSTANT_SUCCESS';
const KILL_PROGRESSES_SUCCESS = 'KILL_PROGRESSES_SUCCESS';

/* eslint-disable import/prefer-default-export */
export const Types = {
  GET_PROGRESS_INSTANT_SUCCESS,
  GET_PROGRESS_INSTANT_FAILED,
  GET_PROGRESS_SUCCESS,
  GET_PROGRESS_FAILED,
  KILL_PROGRESSES_SUCCESS
};
/* eslint-enable import/prefer-default-export */
