import React, { Component } from 'react';
import PropTypes from 'prop-types';

import i18n from '../../i18n';

import ExaminationView from './examination-view';
import Loader from '../../components/loader';
import NotFound from '../../components/not-found';

class Examination extends Component {
  static propTypes = {
    history: PropTypes.shape(),
    deleteExamination: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string
    }),
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string
      })
    }).isRequired,
    app: PropTypes.shape({
      isLocalBackendAvailable: PropTypes.bool,
      pairedId: PropTypes.string
    }),
    examinations: PropTypes.shape({
      isFetching: PropTypes.bool.isRequired,
      examinations: PropTypes.arrayOf(PropTypes.any).isRequired,
      errors: PropTypes.arrayOf(PropTypes.any)
    }),
    patients: PropTypes.shape({
      isFetching: PropTypes.bool.isRequired,
      patients: PropTypes.arrayOf(PropTypes.any).isRequired
    }),
    progress: PropTypes.shape({}),
    getExamination: PropTypes.func.isRequired,
    getPatient: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      isConfirmOpen: false
    };

    this.openConfirm = this.openConfirm.bind(this);
    this.closeConfirm = this.closeConfirm.bind(this);
  }

  componentDidMount() {
    const {
      getExamination,
      match
      // flushLocalFiles
    } = this.props;

    const examId = match && match.params && match.params.id;
    // const examination = this.getSelectedExam();

    // flushLocalFiles();
    getExamination(examId);

    // if (!examination || (examination.hasData && !examination.results)) {
    // }
  }

  getSelectedExam() {
    const { match, examinations } = this.props;
    const examId = match && match.params && match.params.id;
    return (
      examinations &&
      examinations.examinations &&
      examinations.examinations.find(e => e.id.toString() === examId.toString())
    );
  }

  getPatientOfExam() {
    const { patients, getPatient } = this.props;
    const examination = this.getSelectedExam();

    if (!examination || !examination.patientId) return null;

    const patient =
      patients &&
      patients.patients &&
      patients.patients.find(
        p => p.id.toString() === examination.patientId.toString()
      );

    if (!patient && !patients.isFetching) {
      getPatient(examination.patientId);
    }

    return patient;
  }

  openConfirm() {
    this.setState({ isConfirmOpen: true });
  }

  closeConfirm() {
    this.setState({ isConfirmOpen: false });
  }

  render() {
    // const { examinations, patients, app, getLocalExamination } = this.props;
    const {
      examinations,
      patients,
      app,
      deleteExamination,
      history
    } = this.props;
    const { isConfirmOpen } = this.state;

    const isExaminationsFetching = examinations && examinations.isFetching;
    const errors = examinations && examinations.errors;

    const { isLocalBackendAvailable } = app;

    if (isExaminationsFetching) {
      return <Loader message={i18n.t(`loadingInfo.loadingExamination`)} />;
    }

    if (errors) {
      const errorMessage = errors && errors.message;
      return (
        <NotFound
          message={i18n.t(`backendResponse.${errorMessage}`)}
          backTo="/examinations"
        />
      );
    }

    const examination = this.getSelectedExam();

    if (isExaminationsFetching || !examination) {
      return <Loader message={i18n.t(`loadingInfo.loadingExamination`)} />;
    }

    // if(!examination.status || examination.status === 'completed'){
    //   getLocalExamination(examination.id)
    // }

    const isPatientsFetching = patients && patients.isFetching;
    const patient = this.getPatientOfExam();

    if (isPatientsFetching || !patient) {
      return <Loader message={i18n.t(`loadingInfo.loadingExamination`)} />;
    }

    return (
      <ExaminationView
        patient={{ patient, isFetching: isPatientsFetching }}
        examination={{ examination, isFetching: isExaminationsFetching }}
        isLocalBackendAvailable={isLocalBackendAvailable}
        deleteExamination={deleteExamination}
        isConfirmOpen={isConfirmOpen}
        openConfirm={this.openConfirm}
        closeConfirm={this.closeConfirm}
        history={history}
      />
    );
  }
}

export default Examination;
