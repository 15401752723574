import { connect } from 'react-redux';
import Overview from './overview';
import {
  getLocalExamination,
  getExamination
} from '../../../../reducers/request';

const mapStateToProps = (state, props) => {
  const { app } = state;
  const { isLocalBackendAvailable } = app;
  const localExamination = state.examination;
  const { examination } = props;

  // variable which defines wheter local loop is necessarry
  let shouldLoopLocal = false;

  // filenames and properties for each file type
  let thermalImages = [];
  let kinectVideo = [];
  let carvData = [];

  // status should be refreshed?
  if (
    isLocalBackendAvailable &&
    localExamination &&
    localExamination.status &&
    (localExamination.status === 'running' ||
      localExamination.status === 'pending')
  ) {
    shouldLoopLocal = true;
  }

  const filesFromExam =
    (examination && examination.files && JSON.parse(examination.files)) || {};

  if (filesFromExam) {
    thermalImages = filesFromExam.thermal || [];
    kinectVideo = filesFromExam.kinect || [];
    carvData = filesFromExam.carv || [];
  }

  return {
    examination,
    thermalImages,
    carvData,
    kinectVideo,
    shouldLoopLocal,
    examinationId: examination.id.toString(),
    status:
      (examination.status === 'confirmed' ||
        examination.status === 'completed') &&
      ((localExamination && localExamination.status !== 'pending') ||
        !localExamination)
        ? examination.status
        : localExamination && localExamination.status
  };
};

export default connect(mapStateToProps, {
  getLocalExamination,
  getExamination
})(Overview);
