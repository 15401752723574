// import env from "../../config";
import PatientsApi from './patients';
import ExaminationsApi from './examinations';
import EvaluationApi from './evaluation';
import PrintApi from './print';
import ProgressApi from './progress';
import FilesApi from './files';
import SettingsApi from './settings';

export default class Api {
  static request = (requestId, variables, token) => {
    switch (requestId) {
      case 'addPatient':
        return PatientsApi.add(variables, token);
      case 'getPatient':
        return PatientsApi.get(variables, token);
      case 'getPatients':
        return PatientsApi.list(variables, token);
      case 'updatePatient':
        return PatientsApi.update(variables, token);
      case 'deletePatient':
        return PatientsApi.delete(variables, token);
      case 'addExamination':
        return ExaminationsApi.add(variables, token);
      case 'updateExamination':
        return ExaminationsApi.update(variables, token);
      case 'updateRemoteExamination':
        return ExaminationsApi.updateRemote(variables, token);
      case 'deleteExamination':
        return ExaminationsApi.delete(variables, token);
      case 'getAllExaminations':
        return ExaminationsApi.list(variables, token);
      case 'getExamination':
        return ExaminationsApi.get(variables, token);
      case 'getExaminations':
        return ExaminationsApi.getAllByPatient(variables, token);
      case 'getLastExamination':
        return ExaminationsApi.getAllByPatient(variables, token);
      case 'startExamination':
        return ExaminationsApi.start(variables, token);
      case 'stopExamination':
        return ExaminationsApi.stop(variables, token);
      case 'pairDevice':
        return ExaminationsApi.pairDevice(variables, token);
      case 'resendData':
        return ExaminationsApi.resendData(variables, token);
      case 'cleanupExamination':
        return ExaminationsApi.cleanupExamination(variables, token);
      case 'getLocalExamination':
        return ExaminationsApi.getLocal(variables, token);
      case 'refreshThermal':
        return ExaminationsApi.refreshThermal(variables, token);
      case 'refreshCarv':
        return ExaminationsApi.refreshCarv(variables, token);
      case 'killPendingProgresses':
        return ProgressApi.kill();
      case 'print':
        return PrintApi.print(variables, token);
      case 'getSettings':
        return SettingsApi.get(variables, token);
      case 'updateSettings':
        return SettingsApi.update(variables, token);
      case 'checkLocalBackend':
        return SettingsApi.checkLocalBackend(variables, token);
      case 'download':
        return FilesApi.download(variables, token);
      case 'getEvaluation':
        return EvaluationApi.get(variables, token);
      case 'updateEvaluation':
        return EvaluationApi.update(variables, token);
      default:
    }
    return null;
  };
}
