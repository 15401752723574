/* globals */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TabNavigationView from './tab-navigation-view';

export default class TabNavigation extends Component {
  static propTypes = {
    location: PropTypes.shape(),
    examination: PropTypes.shape()
  };

  possibleTabs = [
    { position: 1, name: 'Overview', pathname: 'overview' },
    { position: 2, name: 'Evaluation', pathname: 'evaluation' }
    //   { position: 3, name: 'Report', pathname: 'report' }
  ];

  getActualTab() {
    const { location, examination } = this.props;
    let updatedTabs = [...this.possibleTabs];
    let actualTab = null;

    updatedTabs.forEach(tab => {
      if (location.pathname.includes(tab.pathname)) {
        actualTab = tab;
      }
    });

    if (!examination.status) {
      updatedTabs = updatedTabs.filter(tab => tab.position === 1);
    }

    return { actualTab, possibleTabs: updatedTabs };
  }

  render() {
    const { actualTab, possibleTabs } = this.getActualTab();
    return (
      <TabNavigationView actualTab={actualTab} possibleTabs={possibleTabs} />
    );
  }
}
