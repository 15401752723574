import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LoadingIcon } from '../icons';
import '../../styles/loader.css';

class Loader extends Component {
  static propTypes = {
    isWrapped: PropTypes.bool, // Defines if the loader has to be wrapped in a div or can stand alone
    message: PropTypes.string
  };

  isThereMessage() {
    const { message } = this.props;
    if (message) {
      return <p className="loader-message">{message}</p>;
    }
    return null;
  }

  render() {
    const { isWrapped } = this.props;

    if (isWrapped) {
      return (
        <div style={{ position: 'relative', width: '100%', height: '200px' }}>
          <div className="indicator">
            <LoadingIcon className="icon-no-margin" />
          </div>
          {this.isThereMessage()}
        </div>
      );
    }
    return (
      <>
        <div className="indicator">
          <LoadingIcon className="icon-no-margin" />
        </div>
        {this.isThereMessage()}
      </>
    );
  }
}

export default Loader;
