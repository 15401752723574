import { REQUEST, REHYDRATE } from '../request';
import { Types as ExaminationTypes } from '../examinations';

const ADD_PATIENT_SUCCESS = 'ADD_PATIENT_SUCCESS';
const GET_PATIENT_SUCCESS = 'GET_PATIENT_SUCCESS';
const GET_PATIENTS_SUCCESS = 'GET_PATIENTS_SUCCESS';
const UPDATE_PATIENT_SUCCESS = 'UPDATE_PATIENT_SUCCESS';
const GET_EXAMINATIONS_SUCCESS = 'GET_EXAMINATIONS_SUCCESS';
const GET_LAST_EXAMINATION_SUCCESS = 'GET_LAST_EXAMINATION_SUCCESS';
const ADD_PATIENT_FAILED = 'ADD_PATIENT_FAILED';
const GET_PATIENT_FAILED = 'GET_PATIENT_FAILED';
const GET_PATIENTS_FAILED = 'GET_PATIENTS_FAILED';
const UPDATE_PATIENT_FAILED = 'UPDATE_PATIENT_FAILED';
const GET_EXAMINATIONS_FAILED = 'GET_EXAMINATIONS_FAILED';
const GET_LAST_EXAMINATION_FAILED = 'GET_LAST_EXAMINATION_FAILED';
const DELETE_PATIENT_SUCCESS = 'DELETE_PATIENT_SUCCESS';
const DELETE_PATIENT_FAILED = 'DELETE_PATIENT_FAILED';

export const Types = {
  ADD_PATIENT_SUCCESS,
  GET_EXAMINATIONS_SUCCESS,
  GET_LAST_EXAMINATION_SUCCESS,
  GET_PATIENT_SUCCESS,
  GET_PATIENTS_SUCCESS,
  UPDATE_PATIENT_SUCCESS,
  ADD_PATIENT_FAILED,
  GET_EXAMINATIONS_FAILED,
  GET_LAST_EXAMINATION_FAILED,
  GET_PATIENT_FAILED,
  GET_PATIENTS_FAILED,
  UPDATE_PATIENT_FAILED,
  REQUEST,
  DELETE_PATIENT_FAILED,
  DELETE_PATIENT_SUCCESS
};

export const initialState = {
  isFetching: false,
  patients: [],
  errors: null
};

const patients = (state = initialState, action) => {
  if (!action) return state;

  let id;
  let found;
  let patientsUpdated;

  switch (action.type) {
    case REQUEST:
      return {
        ...state,
        isFetching:
          (action.payload && /PATIENT/.test(action.payload.action)) ||
          state.isFetching,
        errors:
          action.payload && /PATIENT/.test(action.payload.action)
            ? null
            : state.errors
      };

    case ADD_PATIENT_SUCCESS:
    case GET_PATIENT_SUCCESS:
    case UPDATE_PATIENT_SUCCESS:
      id =
        action &&
        action.payload &&
        action.payload.data &&
        action.payload.data.id;
      if (!id) return state;

      if (!action.payload.data.patient) return state;

      found = false;
      patientsUpdated = {
        ...state,
        isFetching: false,
        patients: (state.patients || []).map(itm => {
          if (!itm || (itm.id && itm.id.toString()) !== id.toString()) {
            // this isn't the item we care about - keep it as-is
            return itm;
          }

          // otherwise, this is the item we are looking for => return the retrieved value
          found = true;

          return action.payload.data.patient;
        }),
        errors: null
      };

      if (!found) patientsUpdated.patients.unshift(action.payload.data.patient);

      return patientsUpdated;

    case GET_PATIENTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        patients:
          (action.payload &&
            action.payload.data &&
            action.payload.data.patients) ||
          [],
        errors: null
      };

    case GET_LAST_EXAMINATION_SUCCESS:
    case GET_EXAMINATIONS_SUCCESS:
    case ExaminationTypes.ADD_EXAMINATION_SUCCESS:
      if (action && action.payload && action.payload.data) {
        id = action.payload.data.patientId;

        if (action.type === ExaminationTypes.ADD_EXAMINATION_SUCCESS) {
          id =
            action.payload.data.examination &&
            action.payload.data.examination.patientId;
        }
      }
      if (!id) return state;

      if (!action.payload.data.examinations && !action.payload.data.examination)
        return state;

      return {
        ...state,
        isFetching: false,
        patients: (state.patients || []).map(itm => {
          if (!itm || (itm.id && itm.id.toString()) !== id.toString()) {
            // this isn't the item we care about - keep it as-is
            return itm;
          }

          // otherwise, this is the one we want - return an updated value
          let examinations;
          if (action.type === ExaminationTypes.ADD_EXAMINATION_SUCCESS) {
            examinations = [
              action.payload.data.examination,
              ...itm.examinations
            ];
          } else {
            examinations = [...action.payload.data.examinations];

            // sort descending
            examinations.sort(
              (a, b) => Date.parse(a.date) - Date.parse(b.date)
            );

            if (action.type === GET_LAST_EXAMINATION_SUCCESS) {
              return {
                ...itm,
                lastExamination: examinations[0],
                examinations
              };
            }
          }
          return {
            ...itm,
            examinations
          };
        }),
        errors: null
      };

    case DELETE_PATIENT_SUCCESS:
      id =
        action &&
        action.payload &&
        action.payload.data &&
        action.payload.data.id;
      if (!id) return state;

      return {
        ...state,
        patients: state.patients.filter(patient => patient.id !== id),
        isFetching: false,
        errors: null
      };

    case ADD_PATIENT_FAILED:
    case GET_PATIENT_FAILED:
    case GET_PATIENTS_FAILED:
    case UPDATE_PATIENT_FAILED:
    case DELETE_PATIENT_FAILED:
    case GET_LAST_EXAMINATION_FAILED:
      return {
        ...state,
        isFetching: false,
        errors: action.payload
      };

    case REHYDRATE:
      return {
        ...((action.payload && action.payload.patients) || initialState),
        isFetching: false
      };

    case 'FLUSH':
      return initialState;
    default:
      return state;
  }
};

export const Actions = {};

export default patients;
