import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ThermalImages from './file-boxes/thermal-images';
import KinectVideo from './file-boxes/kinect-video';
import CarvData from './file-boxes/carv-data';

class OverviewView extends Component {
  static propTypes = {
    thermalImages: PropTypes.arrayOf(PropTypes.shape()),
    kinectVideo: PropTypes.arrayOf(PropTypes.shape()),
    carvData: PropTypes.arrayOf(PropTypes.shape()),
    examinationId: PropTypes.string,
    status: PropTypes.string
  };

  render() {
    const {
      thermalImages,
      kinectVideo,
      carvData,
      examinationId,
      status
    } = this.props;

    /**
     * Render order inside the grid:
     * 1. Thermal images box
     * 2. Kinect video box
     * 3. Carv data box
     */
    return (
      <div className="overview">
        <div className="grid-3">
          <ThermalImages
            thermalImages={thermalImages}
            examinationId={examinationId}
            fromServer
            enableResendData={status === 'completed'}
            status={status}
          />
          <KinectVideo
            kinectVideo={kinectVideo}
            examinationId={examinationId}
            fromServer
            status={status}
          />
          <CarvData
            carvData={carvData}
            examinationId={examinationId}
            fromServer
            enableResendData={status === 'completed'}
            status={status}
          />
        </div>
      </div>
    );
  }
}

export default OverviewView;
