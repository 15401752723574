import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  VictoryLine,
  VictoryAxis,
  VictoryChart,
  VictoryVoronoiContainer,
  VictoryCursorContainer,
  VictoryLabel,
  VictoryTooltip
} from 'victory';

export default class Chart extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape()),
    secondLineData: PropTypes.arrayOf(PropTypes.shape()),
    domain: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.number)
    ]),
    duration: PropTypes.number
  };

  // Prevent re-render if data and duration is the same as before
  shouldComponentUpdate(nextProps) {
    const { data, duration } = this.props;
    return (
      JSON.stringify(data) !== JSON.stringify(nextProps.data) ||
      duration !== nextProps.duration
    );
  }

  render() {
    const { duration, data, secondLineData, domain } = this.props;

    // Filter negative time data from display (coming from Kinect)
    const filteredData = data && data.filter(d => d.x >= 0);
    const filteredSecondLineData =
      secondLineData && secondLineData.filter(d => d.x >= 0);

    const tickValues = [];
    for (let i = -100; i < 100; i += 10) {
      tickValues.push(i);
    }

    return (
      <VictoryChart
        width={960}
        height={210}
        padding={{ top: 0, left: 0, right: 0, bottom: 0 }}
        containerComponent={
          secondLineData ? (
            <VictoryCursorContainer
              cursorLabel={d => d.y.toFixed(2)}
              cursorLabelComponent={<VictoryLabel className="text-white" />}
            />
          ) : (
            <VictoryVoronoiContainer />
          )
        }
      >
        <VictoryAxis
          dependentAxis
          crossAxis={false}
          tickValues={tickValues}
          style={{
            grid: {
              strokeWidth: 2,
              stroke: tick => (tick === 0 ? '#000' : '#23232b')
            }
          }}
          domain={
            domain[0] === (0 || null) && domain[1] === (0 || null)
              ? null
              : { y: domain }
          }
        />
        <VictoryLine
          samples={filteredData.length / 2}
          minDomain={{ x: 0 }}
          maxDomain={{ x: duration }}
          width={960}
          height={140}
          padding={{ top: 0, left: 0, right: 0, bottom: 0 }}
          style={{
            data: { stroke: '#d2232a' }
          }}
          data={filteredData}
          labels={d => d.y && d.y.toFixed(1)}
          labelComponent={<VictoryTooltip style={{ fontSize: 10 }} />}
        />
        {secondLineData ? (
          <VictoryLine
            samples={filteredSecondLineData.length / 2}
            minDomain={{ x: 0 }}
            maxDomain={{ x: duration }}
            width={960}
            height={140}
            padding={{ top: 0, left: 0, right: 0, bottom: 0 }}
            style={{
              data: { stroke: '#ffa600' }
            }}
            data={filteredSecondLineData}
            labels={d => d.y && d.y.toFixed(2)}
            labelComponent={<VictoryTooltip style={{ fontSize: 10 }} />}
          />
        ) : null}
      </VictoryChart>
    );
  }
}
