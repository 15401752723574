import { connect } from 'react-redux';
import { checkLocalBackend } from '../../reducers/request';
import Main from './main';

const mapStateToProps = ({ auth: { user, isFetching }, app }) => {
  return {
    user,
    isMenuOpen: app.isMenuOpen,
    isFetching,
    isFetchingLocalBackend: app.isFetching
  };
};

export default connect(mapStateToProps, { checkLocalBackend })(Main);
