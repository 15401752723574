/* globals window */

import { takeLatest, fork, call } from 'redux-saga/effects';
import { Types } from '../../reducers/patients';

export function* added(response) {
  if (response && response.payload && response.payload.data) {
    const { id } = response.payload.data;
    yield call(redirectToPatient, id);
  }
}

export function redirectToPatient(id) {
  window.location.href = `${window.location.href}/${id}`;
}

function* patientSaga() {
  yield fork(takeLatest, Types.ADD_PATIENT_SUCCESS, added);
}

export default patientSaga;
