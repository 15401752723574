import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import PatientInfoView from './patient-info-view';
import i18n from '../../../i18n';

export default class PatientInfo extends Component {
  static propTypes = {
    patient: PropTypes.shape(),
    examination: PropTypes.shape()
  };

  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({
      isModalOpen: true
    });
  }

  closeModal() {
    this.setState({
      isModalOpen: false
    });
  }

  formatPatient() {
    const { patient, examination } = this.props;
    return {
      id: patient.id,
      name: `${patient.lastName} ${patient.firstName}`,
      age: moment().diff(moment(patient.dateOfBirth), 'years'),
      weight: examination.weight > 0 ? `${examination.weight} kg` : null,
      height: examination.height > 0 ? `${examination.height} cm` : null,
      gender: i18n.t(`patientInfo.genders.${patient.gender}`),
      dominantSide: i18n.t(`patientInfo.dominantSides.${patient.dominantSide}`)
    };
  }

  formatDefaultModalValues() {
    const { patient } = this.props;
    return {
      firstName: patient && patient.firstName,
      lastName: patient && patient.lastName,
      dateOfBirth: patient && moment(patient.dateOfBirth).format('YYYY-MM-DD'),
      placeOfBirth: patient && patient.placeOfBirth,
      mothersName: patient && patient.mothersName,
      address: (patient && patient.address) || '',
      email: (patient && patient.email) || '',
      phone: (patient && patient.phone) || '',
      gender: patient && patient.gender,
      dominantSide: patient && patient.dominantSide
    };
  }

  render() {
    const { isModalOpen } = this.state;
    const patient = this.formatPatient();
    const defaultModalValues = this.formatDefaultModalValues();

    return (
      <PatientInfoView
        patient={patient}
        isModalOpen={isModalOpen}
        openModal={this.openModal}
        closeModal={this.closeModal}
        defaultModalValues={defaultModalValues}
      />
    );
  }
}
