/* eslint-disable react/jsx-wrap-multilines */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import '../../styles/login.css';

import i18n from '../../i18n';

class LoginView extends Component {
  static propTypes = {
    handleLogin: PropTypes.func,
    handleChange: PropTypes.func,
    isKeepMeLoggedIn: PropTypes.bool,
    errors: PropTypes.arrayOf(PropTypes.any)
  };

  static showErrors(errors) {
    /* eslint-disable react/no-array-index-key */
    return errors.map((err, i) => (
      <p key={`login_error_msg_${i}`} className="error-msg">
        {err}
      </p>
    ));
    /* eslint-enable react/no-array-index-key */
  }

  constructor(props) {
    super(props);
    this.theme = createMuiTheme({
      palette: {
        primary: {
          main: '#ffffff'
        },
        secondary: {
          main: '#d2232a'
        },
        error: {
          main: '#d2232a'
        },
        text: {
          primary: '#afafaf',
          secondary: '#afafaf'
        },
        background: {
          paper: '#23232b'
        },
        action: {
          selected: '#d2232a'
        }
      }
    });
  }

  render() {
    const { handleChange, isKeepMeLoggedIn } = this.props;
    const { props } = this;

    return (
      <div className="Login">
        <div className="content">
          <div className="login-header">
            <img src="/assets/images/logo_red.png" alt="logo" />
            <p>{i18n.t('login.loginText')}</p>
          </div>
          <form onSubmit={props.handleLogin}>
            <div className="input-field">
              <input
                type="text"
                name="username"
                placeholder={i18n.t('login.username')}
              />
            </div>
            <div className="input-field">
              <input
                type="password"
                name="password"
                placeholder={i18n.t('login.password')}
              />
            </div>
            <div className="checkbox-field">
              <MuiThemeProvider theme={this.theme}>
                <FormControlLabel
                  style={{
                    marginTop: 10,
                    marginBottom: 5,
                    padding: 0
                  }}
                  label={i18n.t('login.keepMeLoggedIn')}
                  control={
                    <Checkbox
                      style={{
                        marginTop: 0,
                        padding: 0,
                        marginRight: 10
                      }}
                      name="isKeepMeLoggedIn"
                      checked={isKeepMeLoggedIn}
                      onChange={e => handleChange(e)}
                    />
                  }
                />
              </MuiThemeProvider>
            </div>
            <div className="submit-field">
              <button type="submit">{i18n.t('login.loginButton')}</button>
            </div>
          </form>
          {LoginView.showErrors(props.errors)}
        </div>
      </div>
    );
  }
}
export default LoginView;
