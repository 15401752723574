import { connect } from 'react-redux';
import Settings from './settings';
import {
  getSettings,
  updateSettings,
  killPendingProgresses
} from '../../reducers/request';

const mapStateToProps = state => {
  const { settings, app } = state;

  return {
    isFetching: settings.isFetching,
    settings: settings.settings,
    carvSessions: settings.carvSessions,
    isLocalBackendAvailable: app.isLocalBackendAvailable
  };
};

export default connect(mapStateToProps, {
  getSettings,
  updateSettings,
  killPendingProgresses
})(Settings);
