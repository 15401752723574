import { all, call } from 'redux-saga/effects';

import authSaga from './auth';
import notificationSaga from './notification';
import patientSaga from './patient';
import examinationSaga from './examination';
import requestSaga from './request';
import settingsSaga from './settings';
import filesSaga from './files';
import progressSaga from './progress';

function* rootSaga() {
  yield all([
    call(authSaga),
    call(notificationSaga),
    call(patientSaga),
    call(examinationSaga),
    call(requestSaga),
    call(settingsSaga),
    call(filesSaga),
    call(progressSaga)
  ]);
}

export default rootSaga;
