import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';
import EditPatient from '../../modals/edit-patient';

export default class PatientInfoView extends Component {
  static propTypes = {
    patient: PropTypes.shape(),
    closeModal: PropTypes.func,
    openModal: PropTypes.func,
    isModalOpen: PropTypes.bool,
    defaultModalValues: PropTypes.shape()
  };

  render() {
    const {
      patient,
      closeModal,
      openModal,
      isModalOpen,
      defaultModalValues
    } = this.props;
    return (
      <>
        {isModalOpen ? (
          <EditPatient
            isOpen={isModalOpen}
            closeModal={closeModal}
            defaults={defaultModalValues || null}
            patientId={patient.id}
          />
        ) : null}
        <div className="patient-info">
          <div className="title-box">
            <h1 className="title title-inline">
              {i18n.t('examinationInfo.patient')}
            </h1>
            <button
              type="button"
              className="btn-small btn-inline"
              onClick={() => openModal()}
            >
              {i18n.t('examinationActions.editPatient')}
            </button>
          </div>
          <div className="box examination-info-list">
            <div className="examination-info-grid">
              <Link to={`/patients/${patient.id}`}>
                <div className="elem elem-hoverable">
                  <h1>{i18n.t('examinationInfo.patientName')}</h1>
                  <p>{patient.name}</p>
                </div>
              </Link>
              <div className="elem">
                <h1>{i18n.t('examinationInfo.age')}</h1>
                <p>{patient.age}</p>
              </div>
              {patient.weight ? (
                <div className="elem">
                  <h1>{i18n.t('examinationInfo.weight')}</h1>
                  <p>{patient.weight}</p>
                </div>
              ) : null}
              {patient.height ? (
                <div className="elem">
                  <h1>{i18n.t('examinationInfo.height')}</h1>
                  <p>{patient.height}</p>
                </div>
              ) : null}
              <div className="elem">
                <h1>{i18n.t(`patientInfo.gender`)}</h1>
                <p>{patient.gender}</p>
              </div>
              <div className="elem">
                <h1>{i18n.t('patientInfo.dominantSide')}</h1>
                <p>{patient.dominantSide}</p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
