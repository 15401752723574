export const jointTypes = {
  SpineBase: 0,
  SpineMid: 1,
  Neck: 2,
  Head: 3,
  ShoulderLeft: 4,
  ElbowLeft: 5,
  WristLeft: 6,
  HandLeft: 7,
  ShoulderRight: 8,
  ElbowRight: 9,
  WristRight: 10,
  HandRight: 11,
  HipLeft: 12,
  KneeLeft: 13,
  AnkleLeft: 14,
  FootLeft: 15,
  HipRight: 16,
  KneeRight: 17,
  AnkleRight: 18,
  FootRight: 19,
  SpineShoulder: 20,
  HandTipLeft: 21,
  ThumbLeft: 22,
  HandTipRight: 23,
  ThumbRight: 24
};

export const jointTypesAzure = {
  SpineBase: 0,
  SpineMid: 1,
  Neck: 2,
  Head: 3,
  ShoulderLeft: 4,
  ElbowLeft: 5,
  WristLeft: 6,
  ShoulderRight: 8,
  ElbowRight: 9,
  WristRight: 10,
  HipLeft: 12,
  KneeLeft: 13,
  AnkleLeft: 14,
  FootLeft: 15,
  HipRight: 16,
  KneeRight: 17,
  AnkleRight: 18,
  FootRight: 19,
  SpineShoulder: 2
};

export const azureJointNames = {
  SpineBase: 'pelvis',
  SpineMid: 'spine_naval',
  Neck: 'neck',
  Head: 'head',
  ShoulderLeft: 'shoulder_left',
  ElbowLeft: 'elbow_left',
  WristLeft: 'wrist_left',
  ShoulderRight: 'shoulder_right',
  ElbowRight: 'elbow_right',
  WristRight: 'wrist_right',
  HipLeft: 'hip_left',
  KneeLeft: 'knee_left',
  AnkleLeft: 'ankle_left',
  FootLeft: 'foot_left',
  HipRight: 'hip_right',
  KneeRight: 'knee_right',
  AnkleRight: 'ankle_right',
  FootRight: 'foot_right'
};

export const joints = [
  { left: jointTypes.Head, right: jointTypes.Neck },
  { left: jointTypes.Neck, right: jointTypes.SpineShoulder },
  { left: jointTypes.SpineShoulder, right: jointTypes.SpineMid },
  { left: jointTypes.SpineMid, right: jointTypes.SpineBase },
  { left: jointTypes.SpineShoulder, right: jointTypes.ShoulderRight },
  { left: jointTypes.SpineShoulder, right: jointTypes.ShoulderLeft },
  { left: jointTypes.SpineBase, right: jointTypes.HipRight },
  { left: jointTypes.SpineBase, right: jointTypes.HipLeft },

  { left: jointTypes.ShoulderRight, right: jointTypes.ElbowRight },
  { left: jointTypes.ElbowRight, right: jointTypes.WristRight },
  { left: jointTypes.WristRight, right: jointTypes.HandRight },
  { left: jointTypes.HandRight, right: jointTypes.HandTipRight },
  { left: jointTypes.WristRight, right: jointTypes.ThumbRight },

  { left: jointTypes.ShoulderLeft, right: jointTypes.ElbowLeft },
  { left: jointTypes.ElbowLeft, right: jointTypes.WristLeft },
  { left: jointTypes.WristLeft, right: jointTypes.HandLeft },
  { left: jointTypes.HandLeft, right: jointTypes.HandTipLeft },
  { left: jointTypes.WristLeft, right: jointTypes.ThumbLeft },

  { left: jointTypes.HipRight, right: jointTypes.KneeRight },
  { left: jointTypes.KneeRight, right: jointTypes.AnkleRight },
  { left: jointTypes.AnkleRight, right: jointTypes.FootRight },

  { left: jointTypes.HipLeft, right: jointTypes.KneeLeft },
  { left: jointTypes.KneeLeft, right: jointTypes.AnkleLeft },
  { left: jointTypes.AnkleLeft, right: jointTypes.FootLeft }
];

export const jointsAzure = [
  { left: jointTypesAzure.Head, right: jointTypesAzure.Neck },
  { left: jointTypesAzure.Neck, right: jointTypesAzure.SpineShoulder },
  { left: jointTypesAzure.SpineShoulder, right: jointTypesAzure.SpineMid },
  { left: jointTypesAzure.SpineMid, right: jointTypesAzure.SpineBase },
  { left: jointTypesAzure.SpineShoulder, right: jointTypesAzure.ShoulderRight },
  { left: jointTypesAzure.SpineShoulder, right: jointTypesAzure.ShoulderLeft },
  { left: jointTypesAzure.SpineBase, right: jointTypesAzure.HipRight },
  { left: jointTypesAzure.SpineBase, right: jointTypesAzure.HipLeft },

  { left: jointTypesAzure.ShoulderRight, right: jointTypesAzure.ElbowRight },
  { left: jointTypesAzure.ElbowRight, right: jointTypesAzure.WristRight },

  { left: jointTypesAzure.ShoulderLeft, right: jointTypesAzure.ElbowLeft },
  { left: jointTypesAzure.ElbowLeft, right: jointTypesAzure.WristLeft },

  { left: jointTypesAzure.HipRight, right: jointTypesAzure.KneeRight },
  { left: jointTypesAzure.KneeRight, right: jointTypesAzure.AnkleRight },
  { left: jointTypesAzure.AnkleRight, right: jointTypesAzure.FootRight },

  { left: jointTypesAzure.HipLeft, right: jointTypesAzure.KneeLeft },
  { left: jointTypesAzure.KneeLeft, right: jointTypesAzure.AnkleLeft },
  { left: jointTypesAzure.AnkleLeft, right: jointTypesAzure.FootLeft }
];
