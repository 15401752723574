import { connect } from 'react-redux';
import {
  startExamination,
  stopExamination,
  getExamination,
  updateExamination,
  cleanupExamination
} from '../../../reducers/request';
import { examinationActionDisable } from '../../../reducers/app';
import { flushLocalFiles } from '../../../reducers/files';
import Progress from './progress';

const mapStateToProps = (state, props) => {
  const { examination } = props;
  const { app } = state;
  const localExamination = state.examination;

  const isButtonClickable = app.isExaminationStartable;
  return {
    localExamination,
    examination,
    runningId: localExamination && localExamination.runningId,
    isButtonClickable
  };
};

export default connect(mapStateToProps, {
  startExamination,
  stopExamination,
  examinationActionDisable,
  getExamination,
  updateExamination,
  cleanupExamination,
  flushLocalFiles
})(Progress);
