import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Examination from './examination';
import {
  getExamination,
  getPatient,
  startExamination,
  stopExamination,
  getLocalExamination,
  deleteExamination
} from '../../reducers/request';
import { Actions as FileActions } from '../../reducers/files';
import { Actions as NotificationsActions } from '../../reducers/notifications';
import { Actions as ExaminationActions } from '../../reducers/examinations';

const mapStateToProps = ({ app, auth, patients, examinations, files }) => ({
  app,
  files,
  patients,
  examinations,
  progress: examinations && examinations.progress,
  token: auth && auth.user && auth.user.token
});

export default withRouter(
  connect(mapStateToProps, {
    getExamination,
    getPatient,
    startExamination,
    stopExamination,
    showNotification: NotificationsActions.showNotification,
    cleanExaminationProgress: ExaminationActions.cleanExaminationProgress,
    flushLocalFiles: FileActions.flushLocalFiles,
    deleteExamination,
    getLocalExamination
  })(Examination)
);
