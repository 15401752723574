import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default class TabNavigationView extends Component {
  static propTypes = {
    possibleTabs: PropTypes.arrayOf(PropTypes.shape()),
    actualTab: PropTypes.shape()
  };

  render() {
    const { possibleTabs, actualTab } = this.props;

    return (
      <div className="tab-navigation">
        {possibleTabs.map(tab => {
          return (
            <Link key={tab.pathname} to={tab.pathname}>
              <h2
                className={`tab ${
                  actualTab && actualTab.pathname === tab.pathname
                    ? 'tab-selected'
                    : ''
                }`}
              >
                {tab.name}
              </h2>
            </Link>
          );
        })}
      </div>
    );
  }
}
