import React, { Component } from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line
import BaseModal from '../base-modal';

export default class NewExaminationsView extends Component {
  static propTypes = {
    modalType: PropTypes.string,
    buttons: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        doReset: PropTypes.bool,
        doValidate: PropTypes.bool,
        ignoreFieldsValidation: PropTypes.arrayOf(PropTypes.string),
        submit: PropTypes.func.isRequired
      })
    ).isRequired,
    fields: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    isOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired
  };

  render() {
    const { buttons, fields, isOpen, closeModal, modalType } = this.props;
    return (
      <BaseModal
        modalType={modalType}
        fields={fields}
        buttons={buttons}
        isOpen={isOpen}
        closeModal={closeModal}
      />
    );
  }
}
